import React from "react";
import { StyleSheet, TextInput, View } from "react-native";
import { scale, verticalScale } from "react-native-size-matters";

import colors from "../contants/colors";

import Typography from "./typography";

const Input = ({
  onChange,
  placeholder,
  value,
  label,
  keyboardType,
  max,
  style,
  error,
  onBlur = () => {},
}) => {
  return (
    <View style={[styles.cont, style]}>
      {label && <Typography title={label} style={styles.label} />}
      <TextInput
        placeholder={placeholder}
        onChangeText={onChange}
        value={value}
        style={styles.input}
        placeholderTextColor="rgba(255,255,255,0.5)"
        keyboardType={keyboardType}
        maxLength={max}
        onBlur={onBlur}
        selectionColor={colors.white}
      />
      {error && <Typography title={error} style={styles.error} />}
    </View>
  );
};

const styles = StyleSheet.create({
  cont: {
    width: "100%",
  },
  input: {
    width: "100%",
    fontSize: 16,
    borderWidth: 1,
    borderColor: "rgba(255,255,255,0.5)",
    paddingHorizontal: scale(20),
    borderRadius: scale(100),
    color: colors.white,
    position: "relative",
    paddingVertical: verticalScale(10),
  },
  label: {
    fontSize: scale(12),
    backgroundColor: colors.bg,
    position: "absolute",
    top: scale(-10),
    left: scale(15),
    paddingHorizontal: scale(5),
    zIndex: 8,
  },
  error: {
    marginLeft: scale(10),
    marginTop: scale(5),
    color: "#ED4C67",
    fontSize: scale(11),
  },
});

export default Input;
