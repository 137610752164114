import { persistStore, persistReducer } from "redux-persist";
import {
  infoReducer,
  listTabReducer,
  loaderReducer,
  matchTabReducer,
  userInfoReducer,
  userReducer,
} from "./slices";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
// import AsyncStorage from '@react-native-async-storage/async-storage';

const persistConfig = {
  key: "user",
  storage,
};

const rootReducer = combineReducers({
  user: userReducer,
  showLoader: loaderReducer,
  matchTab: matchTabReducer,
  listTab: listTabReducer,
  userInfo: userInfoReducer,
  info: infoReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      immutableCheck: false,
      serializableCheck: false,
      // Redux persist
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
    }).concat([]),
});

export const pStore = persistStore(store);
