import { useNavigation } from "@react-navigation/native";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const Intro = () => {
  const { replace } = useNavigation();

  const user = useSelector((s) => s.user.value);

  useEffect(() => {
    replace(user ? "PinLogin" : "Splash");
  }, []);

  return <></>;
};

export default Intro;
