import React from "react";
import { ActivityIndicator, View } from "react-native";
import Modal from "./modal";

import colors from "../contants/colors";
import { scale } from "react-native-size-matters";

const Loader = ({ show }) => {
  return (
    <Modal
      isVisible={show}
      backdropOpacity={0.8}
      animationInTiming={10}
      animationOutTiming={10}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <View
        style={{
          borderRadius: 100,
          backgroundColor: colors.btn,
          width: scale(50),
          height: scale(50),
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ActivityIndicator size={scale(30)} color={colors.white} animating />
      </View>
    </Modal>
  );
};

export default Loader;
