import React, { useRef, useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
  BackHandler,
} from "react-native";
import { useNavigation } from "@react-navigation/native";

import { scale, verticalScale } from "react-native-size-matters";

import Typography from "./typography";
import Img from "./image";

import colors from "../contants/colors";
import images from "../contants/images";
import icons from "../contants/icons";
import fonts from "../contants/fonts";

const Splash = ({}) => {
  const [screen, setScreen] = useState(0);

  const { reset } = useNavigation();

  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={styles.skip}
        onPress={() => {
          reset({
            index: 0,
            routes: [{ name: "Login" }],
          });
        }}
      >
        <Typography title="Skip" style={styles.skipText} />
      </TouchableOpacity>
      <Img
        source={
          screen === 0
            ? images.splash1
            : screen === 1
            ? images.splash2
            : images.splash3
        }
        style={styles.splash1}
      />
      <Typography
        title={
          screen === 0
            ? "Player vs Player Thrille"
            : screen === 1
            ? "BUY PLAYER get 4x Profit"
            : "Stake on Runrate "
        }
        style={styles.title}
      />
      <Typography
        title={
          screen === 0
            ? "In this contest you have to take only one player and your opponent also has to take one player, PLAY (PLAYER vs PLAYER)."
            : screen === 1
            ? "Just buy the player,the better perfomance you get more profit,."
            : "You can make profit by understanding the match situation the runrate in the next over will be up or down."
        }
        style={styles.textTitle}
      />
      <View style={styles.nextPage}>
        <Img
          source={
            screen === 0
              ? icons.frame
              : screen === 1
              ? icons.frame2
              : icons.frame3
          }
          style={styles.frame}
        />
        <TouchableOpacity
          onPress={() => {
            screen === 2
              ? reset({
                  index: 0,
                  routes: [{ name: "Login" }],
                })
              : setScreen(screen + 1);
          }}
        >
          <Img source={icons.next} style={styles.next} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const { height } = Dimensions.get("window");

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.bg,
    alignItems: "center",
    paddingBottom: verticalScale(10),
  },
  skipText: {
    color: colors.white,
    fontSize: scale(15),
    fontWeight: fonts._600,
  },
  skip: {
    marginLeft: scale(300),
    marginTop: scale(20),
    width: scale(40),
  },
  splash1: {
    width: "100%",
    height: scale(421),
  },
  title: {
    fontSize: scale(25),
    color: colors.white,
    fontWeight: fonts._700,
  },
  textTitle: {
    fontSize: scale(12),
    color: colors.white,
    width: scale(300),
    textAlign: "center",
    marginTop: scale(10),
    fontWeight: fonts._400,
    lineHeight: verticalScale(20),
  },
  nextPage: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingHorizontal: scale(20),
    // position: "absolute",
    // top: height - verticalScale(90),
  },
  frame: {
    width: scale(60),
    height: scale(8),
  },
  next: {
    width: scale(50),
    height: scale(50),
  },
});

export default Splash;
