import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import Home from "../screens/app/home";
import MyProfile from "../screens/app/myProfile";
import Four from "../screens/app/four";
import { StyleSheet, View } from "react-native";
import colors from "../contants/colors";
import { scale } from "react-native-size-matters";
import Img from "../components/image";
import icons from "../contants/icons";

const Tab = createBottomTabNavigator();

const BottomTab = () => {
  return (
    <View style={{ width: "100%", height: "100vh" }}>
      <Tab.Navigator
        screenOptions={{
          headerShown: false,
          tabBarStyle: styles.tabBar,
          tabBarShowLabel: false,
        }}
        backBehavior="history"
      >
        <Tab.Screen
          name="Home"
          component={Home}
          options={{
            tabBarIcon: ({ focused }) =>
              focused ? (
                <View style={styles.iconWrraper}>
                  <Img source={icons.home} style={styles.iconActive} />
                </View>
              ) : (
                <Img source={icons.home} style={styles.icon} />
              ),
          }}
        />
        <Tab.Screen
          name="BetList"
          component={Four}
          options={{
            tabBarIcon: ({ focused }) =>
              focused ? (
                <View style={styles.iconWrraper}>
                  <Img source={icons.trophy} style={styles.iconActive} />
                </View>
              ) : (
                <Img source={icons.trophy} style={styles.icon} />
              ),
          }}
        />
        {/* <Tab.Screen
        name="Stock"
        component={BuyPlayer}
        options={{
          tabBarIcon: ({focused}) =>
            focused ? (
              <View style={styles.iconWrraper}>
                <Img source={icons.stock} style={styles.iconActive} />
              </View>
            ) : (
              <Img source={icons.stock} style={styles.icon} />
            ),
        }}
      /> */}
        <Tab.Screen
          name="Profile"
          component={MyProfile}
          options={{
            tabBarIcon: ({ focused }) =>
              focused ? (
                <View style={styles.iconWrraper}>
                  <Img source={icons.user} style={styles.iconActive} />
                </View>
              ) : (
                <Img source={icons.user} style={styles.icon} />
              ),
          }}
        />
      </Tab.Navigator>
    </View>
  );
};

const styles = StyleSheet.create({
  tabBar: {
    backgroundColor: colors.primary,
    borderTopWidth: 0,
    position: "absolute",
    bottom: scale(15),
    left: scale(20),
    right: scale(20),
    height: scale(60),
    borderRadius: scale(10),
  },
  icon: {
    height: scale(28),
    width: scale(28),
  },
  iconActive: {
    height: scale(21),
    width: scale(21),
    tintColor: colors.white,
  },
  iconWrraper: {
    width: scale(40),
    height: scale(40),
    borderRadius: scale(100),
    backgroundColor: colors.btn,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default BottomTab;
