import { View, FlatList } from "react-native";
// import SkeletonPlaceholder from 'react-native-skeleton-placeholder';
import { scale, verticalScale } from "react-native-size-matters";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import colors from "../contants/colors";

const Placeholder = ({ item = 10, heigh = verticalScale(70), padding = 0 }) => {
  return (
    <FlatList
      data={Array(item).fill()}
      style={{ paddingHorizontal: padding }}
      showsVerticalScrollIndicator={false}
      renderItem={() => (
        <View style={{ marginVertical: verticalScale(5), width: "100%" }}>
          <Skeleton
            height={heigh}
            borderRadius={scale(10)}
            baseColor={colors.primary}
            highlightColor="#1f4266"
            duration={0.7}
          />
        </View>
      )}
    />
  );
};

export default Placeholder;
