import { scale, verticalScale } from "react-native-size-matters";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  useWindowDimensions,
  ScrollView,
} from "react-native";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import Typography from "./typography";
import Img from "./image";
import Loader from "./loader";
import BetConfirm from "./betConfirm";

import icons from "../contants/icons";
import colors from "../contants/colors";
import fonts from "../contants/fonts";

import api from "../functions/api";
import toast from "../functions/toast";
import { Line } from "react-chartjs-2";

const Graph = ({ runRate, matchData }) => {
  const [showModel, setShowModel] = useState(false);
  const [nextOver, setNextOver] = useState(null);
  const [selected, setSelected] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  const runRateListRef = useRef();

  const { width } = useWindowDimensions();

  const user = useSelector((s) => s.user.value);

  const addBet = async (betAmount, amount, quantity) => {
    setShowLoader(true);
    try {
      const data = {
        betAmount,
        amount,
        quantity,
        selected,
        _over: nextOver,
        x: 2,
        user,
        _match: matchData._id,
        curRunRate: runRate.runRates[runRate.runRates.length - 1].runRate,
      };

      await api.post("/add-run-rate-bet", data);

      setShowModel(false);

      toast("Bet added successfully");
    } catch (err) {
      console.log("🚀 ~ file: graph.js:33 ~ addBet ~ err:", err);
    }
    setShowLoader(false);
  };

  useEffect(() => {
    matchData && setNextOver(Math.floor(parseFloat(matchData._over)) + 1);
  }, [matchData]);

  return (
    <>
      <Loader show={showLoader} />
      <BetConfirm
        show={showModel}
        min={100}
        val={"100"}
        hideModal={() => setShowModel(false)}
        onChange={addBet}
      />
      {runRate && runRate.runRates.length > 0 && runRate.runRates && (
        <ScrollView
          contentContainerStyle={styles.container}
          showsVerticalScrollIndicator={false}
        >
          <Typography title="Live Runrate Up & Down" />
          <Line
            data={{
              labels: runRate.runRates.slice(-7).map((i) => i.over + " ov"),
              datasets: [
                {
                  data: runRate.runRates
                    .slice(-7)
                    .map((i) => parseFloat(i.runRate)),
                  fill: true,
                  backgroundColor: colors.primary,
                  borderColor: colors.btn,
                  label: "Runrate",
                },
              ],
            }}
          />

          {/* <LineChart
            data={{
              labels: runRate.runRates.slice(-7).map(i => i.over + ' ov'),
              datasets: [
                {
                  data: runRate.runRates
                    .slice(-7)
                    .map(i => parseFloat(i.runRate)),
                },
              ],
            }}
            width={width - 40}
            height={(width - 40) / 2}
            // yAxisLabel="$"
            // yAxisSuffix="k"
            yAxisInterval={1}
            chartConfig={{
              backgroundGradientFrom: colors.btn,
              backgroundGradientTo: '#0065b3',
              decimalPlaces: 2, // optional, defaults to 2dp
              color: (opacity = 1) => colors.white,
              labelColor: (opacity = 1) => colors.white,
              style: {
                borderRadius: 16,
              },
              propsForDots: {
                r: '5',
                strokeWidth: '0.1',
                stroke: colors.white,
              },
            }}
            bezier
            style={{
              marginVertical: 8,
              borderRadius: 16,
            }}
          /> */}
          {parseFloat(matchData._over) % 1 >= 0.1 && (
            <Typography
              title="You can bet on Runrate after complete each over"
              style={styles.msg}
            />
          )}
          <View style={styles.bets}>
            <TouchableOpacity
              style={[
                styles.upComing,
                (parseFloat(matchData._over) % 1 >= 0.1 ||
                  matchData.status !== "Live") && { opacity: 0.5 },
              ]}
              onPress={() => {
                setSelected("UP");
                setShowModel(true);
              }}
              disabled={
                parseFloat(matchData._over) % 1 >= 0.1 ||
                matchData.status !== "Live"
              }
            >
              <Typography title="UP" style={styles.ru} />
              <Typography
                title={`(${nextOver} ov) ${runRate.finalRunRate}`}
                style={styles.ru}
              />
              <Img source={icons.green} style={styles.green} />
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                styles.down,
                (parseFloat(matchData._over) % 1 >= 0.1 ||
                  matchData.status !== "Live") && { opacity: 0.5 },
              ]}
              onPress={() => {
                setSelected("DOWN");
                setShowModel(true);
              }}
              disabled={
                parseFloat(matchData._over) % 1 >= 0.1 ||
                matchData.status !== "Live"
              }
            >
              <Typography title="Down" style={styles.ru} />
              <Typography
                title={`(${nextOver} ov) ${runRate.finalRunRate}`}
                style={styles.ru}
              />
              <Img source={icons.red} style={styles.green} />
            </TouchableOpacity>
          </View>
          <ScrollView
            horizontal
            style={{ marginVertical: 20 }}
            showsHorizontalScrollIndicator={false}
            ref={runRateListRef}
            contentOffset={{ x: runRate.runRates.length * 88 }}
          >
            {runRate.runRates.map((item) => (
              <View style={styles.runRateBox}>
                <Typography title={item.over + " ov"} style={styles.ov} />
                <Typography title={item.runRate} style={styles.runRate} />
              </View>
            ))}
          </ScrollView>
        </ScrollView>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: scale(15),
    paddingBottom: verticalScale(50),
  },
  graph: {
    width: "100%",
    height: scale(230),
  },
  bets: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: scale(10),
  },
  upComing: {
    alignItems: "center",
    flexDirection: "row",
    borderWidth: scale(1),
    borderColor: colors.engGreen,
    paddingHorizontal: scale(10),
    paddingVertical: verticalScale(5),
    borderRadius: scale(20),
    marginHorizontal: scale(5),
  },
  down: {
    alignItems: "center",
    flexDirection: "row",
    borderWidth: scale(1),
    borderColor: colors.red,
    paddingHorizontal: scale(10),
    paddingVertical: verticalScale(5),
    borderRadius: scale(20),
    marginHorizontal: scale(5),
  },
  green: {
    width: scale(11),
    height: scale(11),
    marginHorizontal: scale(3),
    marginBottom: scale(3),
  },
  ru: {
    fontSize: scale(12),
    marginHorizontal: scale(3),
  },
  price: {
    flexDirection: "row",
    alignItems: "center",
  },
  runRateBox: {
    width: scale(80),
    height: verticalScale(55),
    borderRadius: scale(10),
    backgroundColor: colors.primary,
    marginHorizontal: scale(4),
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: verticalScale(3),
  },
  runRate: {
    fontSize: scale(13),
    fontWeight: fonts._600,
  },
  msg: {
    fontSize: scale(11),
    textAlign: "center",
    width: "100%",
    marginTop: verticalScale(3),
    color: colors.yellow,
  },
});

export default Graph;
