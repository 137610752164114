import { StyleSheet } from "react-native";

import colors from "../../../contants/colors";
import { scale, verticalScale } from "react-native-size-matters";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.bg,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: verticalScale(10),
    justifyContent: "space-between",
    width: "100%",
    paddingHorizontal: scale(10),
  },
  pocketRupees: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: colors.primary,
    paddingHorizontal: scale(20),
    paddingVertical: verticalScale(10),
    justifyContent: "center",
    borderRadius: scale(100),
  },
  logo: {
    width: scale(120),
    height: verticalScale(60),
  },
  pocket: {
    width: scale(20),
    height: scale(15),
  },
  add: {
    width: scale(16),
    height: scale(16),
  },
  rupees: {
    paddingHorizontal: scale(10),
    fontSize: scale(12),
    marginTop: verticalScale(3),
  },
});

export default styles;
