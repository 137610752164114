const icons = {
  home: require('../assets/icons/home.png'),
  score: require('../assets/icons/score.png'),
  user: require('../assets/icons/user.png'),
  win: require('../assets/icons/win.png'),
  ind: require('../assets/icons/ind.png'),
  usa: require('../assets/icons/usa.png'),
  batBall: require('../assets/icons/bat-ball.png'),
  worldCup: require('../assets/icons/world-cup.png'),
  red: require('../assets/icons/red.png'),
  green: require('../assets/icons/green.png'),
  live: require('../assets/icons/live.png'),
  mobail: require('../assets/icons/mobail.png'),
  code: require('../assets/icons/code.png'),
  back: require('../assets/icons/back.png'),
  time: require('../assets/icons/time.png'),
  rupees: require('../assets/icons/rupees.png'),
  editPlayer: require('../assets/icons/edit-player.png'),
  add: require('../assets/icons/add.png'),
  logo: require('../assets/icons/logo.png'),
  pocket: require('../assets/icons/pocket.png'),
  arcticons_stockswidget: require('../assets/icons/arcticons_stockswidget.png'),
  cancelled: require('../assets/icons/cancelled.png'),
  edit: require('../assets/icons/edit.png'),
  right: require('../assets/icons/right.png'),
  money: require('../assets/icons/money.png'),
  kyc: require('../assets/icons/kyc.png'),
  contact: require('../assets/icons/contact.png'),
  logout: require('../assets/icons/logout.png'),
  play: require('../assets/icons/play.png'),
  point: require('../assets/icons/point.png'),
  privacy: require('../assets/icons/privacy.png'),
  rateUs: require('../assets/icons/rateUs.png'),
  refer: require('../assets/icons/refer.png'),
  tds: require('../assets/icons/tds.png'),
  terms: require('../assets/icons/terms.png'),
  theme: require('../assets/icons/theme.png'),
  updates: require('../assets/icons/updates.png'),
  single: require('../assets/icons/single.png'),
  first: require('../assets/icons/first.png'),
  cancel: require('../assets/icons/cancel.png'),
  home: require('../assets/icons/home.png'),
  trophy: require('../assets/icons/trophy.png'),
  stock: require('../assets/icons/stock.png'),
  giftSharp: require('../assets/icons/gift-sharp.png'),
  notification: require('../assets/icons/notification.png'),
  walletPlus: require('../assets/icons/wallet-plus.png'),
  logout: require('../assets/icons/logout.png'),
  homeActive: require('../assets/icons/home-active.png'),
  winActive: require('../assets/icons/win-active.png'),
  scoreActive: require('../assets/icons/score-active.png'),
  camera: require('../assets/icons/camera.png'),
  lock: require('../assets/icons/lock.png'),
  ptsBg: require('../assets/icons/pts-bg.png'),
  down: require('../assets/icons/down.png'),
  frame: require('../assets/icons/frame.png'),
  frame2: require('../assets/icons/frame2.png'),
  frame3: require('../assets/icons/frame3.png'),
  next: require('../assets/icons/next.png'),
  gpay: require('../assets/icons/gpay.png'),
  paytm: require('../assets/icons/paytm.png'),
  apay: require('../assets/icons/apay.png'),
  bhim: require('../assets/icons/bhim.png'),
};

export default icons;
