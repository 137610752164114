import React, { useRef, useEffect } from "react";
import { BackHandler, View } from "react-native";
import Splash from "../../../components/splash";

import { useIsFocused } from "@react-navigation/native";
import toast from "../../../functions/toast";

const Splash1 = () => {
  const isFocused = useIsFocused();

  const lastBackPressTime = useRef(0);

  const handleBackPress = () => {
    if (isFocused) {
      const currentTime = new Date().getTime();
      if (currentTime - lastBackPressTime.current < 2000) {
        BackHandler.exitApp();
      } else {
        toast("Press back again to exit");
        lastBackPressTime.current = currentTime;
      }
      return true;
    }
  };

  useEffect(() => {
    const backHandlerSubscription = BackHandler.addEventListener(
      "hardwareBackPress",
      handleBackPress
    );
    return () => {
      backHandlerSubscription.remove();
    };
  }, [isFocused]);

  return <Splash />;
};

export default Splash1;
