import { View, TouchableOpacity } from "react-native";
import { useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { useSelector } from "react-redux";

import Typography from "../../../components/typography";
import Img from "../../../components/image";
import Loader from "../../../components/loader";
import OTPInputView from "../../../components/otpInput";

import styles from "./styles";

import colors from "../../../contants/colors";
import images from "../../../contants/images";
import icons from "../../../contants/icons";

import api from "../../../functions/api";
import { scale, verticalScale } from "react-native-size-matters";

const PinLogin = () => {
  const [showLoader, setShowLoader] = useState(false);

  const _id = useSelector((s) => s.user.value);

  const { replace, navigate } = useNavigation();

  const verifyPin = async (pin) => {
    setShowLoader(true);
    try {
      await api.post("/verify-pin", { pin, _id });
      replace("BottomTab");
    } catch (err) {
      console.log("🚀 ~ file: index.js:27 ~ verifyPin ~ err:", err);
    }
    setShowLoader(false);
  };

  return (
    <View style={styles.container} resizeMode="cover">
      <Loader show={showLoader} />
      <Img source={icons.logo} style={styles.logo} />
      <Img source={images.cricketImg} style={styles.bgImg} />
      <View style={{ marginTop: verticalScale(10), alignItems: "center" }}>
        <Typography
          title="Enter Four Digit PIN to Login"
          style={styles.otpText}
        />

        <OTPInputView
          style={{
            width: scale(240),
            height: verticalScale(50),
            justifyContent: "center",
            alignItems: "center",
          }}
          pinCount={4}
          autoFocusOnLoad={false}
          codeInputFieldStyle={{
            width: scale(50),
            height: scale(50),
            borderRadius: scale(10),
            marginHorizontal: scale(5),
            borderWidth: 0,
            backgroundColor: colors.primary,
            fontSize: scale(24),
            textAlign: "center",
            color: colors.white,
          }}
          codeInputHighlightStyle={{
            borderColor: colors.white,
            borderWidth: scale(1),
          }}
          onCodeFilled={verifyPin}
          selectionColor={colors.white}
          secureTextEntry
        />

        <TouchableOpacity onPress={() => navigate("Login", true)}>
          <Typography
            title="Forgot PIN?"
            style={{
              ...styles.otpText,
              color: colors.engRed,
              marginTop: scale(50),
            }}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default PinLogin;
