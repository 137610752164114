const colors = {
  bg: "#060E24",
  primary: "#122940",
  black: "#000000",
  red: "#D63031",
  white: "#fff",
  black5: "#060E24",
  engRed: "#ED4C67",
  btn: "#0984E3",
  input: "#FFFFFFA6",
  engGreen: "#2ECC71",
  grey: "#FFFFFF33",
  darkgrey: "#FFFFFF99",
  lightgrey: "#FFFFFFBF",
  engGrey: "#BDC3C7",
  yellow: "#F1C40F",
  dark: "#FFFFFF1A",
  line: "#FFFFFF40",
  whilegrey: "#D9D9D926",
  border: "#D9D9D933",
  buy: "#122940CC",
  user: "#D9D9D9",
};

export default colors;
