import {
  View,
  TouchableOpacity,
  FlatList,
  ScrollView,
  Clipboard,
  Share,
} from "react-native";
import { useSelector } from "react-redux";
import Typography from "../../../components/typography";
import Img from "../../../components/image";
import Loader from "../../../components/loader";
import styles from "./styles";
import icons from "../../../contants/icons";
import toast from "../../../functions/toast";
import { scale } from "react-native-size-matters";
import colors from "../../../contants/colors";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import api from "../../../functions/api";

const ReferEarn = () => {
  const [showLoader, setShowLoader] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const userInfo = useSelector((s) => s.userInfo.value);

  const { goBack } = useNavigation();

  const user = useSelector((s) => s.user.value);

  const getData = async () => {
    try {
      const data = await api.get("/ref-list/" + user);
      setData(data);
      let t = 0;
      data.forEach((i) => (t += parseFloat(i.amount)));
      setTotal(t);
    } catch (err) {
      console.log("🚀 ~ getData ~ err:", err);
    }
    setShowLoader(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Loader show={showLoader} />
      {userInfo && (
        <View style={styles.container}>
          <View style={styles.header}>
            <View style={styles.backScreen}>
              <TouchableOpacity onPress={goBack}>
                <Img source={icons.back} style={styles.back} />
              </TouchableOpacity>
              <Typography title="Refer & Earn" style={styles.headerText} />
            </View>
          </View>

          <View style={styles.myBouns}>
            <View>
              <Typography title="My Total Bonus" style={styles.totalText} />
              <Typography title={`Rs.${total}/-`} style={styles.totalText} />
            </View>
            <Img source={icons.giftSharp} style={styles.giftIcon} />
          </View>
          <TouchableOpacity
            disabled
            style={styles.refer}
            onPress={() => {
              Clipboard.setString(userInfo.refCode);
              toast("Ref code copied");
            }}
          >
            <Typography
              title={"My Refer Code " + userInfo.refCode}
              style={styles.code}
            />
            <View style={{ marginTop: scale(5), flexDirection: "row" }}>
              <TouchableOpacity
                style={{
                  paddingVertical: scale(5),
                  paddingHorizontal: scale(10),
                  backgroundColor: colors.bg,
                  width: scale(80),
                  alignItems: "center",
                  borderRadius: scale(5),
                }}
                onPress={() => {
                  Clipboard.setString(userInfo.refCode);
                  toast("Ref code copied");
                }}
              >
                <Typography title="Copy" style={{ fontSize: scale(12) }} />
              </TouchableOpacity>
              {navigator.share && (
                <TouchableOpacity
                  style={{
                    paddingVertical: scale(5),
                    paddingHorizontal: scale(10),
                    backgroundColor: colors.bg,
                    width: scale(80),
                    alignItems: "center",
                    borderRadius: scale(5),
                    marginLeft: scale(10),
                  }}
                  onPress={() => {
                    navigator.share({
                      title: "Share ref link",
                      url:
                        "https://app.crickstox.com/login?refCode=" +
                        userInfo.refCode,
                    });
                  }}
                >
                  <Typography title="Share" style={{ fontSize: scale(12) }} />
                </TouchableOpacity>
              )}
            </View>
          </TouchableOpacity>
          {data.length > 0 && (
            <Typography
              title="Refer Transactions"
              style={{ marginVertical: scale(10), fontSize: scale(14) }}
            />
          )}
          <FlatList
            data={data}
            renderItem={({ item }) => (
              <View style={[styles.myBouns, { marginTop: scale(10) }]}>
                <View>
                  <Typography title="Bonus" style={styles.totalText} />
                  <Typography
                    title={`Rs.${item.amount || 0}/-`}
                    style={styles.totalText}
                  />
                </View>
                <Img source={icons.giftSharp} style={styles.giftIcon} />
              </View>
            )}
          />
        </View>
      )}
    </>
  );
};

export default ReferEarn;
