import {
  View,
  TouchableOpacity,
  FlatList,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import { useEffect, useRef } from "react";
import { useNavigation, useRoute } from "@react-navigation/native";
import { verticalScale } from "react-native-size-matters";

import Typography from "../../../components/typography";
import Img from "../../../components/image";

import icons from "../../../contants/icons";

import { scoreBaseURL } from "../../../functions/api";

import styles from "./styles";

const PlayerPerformance = () => {
  const flatRef = useRef();

  const { players, player } = useRoute().params;
  const { height, width } = useWindowDimensions();
  const { goBack } = useNavigation();

  useEffect(() => {
    if (flatRef.current && players[player + 1]) {
      const t = setTimeout(() => {
        flatRef.current.scrollToIndex({ animated: true, index: player + 1 });
        setTimeout(() => {
          flatRef.current.scrollToIndex({ animated: true, index: player });
        }, 1000);
      }, 2000);
      return () => {
        clearTimeout(t);
      };
    }
  }, []);

  return (
    <>
      <View style={styles.header}>
        <TouchableOpacity onPress={goBack}>
          <Img source={icons.back} style={styles.back} />
        </TouchableOpacity>
        <Typography title="Player Performance" style={styles.countryName} />
      </View>
      <FlatList
        data={players}
        showsVerticalScrollIndicator={false}
        ref={flatRef}
        initialScrollIndex={player}
        getItemLayout={(data, index) => ({
          length: width,
          offset: width * index,
          index,
        })}
        renderItem={({ item }) => {
          const points = item.points.finalData;

          return (
            <>
              {points && (
                <View style={styles.container}>
                  <View style={styles.playerName}>
                    <Typography
                      title={item.fullName || item.info.fullName}
                      style={styles.name}
                    />
                    <Img source={icons.batBall} style={styles.batBall} />
                  </View>
                  <View style={styles.playerPoints}>
                    <Img
                      source={{ uri: scoreBaseURL + item.image }}
                      style={styles.rohit}
                    />
                    <View style={styles.player}>
                      <View>
                        <Typography title="Buy %" style={styles.price} />
                        <Typography title="66.07" style={styles.points} />
                      </View>
                      <View style={styles.playerBuy}>
                        <Typography title="Buy Price" style={styles.price} />
                        <Typography
                          title={item.price || 100}
                          style={styles.points}
                        />
                      </View>
                      <View>
                        <Typography title="Points" style={styles.price} />
                        <Typography
                          title={item.points.totalPoints}
                          style={styles.points}
                        />
                      </View>
                    </View>
                  </View>
                  <ScrollView
                    contentContainerStyle={styles.playerDetails}
                    showsVerticalScrollIndicator={false}
                    style={{
                      maxHeight: height - verticalScale(240),
                    }}
                  >
                    <View style={styles.playerEvents}>
                      <Typography title="Events" style={styles.price} />
                      <Typography title="Points" style={styles.pointsPlayer} />
                      <Typography title="Actual" style={styles.price} />
                    </View>
                    <View style={styles.cricketDetails}>
                      <View>
                        <Typography title="Runs" style={styles.Runs} />
                      </View>
                      <View style={styles.playerScore}>
                        <Typography
                          title={points.runs.points}
                          style={styles.score}
                        />
                        <Typography
                          title={points.runs.events}
                          style={styles.actual}
                        />
                      </View>
                    </View>
                    <View style={styles.cricketDetails}>
                      <View>
                        <Typography title="4's" style={styles.Runs} />
                      </View>
                      <View style={styles.playerScore}>
                        <Typography
                          title={points.four.points}
                          style={styles.score}
                        />
                        <Typography
                          title={points.four.events}
                          style={styles.actual}
                        />
                      </View>
                    </View>
                    <View style={styles.cricketDetails}>
                      <View>
                        <Typography title="6's" style={styles.Runs} />
                      </View>
                      <View style={styles.playerScore}>
                        <Typography
                          title={points.six.points}
                          style={styles.score}
                        />
                        <Typography
                          title={points.six.events}
                          style={styles.actual}
                        />
                      </View>
                    </View>
                    <View style={styles.cricketDetails}>
                      <View>
                        <Typography title="50" style={styles.Runs} />
                      </View>
                      <View style={styles.playerScore}>
                        <Typography
                          title={points._50.points}
                          style={styles.score}
                        />
                        <Typography
                          title={points._50.events}
                          style={styles.actual}
                        />
                      </View>
                    </View>
                    <View style={styles.cricketDetails}>
                      <View>
                        <Typography title="100" style={styles.Runs} />
                      </View>
                      <View style={styles.playerScore}>
                        <Typography
                          title={points._100.points}
                          style={styles.score}
                        />
                        <Typography
                          title={points._100.events}
                          style={styles.actual}
                        />
                      </View>
                    </View>
                    <View style={styles.cricketDetails}>
                      <View>
                        <Typography title="Wickets" style={styles.Runs} />
                      </View>
                      <View style={styles.playerScore}>
                        <Typography
                          title={points.wicket.points}
                          style={styles.score}
                        />
                        <Typography
                          title={points.wicket.events}
                          style={styles.actual}
                        />
                      </View>
                    </View>
                    <View style={styles.cricketDetails}>
                      <View>
                        <Typography title="LBW Bonus" style={styles.Runs} />
                      </View>
                      <View style={styles.playerScore}>
                        <Typography
                          title={points.lbw.points}
                          style={styles.score}
                        />
                        <Typography
                          title={points.lbw.events}
                          style={styles.actual}
                        />
                      </View>
                    </View>
                    <View style={styles.cricketDetails}>
                      <View>
                        <Typography title="Bowled Bonus" style={styles.Runs} />
                      </View>
                      <View style={styles.playerScore}>
                        <Typography
                          title={points.bowled.points}
                          style={styles.score}
                        />
                        <Typography
                          title={points.bowled.events}
                          style={styles.actual}
                        />
                      </View>
                    </View>
                    <View style={styles.cricketDetails}>
                      <View>
                        <Typography
                          title="2/3 Wickets Bonus"
                          style={styles.Runs}
                        />
                      </View>
                      <View style={styles.playerScore}>
                        <Typography
                          title={
                            points._2Wickets.points + points._3Wickets.points
                          }
                          style={styles.score}
                        />
                        <Typography
                          title={
                            points._2Wickets.events + points._3Wickets.events
                          }
                          style={styles.actual}
                        />
                      </View>
                    </View>
                    <View style={styles.cricketDetails}>
                      <View>
                        <Typography title="Maiden Over" style={styles.Runs} />
                      </View>
                      <View style={styles.playerScore}>
                        <Typography
                          title={points.maidenOver.points}
                          style={styles.score}
                        />
                        <Typography
                          title={points.maidenOver.events}
                          style={styles.actual}
                        />
                      </View>
                    </View>
                    <View style={styles.cricketDetails}>
                      <View>
                        <Typography title="Catch" style={styles.Runs} />
                      </View>
                      <View style={styles.playerScore}>
                        <Typography
                          title={points.caught.points}
                          style={styles.score}
                        />
                        <Typography
                          title={points.caught.events}
                          style={styles.actual}
                        />
                      </View>
                    </View>
                    <View style={styles.cricketDetails}>
                      <View>
                        <Typography title="Run Out" style={styles.Runs} />
                      </View>
                      <View style={styles.playerScore}>
                        <Typography
                          title={points.runOutDirectThrow.points}
                          style={styles.score}
                        />
                        <Typography
                          title={points.runOutDirectThrow.events}
                          style={styles.actual}
                        />
                      </View>
                    </View>
                    <View style={styles.cricketDetails}>
                      <View>
                        <Typography title="Stumping" style={styles.Runs} />
                      </View>
                      <View style={styles.playerScore}>
                        <Typography
                          title={points.stumping.points}
                          style={styles.score}
                        />
                        <Typography
                          title={points.stumping.events}
                          style={styles.actual}
                        />
                      </View>
                    </View>
                    <View style={styles.cricketDetails}>
                      <View>
                        <Typography
                          title="Run Out Throw Pass"
                          style={styles.Runs}
                        />
                      </View>
                      <View style={styles.playerScore}>
                        <Typography
                          title={points.runOutThrowPassed.points}
                          style={styles.score}
                        />
                        <Typography
                          title={points.runOutThrowPassed.events}
                          style={styles.actual}
                        />
                      </View>
                    </View>
                  </ScrollView>
                  <View style={styles.cricketTotal}>
                    <View>
                      <Typography title="Total" style={styles.Runs} />
                    </View>
                    <View style={styles.playerScore}>
                      <Typography
                        title={item.points.totalPoints}
                        style={styles.score}
                      />
                      <Typography
                        title={item.points.totalEvents}
                        style={styles.actual}
                      />
                    </View>
                  </View>
                </View>
              )}
            </>
          );
        }}
        horizontal
        pagingEnabled
      />
    </>
  );
};

export default PlayerPerformance;
