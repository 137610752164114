import { View, TouchableOpacity, TextInput, ScrollView } from "react-native";
// import OTPInputView from "@twotalltotems/react-native-otp-input";
import { useEffect, useState } from "react";
// import BackgroundTimer from 'react-native-background-timer';
import { useNavigation, useRoute } from "@react-navigation/native";
import { useDispatch } from "react-redux";
// import messaging from "@react-native-firebase/messaging";

import Typography from "../../../components/typography";
import Img from "../../../components/image";
import OTPInputView from "../../../components/otpInput";

import styles from "./styles";

import icons from "../../../contants/icons";
import colors from "../../../contants/colors";
import images from "../../../contants/images";

import api from "../../../functions/api";
import toast from "../../../functions/toast";

import { setUser } from "../../../redux/slices";
import { scale, verticalScale } from "react-native-size-matters";

const Login = () => {
  const [otpSent, setOtpSent] = useState(false);
  const [time, setTime] = useState(300);
  const [mobNo, setMobNo] = useState(null);
  const [refCode, setRefCode] = useState(null);

  const { replace } = useNavigation();

  const isForgotPIN = useRoute().params ? true : false;

  const dispatch = useDispatch();

  const secondsToMinutes = (seconds) => {
    var minutes = Math.floor(seconds / 60);
    var remainingSeconds = seconds % 60;

    minutes = minutes < 10 ? "0" + minutes : minutes;
    remainingSeconds =
      remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;

    return minutes + ":" + remainingSeconds;
  };

  const sendOTP = async () => {
    if (!mobNo || !mobNo.match(/^[6-9]\d{9}$/))
      return toast("Enter valid mobile number");

    try {
      await api.post("/login", { mobNo });
      setOtpSent(true);
      return toast("OTP sent successfully");
    } catch (err) {
      console.log("🚀 ~ file: index.js:34 ~ login ~ err:", err);
      return toast("Something went wrong");
    }
  };

  const verifyOTP = async (otp) => {
    try {
      // await messaging().registerDeviceForRemoteMessages();
      const token = null;
      console.log("🚀 ~ verifyOTP ~ token:", token);

      const data = await api.post("/verify-otp", {
        otp,
        mobNo,
        refCode,
        token,
        isForgotPIN,
      });

      if (data.pin) dispatch(setUser(data._id));

      return replace(
        data.pin && !isForgotPIN ? "PinLogin" : "PinGenerate",
        data
      );
    } catch (err) {
      console.log("🚀 ~ file: index.js:61 ~ verifyOTP ~ err:", err);
    }
  };

  useEffect(() => {
    if (otpSent) {
      const timer = setInterval(() => {
        setTime((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [otpSent]);

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <ScrollView
      style={styles.container}
      resizeMode="cover"
      contentContainerStyle={{
        alignItems: "center",
        width: "100%",
        minHeight: "100%",
      }}
    >
      <Img source={icons.logo} style={styles.logo} />
      <Img source={images.cricketImg} style={styles.bgImg} />
      <View style={styles.mobailNum}>
        <TextInput
          placeholder="Enter Mobile Number"
          placeholderTextColor={colors.input}
          style={styles.input}
          value={mobNo}
          onChangeText={setMobNo}
          keyboardType="number-pad"
          maxLength={10}
          selectionColor={colors.white}
        />
        <Img source={icons.mobail} style={styles.mobailIcon} />
      </View>
      {!isForgotPIN && (
        <TextInput
          placeholder="Refer Code"
          placeholderTextColor={colors.input}
          style={[styles.input, { marginVertical: scale(10) }]}
          value={refCode}
          onChangeText={setRefCode}
          selectionColor={colors.whilegrey}
        />
      )}
      {otpSent ? (
        <>
          <Typography
            title="Enter One Time Password (OTP)"
            style={styles.otpText}
          />
          <OTPInputView
            style={{
              width: scale(240),
              height: verticalScale(50),
              justifyContent: "center",
              alignItems: "center",
            }}
            pinCount={4}
            autoFocusOnLoad={false}
            selectionColor={colors.white}
            codeInputFieldStyle={{
              width: scale(50),
              height: scale(50),
              borderRadius: scale(10),
              marginHorizontal: scale(5),
              borderWidth: 0,
              backgroundColor: colors.primary,
              fontSize: scale(24),
              textAlign: "center",
              color: colors.white,
            }}
            codeInputHighlightStyle={{
              borderColor: colors.white,
              borderWidth: scale(1),
            }}
            onCodeFilled={verifyOTP}
            editable={time > 0}
          />
          <Typography
            title={`OTP Expires in ${secondsToMinutes(time)}`}
            style={styles.otpTime}
          />
        </>
      ) : (
        <TouchableOpacity style={styles.btn} onPress={sendOTP}>
          <Img source={icons.code} style={styles.code} />
          <Typography title="Request OTP" style={styles.btnText} />
        </TouchableOpacity>
      )}
    </ScrollView>
  );
};

export default Login;
