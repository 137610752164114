import {
  View,
  StyleSheet,
  FlatList,
  RefreshControl,
  ActivityIndicator,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import { scale, verticalScale } from "react-native-size-matters";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Typography from "./typography";
import Img from "./image";

import colors from "../contants/colors";
import images from "../contants/images";

import api, { scoreBaseURL } from "../functions/api";
import moment from "moment";
import Loader from "./loader";
import { useIsFocused } from "@react-navigation/native";
import Placeholder from "./placeholder";
import icons from "../contants/icons";

const TeamVs = ({ all, matchData }) => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(true);
  const [showLoader, setShowLoader] = useState(true);

  const user = useSelector((s) => s.user.value);

  const isFocused = useIsFocused();

  const getData = async (_page) => {
    try {
      const res = await api.get(
        all
          ? `/joined-contest?numOfItem=${5}&page=${_page || page}&user=${user}`
          : `/joined-contest?numOfItem=${5}&page=${
              _page || page
            }&user=${user}&match=${matchData._id}`
      );

      _page === 1 && setPage(_page);
      _page === 1 || page === 1 ? setData(res) : setData([...data, ...res]);
      _page === 1 && setIsLoading(true);
      res.length < 5 && setIsLoading(false);
    } catch (err) {
      console.log("🚀 ~ file: stakeBall.js:16 ~ getData ~ err:", err);
    }
    setRefreshing(false);
    setShowLoader(false);
  };

  useEffect(() => {
    getData();
  }, [page, isFocused, matchData]);

  return (
    <>
      {showLoader ? (
        <Placeholder heigh={verticalScale(150)} item={5} padding={scale(15)} />
      ) : (
        <>
          {data.length > 0 && (
            <FlatList
              data={data}
              style={{
                height: all
                  ? Dimensions.get("window").height - verticalScale(220)
                  : verticalScale(380),
              }}
              showsVerticalScrollIndicator={false}
              contentContainerStyle={styles.flatList}
              onEndReached={() => setPage(page + 1)}
              refreshControl={
                <RefreshControl
                  refreshing={refreshing}
                  onRefresh={() => {
                    setRefreshing(true);
                    getData(1);
                    setPage(1);
                  }}
                  colors={[colors.btn]}
                  tintColor={colors.btn}
                />
              }
              ListFooterComponent={() =>
                isLoading ? (
                  <ActivityIndicator
                    size={50}
                    color={colors.white}
                    style={{ marginVertical: verticalScale(20) }}
                  />
                ) : (
                  <></>
                )
              }
              renderItem={({ item, index }) => (
                <View style={styles.container}>
                  <View style={styles.playerResult}>
                    <Typography title="Prize Pool" style={styles.playerName} />
                    <Typography
                      title={`Rs. ${item.contInfo.pricePool}`}
                      style={styles.batsman}
                    />
                    <View style={styles.teamSpots}>
                      <Typography title="Spots - " style={styles.playerName} />
                      <Typography title="2" style={styles.rupees} />
                    </View>
                    <View style={styles.teamSpots}>
                      <Typography title="Entry - " style={styles.playerName} />
                      <Typography
                        title={"Rs. " + item.contInfo.entryFee}
                        style={styles.rupees}
                      />
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      title={item.vs}
                      style={{
                        fontSize: scale(10),
                        marginVertical: scale(5),
                        marginLeft: scale(10),
                        color: colors.lightgrey,
                      }}
                    />
                    <Typography
                      title={moment(item.contInfo.createdAt).format(
                        "DD MMM YY hh:mm:ss"
                      )}
                      style={{
                        fontSize: scale(10),
                        marginVertical: scale(5),
                        marginRight: scale(10),
                        color: colors.lightgrey,
                      }}
                    />
                  </View>
                  <View
                    style={[
                      styles.teamName,
                      {
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                    ]}
                  >
                    {item.contInfo.winner == user && (
                      <View
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <Img
                          source={icons.worldCup}
                          style={{
                            width: scale(16),
                            marginRight: scale(2),
                            height: verticalScale(13),
                            marginTop: verticalScale(5),
                          }}
                        />
                        <Typography
                          title={
                            "Congratulations You Won Rs. " +
                            item.contInfo.pricePool
                          }
                          style={styles.won}
                        />
                      </View>
                    )}

                    {item.oppPlayer &&
                      item.myPlayer &&
                      item.oppPlayer.points == item.myPlayer.points && (
                        <Typography
                          title="Tie"
                          style={{
                            ...styles.won,
                            color: colors.white,
                            marginLeft: scale(5),
                          }}
                        />
                      )}

                    {item.contInfo.winner &&
                      item.contInfo.winner != 0 &&
                      item.contInfo.winner != user && (
                        <Typography
                          title="Hey Champ Hard Luck Try More"
                          style={{
                            ...styles.won,
                            color: colors.white,
                            marginLeft: scale(5),
                          }}
                        />
                      )}
                  </View>
                  <View style={styles.team}>
                    <View style={styles.player}>
                      <Typography
                        title={
                          item.oppPlayer ? "USER-" + (item.oppUser + 15553) : ""
                        }
                        style={styles.userName}
                      />
                      <Typography title="You" style={styles.userName} />
                    </View>
                    <View style={styles.matche}>
                      {item.oppPlayer ? (
                        <View style={{ alignItems: "center" }}>
                          <Img
                            source={{
                              uri: scoreBaseURL + item.oppPlayer.image,
                            }}
                            style={[
                              styles.rohit,
                              { marginBottom: verticalScale(2) },
                            ]}
                          />
                          <Typography
                            style={styles.playerName}
                            title={item.oppPlayer.shortName}
                          />
                        </View>
                      ) : (
                        <View
                          style={{
                            alignItems: "left",
                            width: scale(110),
                            height: scale(50),
                            marginTop: scale(-20),
                          }}
                        >
                          <Img
                            source={images.user}
                            style={{
                              width: scale(45),
                              height: scale(45),
                              marginLeft: scale(5),
                            }}
                            resizeMode="cover"
                          />
                          <Typography
                            title="Waiting"
                            style={{
                              fontSize: scale(11),
                              marginTop: scale(3),
                              marginLeft: scale(5),
                            }}
                          />
                        </View>
                      )}
                      {item.oppPlayer && (
                        <View style={styles.miPoints}>
                          <Typography title="Point’s" style={styles.points} />
                          <Typography
                            title={item.oppPlayer.points}
                            style={styles.run}
                          />
                        </View>
                      )}
                      <View>
                        <Typography title="VS" style={styles.matcheVs} />
                      </View>
                      <View style={styles.miPoints}>
                        <Typography title="Point’s" style={styles.points} />
                        <Typography
                          title={item.myPlayer.points}
                          style={styles.run}
                        />
                      </View>
                      {item.myPlayer ? (
                        <TouchableOpacity
                          disabled
                          style={{ position: "relative", alignItems: "center" }}
                        >
                          <Img
                            source={{ uri: scoreBaseURL + item.myPlayer.image }}
                            style={[
                              styles.rohit,
                              { marginBottom: verticalScale(2) },
                            ]}
                          />
                          <Typography
                            style={styles.playerName}
                            title={item.myPlayer.shortName}
                          />
                        </TouchableOpacity>
                      ) : (
                        <View>
                          <Typography title="Waiting" />
                        </View>
                      )}
                    </View>

                    {item.oppPlayer &&
                      (item.myPlayer.points > 0 || item.oppPlayer.points > 0) &&
                      item.myPlayer.points !== item.oppPlayer.points && (
                        <Typography
                          title="WINNING ZONE"
                          style={{
                            ...styles.winning,
                            ...(item.myPlayer.points > item.oppPlayer.points
                              ? { marginLeft: "auto" }
                              : { marginRight: "auto" }),
                          }}
                        />
                      )}
                  </View>
                  {item.contInfo.status == 1 && (
                    <>
                      <Typography
                        title="Contest is not fullfill"
                        style={{
                          width: "100%",
                          textAlign: "center",
                          marginTop: verticalScale(5),
                          color: colors.engRed,
                          fontSize: scale(12),
                        }}
                      />
                      <Typography
                        title="Amount has been refunded to your wallet"
                        style={{
                          marginBottom: verticalScale(10),
                          width: "100%",
                          textAlign: "center",
                          marginTop: verticalScale(2),
                          color: colors.yellow,
                          fontSize: scale(10),
                        }}
                      />
                    </>
                  )}
                </View>
              )}
            />
          )}
        </>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.primary,
    marginHorizontal: scale(15),
    borderRadius: scale(10),
    marginBottom: scale(13),
    paddingBottom: verticalScale(3),
  },
  playerResult: {
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: scale(1),
    borderBottomColor: colors.bg,
    justifyContent: "space-between",
    paddingHorizontal: scale(10),
    height: scale(27),
  },
  teamSpots: {
    flexDirection: "row",
    alignItems: "center",
  },

  batsman: {
    backgroundColor: colors.bg,
    paddingHorizontal: scale(10),
    borderRadius: scale(20),
    fontSize: scale(11),
    paddingVertical: verticalScale(4),
  },
  playerName: {
    fontSize: scale(11),
    color: colors.darkgrey,
  },
  rupees: {
    fontSize: scale(11),
  },
  player: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: scale(4),
  },
  team: {
    paddingHorizontal: scale(10),
  },
  userName: {
    fontSize: scale(10),
  },
  matche: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  rohit: {
    width: scale(55),
    height: scale(60),
  },
  miPoints: {
    alignItems: "center",
  },
  points: {
    fontSize: scale(12),
  },
  run: {
    fontSize: scale(18),
  },
  matcheVs: {
    backgroundColor: colors.bg,
    paddingHorizontal: scale(15),
    borderRadius: scale(30),
    paddingVertical: verticalScale(3),
  },
  teamName: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: scale(2),
  },
  won: {
    fontSize: scale(10),
    color: colors.yellow,
    // marginLeft: scale(10),
    marginTop: scale(5),
  },
  winning: {
    marginVertical: verticalScale(3.5),
    color: colors.engGreen,
    fontSize: scale(9),
  },
  title: {
    marginLeft: scale(20),
    marginBottom: verticalScale(10),
  },
});

export default TeamVs;
