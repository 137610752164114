const images = {
  cricketImg: require("../assets/images/cricket-img.png"),
  rohit: require("../assets/images/rohit.png"),
  virat: require("../assets/images/virat.png"),
  graph: require("../assets/images/graph.png"),
  user: require("../assets/images/user.png"),
  ptsPoster: require("../assets/images/pts-poster.png"),
  splash1: require("../assets/images/splash1.png"),
  splash2: require("../assets/images/splash2.png"),
  splash3: require("../assets/images/splash3.png"),
  splash: require("../assets/images/splash.png"),
};

export default images;
