import {
  View,
  StyleSheet,
  TouchableOpacity,
  TextInput,
  ScrollView,
} from "react-native";
import { scale, verticalScale } from "react-native-size-matters";
import { useEffect, useState } from "react";

import Typography from "./typography";
import Img from "./image";
import Modal from "./modal";

import colors from "../contants/colors";
import icons from "../contants/icons";
import fonts from "../contants/fonts";
import gifs from "../contants/gifs";

import toast from "../functions/toast";
import { useSelector } from "react-redux";

const BetConfirm = ({
  show,
  hideModal = () => {},
  onChange = () => {},
  min = 10,
  max = 100000000,
  val,
  x,
  selected,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [amount, setAmount] = useState(null);
  const [fnAmount, setFnAmount] = useState(null);
  const [mult, setMult] = useState(1);
  const [sm, setSm] = useState(false);

  const betAmounts = [200, 500, 1000, 1200, 1500, 2000, 2500, 3000];

  const userInfo = useSelector((s) => s.userInfo.value);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  useEffect(() => {
    setFnAmount(val || "0");
    setAmount(val || "0");
  }, [val]);

  // useEffect(() => {
  //   if (sm) {
  //     if (userInfo.wallet >= fnAmount) {
  //       setShowModal(false);
  //       setTimeout(() => {
  //         setSm(false);
  //         onChange(fnAmount, amount, mult);
  //       }, 2000);
  //     } else {
  //       setSm(false);
  //       onChange(fnAmount, amount, mult);
  //     }
  //   }
  // }, [sm]);

  return (
    <>
      <Modal
        isVisible={sm}
        backdropOpacity={0.6}
        animationInTiming={6}
        animationOutTiming={6}
      >
        <View style={{ alignItems: "center", width: "100%" }}>
          <Img
            source={gifs.suc}
            style={{ width: scale(200), height: scale(200) }}
          />
        </View>
      </Modal>
      <Modal
        isVisible={showModal}
        style={{
          marginHorizontal: scale(13),
          flex: 1,
          zIndex: 999,
        }}
        onBackdropPress={() => {
          setAmount(val || "0");
          setFnAmount(val || "0");
          setMult(1);
          hideModal();
        }}
      >
        <ScrollView>
          <View style={styles.current}>
            <View style={styles.wallet}>
              <Img source={icons.money} style={styles.money} />
              <TouchableOpacity
                onPress={() => {
                  setFnAmount(val || "0");
                  setAmount(val || "0");
                  setMult(1);
                  hideModal();
                }}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  height: scale(12),
                  width: scale(12),
                }}
              >
                <Img source={icons.cancel} style={styles.cancel} />
              </TouchableOpacity>
            </View>
            {userInfo && (
              <View style={styles.amount}>
                <Img
                  source={icons.rupees}
                  style={[
                    styles.rupees,
                    {
                      marginTop: verticalScale(4),
                      marginRight: scale(5),
                      height: verticalScale(15),
                    },
                  ]}
                />
                <Typography
                  title={parseFloat(userInfo.wallet).toFixed(2)}
                  style={styles.ruText}
                />
              </View>
            )}
            <Typography
              title="Current In Your Wallet"
              style={{ ...styles.text, marginTop: verticalScale(4) }}
            />
          </View>
          <View style={styles.bet}>
            <Typography
              title="How Much Would You Like To Add"
              style={styles.text}
            />
            <View style={styles.amountText}>
              <Img source={icons.rupees} style={styles.amountIcon} />
              <TextInput
                style={styles.input}
                value={fnAmount === 0 ? amount : fnAmount}
                onChangeText={(text) => {
                  setMult(1);
                  setAmount(text);
                  setFnAmount(text);
                }}
                maxLength={10}
                keyboardType="numeric"
              />
            </View>
            <View style={styles.count}>
              <TouchableOpacity
                onPress={() => {
                  setFnAmount((parseInt(amount) * (mult - 1)).toString());
                  setMult(mult - 1);
                }}
                disabled={mult === 1}
              >
                <Typography title="-" style={styles.minus} />
              </TouchableOpacity>
              <Typography title={mult} style={styles.num} />
              <TouchableOpacity
                onPress={() => {
                  setFnAmount((amount * (mult + 1)).toString());
                  setMult(mult + 1);
                }}
              >
                <Typography title="+" style={styles.plus} />
              </TouchableOpacity>
            </View>
            <Typography
              title={"Total Bet Amount : " + parseFloat(fnAmount).toFixed(2)}
              style={styles.text}
            />
            {x && (
              <Typography
                title={
                  "Total winning Amount : " +
                  (parseFloat(fnAmount) * parseFloat(x)).toFixed(2)
                }
                style={{ ...styles.text, color: colors.engGreen }}
              />
            )}
            {selected && (
              <Typography
                title={"You selected : " + selected}
                style={styles.text}
              />
            )}
            <View
              style={[
                styles.addAmount,
                { flexWrap: "wrap", justifyContent: "center" },
              ]}
            >
              {betAmounts.map((item) => (
                <TouchableOpacity
                  style={styles.price}
                  onPress={() => {
                    setMult(1);
                    setAmount(item.toString());
                    setFnAmount(item.toString());
                  }}
                >
                  <Img source={icons.rupees} style={styles.rupeesIcon} />
                  <Typography title={item} style={styles.addRupees} />
                </TouchableOpacity>
              ))}
            </View>
            <Typography
              title="You Are  Agreeing to Crickstox user Agreement And Privacy Policy"
              style={styles.textTitle}
            />
            <TouchableOpacity
              onPress={() => {
                if (fnAmount < min) return toast(`Enter minimum amount ${min}`);
                if (fnAmount > max) return toast(`Enter maximum amount ${max}`);
                onChange(fnAmount, amount, mult);
              }}
            >
              <Typography title="Bet Confirm" style={styles.btn} />
            </TouchableOpacity>
          </View>
        </ScrollView>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  wallet: {
    flexDirection: "row",
    alignItem: "center",
    justifyContent: "space-between",
  },
  money: {
    width: scale(22),
    height: scale(22),
    tintColor: colors.white,
  },
  cancel: {
    width: scale(12),
    height: scale(12),
  },
  amount: {
    flexDirection: "row",
    alignItem: "center",
    justifyContent: "center",
    marginTop: scale(20),
  },
  rupees: {
    tintColor: colors.white,
    width: scale(20),
    height: scale(20),
    marginTop: scale(7),
  },
  ruText: {
    color: colors.yellow,
    fontSize: scale(20),
    fontWeight: fonts._700,
  },
  text: {
    fontSize: scale(12),
    textAlign: "center",
    // marginBottom: verticalScale(5),
    marginTop: verticalScale(2.5),
    marginBottom: verticalScale(2.5),
  },
  bet: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.bg,
    borderColor: colors.btn,
    borderWidth: 1,
    borderRadius: scale(27),
    marginHorizontal: scale(10),
    marginTop: scale(-120),
    paddingTop: scale(35),
  },
  current: {
    backgroundColor: colors.primary,
    paddingHorizontal: scale(18),
    paddingVertical: scale(12),
    borderRadius: scale(25),
    height: scale(250),
  },
  amountIcon: {
    tintColor: colors.white,
    width: scale(25),
    height: scale(22),
    marginTop: scale(7),
  },
  amountText: {
    flexDirection: "row",
    alignItems: "center",
  },
  border: {
    width: scale(120),
    height: scale(1),
    backgroundColor: colors.white,
    marginTop: scale(25),
  },
  count: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: scale(15),
    marginBottom: scale(20),
  },
  minus: {
    backgroundColor: colors.white,
    color: colors.black,
    fontSize: scale(18),
    paddingHorizontal: scale(10),
    borderRightWidth: scale(2),
    borderRightColor: colors.user,
    // paddingVertical: scale(1),
  },
  plus: {
    backgroundColor: colors.white,
    color: colors.black,
    fontSize: scale(18),
    paddingHorizontal: scale(10),
    borderLeftColor: colors.user,
    borderLeftWidth: scale(2),
    // paddingVertical: scale(1),
  },
  num: {
    backgroundColor: colors.white,
    color: colors.black,
    fontSize: scale(15),
    paddingHorizontal: scale(40),
    paddingVertical: scale(2),
  },
  price: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: colors.btn,
    borderRadius: scale(5),
    width: scale(63),
    margin: scale(4),
    justifyContent: "center",
    paddingVertical: scale(2),
  },
  rupeesIcon: {
    width: scale(14),
    height: scale(14),
    tintColor: colors.white,
  },
  addRupees: {
    fontSize: scale(14),
    fontWeight: fonts._700,
    marginLeft: scale(2),
  },
  addAmount: {
    flexDirection: "row",
    marginTop: scale(25),
  },
  rupeesText: {
    flexDirection: "row",
    marginTop: scale(8),
  },
  textTitle: {
    textAlign: "center",
    fontSize: scale(12),
    letterSpacing: scale(-0.1),
    width: scale(280),
    marginTop: scale(15),
    paddingHorizontal: scale(5),
  },
  btn: {
    fontSize: scale(16),
    backgroundColor: colors.btn,
    fontWeight: fonts._600,
    borderRadius: scale(100),
    paddingHorizontal: scale(80),
    paddingVertical: scale(10),
    marginTop: scale(20),
    marginBottom: scale(25),
  },
  input: {
    borderBottomColor: colors.engGreen,
    borderBottomWidth: scale(1),
    color: colors.white,
    minWidth: scale(50),
    fontSize: scale(20),
    textAlign: "center",
    maxWidth: scale(100),
    height: verticalScale(25),
  },
});

export default BetConfirm;
