import { StyleSheet } from "react-native";
import { Dimensions } from "react-native";

import colors from "../../../contants/colors";
import fonts from "../../../contants/fonts";
import { scale, verticalScale } from "react-native-size-matters";

const styles = StyleSheet.create({
  container: {
    width: "100%",
    minHeight: "100%",
    backgroundColor: colors.bg,
  },
  playTime: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    // marginVertical: 20,
    marginTop: verticalScale(20),
  },
  text: {
    fontSize: scale(9),
    fontWeight: fonts._500,
  },
  howTo: {
    fontSize: scale(9),
    fontWeight: fonts._500,
    color: colors.yellow,
    backgroundColor: colors.dark,
    marginLeft: scale(10),
    paddingHorizontal: scale(15),
    paddingVertical: verticalScale(2),
    borderRadius: scale(100),
  },
});

export default styles;
