import { useState } from "react";
import { View, TouchableOpacity } from "react-native";
import { TabView, SceneMap } from "react-native-tab-view";

import Typography from "../../../components/typography";
import Img from "../../../components/image";
import icons from "../../../contants/icons";

import styles from "./styles";
import LogoTabBar from "../../../components/logoTabBar";
import { useSelector } from "react-redux";
import { useIsFocused, useNavigation } from "@react-navigation/native";

const Four = () => {
  const userInfo = useSelector((s) => s.userInfo.value);

  const { navigate } = useNavigation();

  const isFocused = useIsFocused();

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Img source={icons.logo} style={styles.logo} />
        {userInfo && (
          <TouchableOpacity
            style={styles.pocketRupees}
            onPress={() => navigate("Recharge")}
          >
            <Img source={icons.pocket} style={styles.pocket} />
            <Typography
              title={parseFloat(userInfo.wallet).toFixed(2)}
              style={styles.rupees}
            />
            <Img source={icons.add} style={styles.add} />
          </TouchableOpacity>
        )}
      </View>
      {isFocused && <LogoTabBar />}
    </View>
  );
};

export default Four;
