import { View, TouchableOpacity, StyleSheet, ScrollView } from "react-native";

import colors from "../contants/colors";

import Typography from "./typography";
import Balls from "./balls";
import BuyResult from "./buyResult";
import TeamVs from "./teamVs";
import Graph from "./graph";
import Players from "./players";
import BattingInd from "./battingInd";
import { useDispatch, useSelector } from "react-redux";
import { setMatchTab } from "../redux/slices";
import { scale, verticalScale } from "react-native-size-matters";
import { useEffect } from "react";
import { useRoute } from "@react-navigation/native";
import Sessions from "./sessions";

const TabBar = (props) => {
  const dispatch = useDispatch();

  const { params } = useRoute();

  let tabIndex = useSelector((s) => s.matchTab.value);

  const tabs = [
    { title: "Ball To Ball", comp: () => <Balls {...props} /> },
    { title: "Sessions", comp: () => <Sessions {...props} /> },
    { title: "Runrate Sessions", comp: () => <Graph {...props} /> },
    { title: "My Buy Player`s", comp: () => <BuyResult {...props} /> },
    { title: "My Contest`s", comp: () => <TeamVs {...props} /> },
    { title: "Scorecard", comp: () => <BattingInd {...props} /> },
    { title: "Players Stats", comp: () => <Players {...props} /> },
  ];

  useEffect(() => {
    props.onChangeTab(tabs[tabIndex]);
  }, [tabIndex]);

  useEffect(() => {
    if (params.tabIndex) {
      dispatch(setMatchTab(params.tabIndex));
    }
  }, []);

  return (
    <View>
      <View
        style={{
          height: verticalScale(30),
          marginVertical: verticalScale(20),
        }}
      >
        <ScrollView
          contentContainerStyle={styles.tabBar}
          horizontal
          showsHorizontalScrollIndicator={false}
          contentOffset={{ x: tabIndex * scale(100) }}
        >
          {tabs.map((tab, index) => (
            <TouchableOpacity
              key={index}
              onPress={() => dispatch(setMatchTab(index))}
              style={[
                styles.tab,
                tabIndex === index && styles.tabActive,
                props.matchData &&
                  props.matchData.status === "Completed" &&
                  index != 2 &&
                  index != 3 &&
                  index != 6 &&
                  index != 5 && { opacity: 0.5 },
                props.matchData &&
                  props.matchData.status === "Upcoming" &&
                  index != 1 && { opacity: 0.5 },
              ]}
              disabled={
                (props.matchData &&
                  props.matchData.status === "Completed" &&
                  index != 2 &&
                  index != 3 &&
                  index != 6 &&
                  index != 5) ||
                (props.matchData &&
                  props.matchData.status === "Upcoming" &&
                  index != 1)
              }
            >
              <Typography
                title={tab.title}
                style={{
                  ...styles.tabTitle,
                  ...(tabIndex === index && { color: "#fff" }),
                }}
              />
            </TouchableOpacity>
          ))}
        </ScrollView>
      </View>
      {tabs[tabIndex] && tabs[tabIndex].comp()}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    // flex: 1,
  },
  tabBar: {
    // width: '100%',
    justifyContent: "center",
    height: verticalScale(30),
  },
  tab: {
    marginHorizontal: scale(5),
    paddingHorizontal: scale(10),
    backgroundColor: "#FFFFFF26",
    borderRadius: scale(100),
    justifyContent: "center",
    // paddingVertical: verticalScale(5),
  },
  tabTitle: {
    fontSize: scale(12),
    color: "#FFFFFF99",
  },
  tabActive: {
    // borderBottomWidth: scale(2),
    backgroundColor: colors.btn,
  },
});

export default TabBar;
