import {createSlice} from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    value: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const {setUser} = userSlice.actions;
export const userReducer = userSlice.reducer;

const loaderSlice = createSlice({
  name: 'showLoader',
  initialState: {
    value: false,
  },
  reducers: {
    setShowLoader: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const {setShowLoader} = loaderSlice.actions;
export const loaderReducer = loaderSlice.reducer;

const matchTabSlice = createSlice({
  name: 'matchTab',
  initialState: {
    value: 0,
  },
  reducers: {
    setMatchTab: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const {setMatchTab} = matchTabSlice.actions;
export const matchTabReducer = matchTabSlice.reducer;

const listTabSlice = createSlice({
  name: 'listTab',
  initialState: {
    value: 0,
  },
  reducers: {
    setListTab: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const {setListTab} = listTabSlice.actions;
export const listTabReducer = listTabSlice.reducer;

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState: {
    value: null,
  },
  reducers: {
    setUserInfo: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const {setUserInfo} = userInfoSlice.actions;
export const userInfoReducer = userInfoSlice.reducer;

const infoSlice = createSlice({
  name: 'info',
  initialState: {
    value: {},
  },
  reducers: {
    setInfo: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const {setInfo} = infoSlice.actions;
export const infoReducer = infoSlice.reducer;
