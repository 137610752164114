import {
  View,
  TouchableOpacity,
  ImageBackground,
  TextInput,
  ScrollView,
  useWindowDimensions,
} from 'react-native';
import {useEffect, useState} from 'react';
import {scale, verticalScale} from 'react-native-size-matters';

import Typography from '../../../components/typography';
import Img from '../../../components/image';

import styles from './styles';

import icons from '../../../contants/icons';
import images from '../../../contants/images';
import {useNavigation} from '@react-navigation/native';
import colors from '../../../contants/colors';

const PointSystem = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [points, setPoints] = useState({});

  const {height} = useWindowDimensions();

  const {goBack} = useNavigation();

  const cart = [
    {
      title: 'T20',
    },
    {
      title: 'ODI',
    },
    {
      title: 'Test',
    },
    {
      title: 'T10',
    },
    {
      title: 'OtherT20',
    },
    {
      title: 'Other ODI',
    },
  ];

  const [Batting, setBatting] = useState(true);
  const [Bowling, setBowling] = useState(true);
  const [Fielding, setFielding] = useState(true);
  const [Additional, setAdditional] = useState(true);

  const handlePress = section => {
    switch (section) {
      case 'batting':
        setBatting(!Batting);
        break;
      case 'bowling':
        setBowling(!Bowling);
        break;
      case 'fielding':
        setFielding(!Fielding);
        break;
      case 'additional':
        setAdditional(!Additional);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (tabIndex === 0) {
      setPoints({
        run: 4,
        four: 1,
        six: 2,
        wickets: 25,
        lbw: 8,
        bowled: 8,
        maiden: 12,
        catch: 12,
        stumping: 20,
        directHit: 12,
        notHit: 8,
        Frun: 5,
        Hrun: 10,
        threeWic: 10,
        fourWic: 15,
      });
    } else if (tabIndex === 1) {
      setPoints({
        run: 1,
        four: 1,
        six: 2,
        wickets: 25,
        lbw: 8,
        bowled: 8,
        maiden: 6,
        catch: 12,
        stumping: 20,
        directHit: 12,
        notHit: 8,
        Frun: 5,
        Hrun: 10,
        threeWic: 10,
        fourWic: 15,
      });
    }
    if (tabIndex === 2) {
      setPoints({
        run: 1,
        four: 1,
        six: 2,
        wickets: 20,
        lbw: 6,
        bowled: 6,
        maiden: 6,
        catch: 6,
        stumping: 10,
        directHit: 8,
        notHit: 4,
        Frun: 5,
        Hrun: 10,
        threeWic: 5,
        fourWic: 5,
      });
    } else if (tabIndex === 3) {
      setPoints({
        run: 4,
        four: 1,
        six: 2,
        wickets: 25,
        lbw: 8,
        bowled: 8,
        maiden: 6,
        catch: 12,
        stumping: 20,
        directHit: 12,
        notHit: 8,
        Frun: 10,
        Hrun: 20,
        threeWic: 15,
        fourWic: 20,
      });
    }
    if (tabIndex === 4) {
      setPoints({
        run: 4,
        four: 1,
        six: 2,
        wickets: 25,
        lbw: 8,
        bowled: 8,
        maiden: 8,
        catch: 12,
        stumping: 20,
        directHit: 12,
        notHit: 8,
        Frun: 5,
        Hrun: 10,
        threeWic: 10,
        fourWic: 15,
      });
    } else if (tabIndex === 5) {
      setPoints({
        run: 1,
        four: 1,
        six: 2,
        wickets: 25,
        lbw: 8,
        bowled: 8,
        maiden: 6,
        catch: 12,
        stumping: 20,
        directHit: 12,
        notHit: 8,
        Frun: 5,
        Hrun: 10,
        threeWic: 10,
        fourWic: 15,
      });
    }
  }, [tabIndex]);

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <View style={styles.backScreen}>
          <TouchableOpacity onPress={goBack}>
            <Img source={icons.back} style={styles.back} />
          </TouchableOpacity>
          <Typography title="Point System" style={styles.headerText} />
        </View>
      </View>

      <View style={styles.pointSystem}>
        <Img source={images.ptsPoster} style={styles.ptsPoster} />
        <Typography
          title="NOTE : Points are same  BUY PLAYERS or CONTEST’S"
          style={styles.noteText}
        />

        <ScrollView
          contentContainerStyle={styles.tabBar}
          horizontal
          showsHorizontalScrollIndicator={false}>
          {cart.map((item, index) => (
            <TouchableOpacity
              style={[
                styles.tab,
                index === tabIndex && {
                  backgroundColor: colors.btn,
                },
              ]}
              onPress={() => setTabIndex(index)}>
              <Typography
                title={item.title}
                style={{
                  ...styles.tabTitle,
                }}
              />
            </TouchableOpacity>
          ))}
        </ScrollView>

        <ScrollView
          style={{height: height - verticalScale(270)}}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{paddingBottom: verticalScale(100)}}>
          <View style={styles.batting}>
            <TouchableOpacity
              style={styles.pointBatting}
              onPress={() => handlePress('batting')}>
              <Typography title="Batting" style={styles.title} />
              <Img source={icons.down} style={styles.down} />
            </TouchableOpacity>
            {Batting && (
              <>
                <ImageBackground source={icons.ptsBg} style={styles.ptsBg}>
                  <View style={styles.rubPts}>
                    <Typography title="Run" style={styles.run} />
                    <Typography
                      title={`+${points.run} pts / INR`}
                      style={styles.pts}
                    />
                  </View>
                </ImageBackground>
                <ImageBackground source={icons.ptsBg} style={styles.ptsBg}>
                  <View style={styles.rubPts}>
                    <Typography title="Four Bonus +" style={styles.run} />
                    <Typography
                      title={`+${points.four} pts / INR`}
                      style={styles.pts}
                    />
                  </View>
                </ImageBackground>
                <ImageBackground source={icons.ptsBg} style={styles.ptsBg}>
                  <View style={styles.rubPts}>
                    <Typography title="Six Bonus +" style={styles.run} />
                    <Typography
                      title={`+${points.six} pts / INR`}
                      style={styles.pts}
                    />
                  </View>
                </ImageBackground>
              </>
            )}
          </View>
          <View style={styles.batting}>
            <TouchableOpacity
              style={styles.pointBatting}
              onPress={() => handlePress('bowling')}>
              <Typography title="Bowling" style={styles.title} />
              <Img source={icons.down} style={styles.down} />
            </TouchableOpacity>
            {Bowling && (
              <>
                <ImageBackground source={icons.ptsBg} style={styles.ptsBg}>
                  <View style={styles.rubPts}>
                    <Typography title="Wicket" style={styles.run} />
                    <Typography
                      title="(Excluding Run Out)"
                      style={styles.runOut}
                    />
                    <Typography
                      title={`+${points.wickets} pts / INR`}
                      style={styles.pts}
                    />
                  </View>
                </ImageBackground>
                <ImageBackground source={icons.ptsBg} style={styles.ptsBg}>
                  <View style={styles.rubPts}>
                    <Typography title="LBW" style={styles.run} />
                    <Typography
                      title={`+${points.lbw} pts / INR`}
                      style={styles.pts}
                    />
                  </View>
                </ImageBackground>
                <ImageBackground source={icons.ptsBg} style={styles.ptsBg}>
                  <View style={styles.rubPts}>
                    <Typography title="Bowled" style={styles.run} />
                    <Typography
                      title={`+${points.bowled} pts / INR`}
                      style={styles.pts}
                    />
                  </View>
                </ImageBackground>
                <ImageBackground source={icons.ptsBg} style={styles.ptsBg}>
                  <View style={styles.rubPts}>
                    <Typography title="Maiden Over" style={styles.run} />
                    <Typography
                      title={`+${points.maiden} pts / INR`}
                      style={styles.pts}
                    />
                  </View>
                </ImageBackground>
              </>
            )}
          </View>
          <View style={styles.batting}>
            <TouchableOpacity
              style={styles.pointBatting}
              onPress={() => handlePress('fielding')}>
              <Typography title="Fielding" style={styles.title} />
              <Img source={icons.down} style={styles.down} />
            </TouchableOpacity>
            {Fielding && (
              <>
                <ImageBackground source={icons.ptsBg} style={styles.ptsBg}>
                  <View style={styles.rubPts}>
                    <Typography title="Catch" style={styles.run} />
                    <Typography
                      title={`+${points.catch} pts / INR`}
                      style={styles.pts}
                    />
                  </View>
                </ImageBackground>
                <ImageBackground source={icons.ptsBg} style={styles.ptsBg}>
                  <View style={styles.rubPts}>
                    <Typography title="Stumping" style={styles.run} />
                    <Typography
                      title={`+${points.stumping} pts / INR`}
                      style={styles.pts}
                    />
                  </View>
                </ImageBackground>
                <ImageBackground source={icons.ptsBg} style={styles.ptsBg}>
                  <View style={styles.rubPts}>
                    <Typography title="Run Out" style={styles.run} />
                    <Typography title="(Direct Hit)" style={styles.directHit} />
                    <Typography
                      title={`+${points.directHit} pts / INR`}
                      style={styles.pts}
                    />
                  </View>
                </ImageBackground>
                <ImageBackground source={icons.ptsBg} style={styles.ptsBg}>
                  <View style={styles.rubPts}>
                    <Typography title="Run Out" style={styles.run} />
                    <Typography
                      title="(Not a Direct Hit)"
                      style={styles.notHit}
                    />
                    <Typography
                      title={`+${points.notHit} pts / INR`}
                      style={styles.pts}
                    />
                  </View>
                </ImageBackground>
              </>
            )}
          </View>
          <View style={styles.batting}>
            <TouchableOpacity
              style={styles.pointBatting}
              onPress={() => handlePress('additional')}>
              <Typography title="Additional Point" style={styles.title} />
              <Img source={icons.down} style={styles.down} />
            </TouchableOpacity>
            {Additional && (
              <>
                <ImageBackground source={icons.ptsBg} style={styles.ptsBg}>
                  <View style={styles.rubPts}>
                    <Typography title="50+ Run" style={styles.run} />
                    <Typography
                      title={`+${points.Frun} pts / INR`}
                      style={styles.pts}
                    />
                  </View>
                </ImageBackground>
                <ImageBackground source={icons.ptsBg} style={styles.ptsBg}>
                  <View style={styles.rubPts}>
                    <Typography title="100+ Run" style={styles.run} />
                    <Typography
                      title={`+${points.Hrun} pts / INR`}
                      style={styles.pts}
                    />
                  </View>
                </ImageBackground>
                <ImageBackground source={icons.ptsBg} style={styles.ptsBg}>
                  <View style={styles.rubPts}>
                    <Typography title="3 Wickets Bonus" style={styles.run} />
                    <Typography
                      title={`+${points.threeWic} pts / INR`}
                      style={styles.pts}
                    />
                  </View>
                </ImageBackground>
                <ImageBackground source={icons.ptsBg} style={styles.ptsBg}>
                  <View style={styles.rubPts}>
                    <Typography title="4 Wickets Bonus" style={styles.run} />
                    <Typography
                      title={`+${points.fourWic} pts / INR`}
                      style={styles.pts}
                    />
                  </View>
                </ImageBackground>
              </>
            )}
          </View>
          <Typography
            title="BUY PLAYERS TO INR / CONTEST’S TO pts"
            style={styles.bottomText}
          />
        </ScrollView>
      </View>
    </View>
  );
};

export default PointSystem;
