import { Dimensions, StyleSheet } from "react-native";
import { scale } from "react-native-size-matters";

import colors from "../../../contants/colors";
import fonts from "../../../contants/fonts";

const { width, height } = Dimensions.get("window");

const styles = StyleSheet.create({
  container: {
    width,
    minHeight: "100%",
    backgroundColor: colors.bg,
    padding: scale(20),
    alignItems: "center",
  },
  prices: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    marginTop: scale(20),
  },
  price: {
    width: scale(85),
    backgroundColor: colors.primary,
    alignItems: "center",
    paddingVertical: scale(10),
    margin: scale(5),
    borderRadius: scale(6),
  },
  activePrice: {
    backgroundColor: colors.btn,
  },
  btn: {
    marginTop: scale(30),
    backgroundColor: colors.btn,
    alignItems: "center",
    justifyContent: "center",
    height: scale(40),
    borderRadius: scale(100),
    paddingHorizontal: scale(30),
  },
  btnText: {
    fontSize: scale(14),
  },
  input: {
    marginTop: scale(40),
  },
  back: {
    width: scale(0),
    height: scale(5),
  },
  title: {
    fontSize: scale(16),
  },
  header: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
  },
  back: {
    marginRight: scale(20),
    width: scale(25),
    height: scale(25),
  },
  succ: {
    height: scale(200),
    backgroundColor: colors.bg,
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    borderRadius: scale(20),
    width: width - scale(60),
  },
  successImg: {
    width: width * 2,
  },
  wallet: {
    padding: scale(20),
    backgroundColor: colors.primary,
    justifyContent: "center",
    marginTop: scale(30),
    borderRadius: scale(10),
    marginRight: "auto",
    borderWidth: 1,
    borderColor: "rgba(255,255,255,0.4)",
    paddingBottom: scale(10),
  },
  amount: {
    fontSize: scale(20),
    fontWeight: fonts._600,
    color: colors.engGreen,
  },
  otpText: {
    marginBottom: scale(10),
  },
  success: {
    width: "100%",
    height: height / 2,
    // backgroundColor: colors.bg,
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    borderRadius: scale(20),
  },
  successImg: {
    width: scale(400),
    height: scale(400),
  },
});

export default styles;
