import {StyleSheet} from 'react-native';
import {Dimensions} from 'react-native';

import colors from '../../../contants/colors';
import fonts from '../../../contants/fonts';
import {scale} from 'react-native-size-matters';

const windowWidth = Dimensions.get('window').width;
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.bg,
    paddingHorizontal: scale(15),
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: scale(10),
  },
  backScreen: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  back: {
    width: scale(25),
    height: scale(18),
  },
  headerText: {
    fontSize: scale(17),
    marginLeft: scale(15),
  },
  myBouns: {
    backgroundColor: colors.yellow,
    marginTop: scale(20),
    borderRadius: scale(10),
    flexDirection: 'row',
    justifyContent: 'space-between',

    paddingLeft: scale(15),
    height: scale(70),
    paddingTop: scale(5),
  },
  totalText: {
    fontSize: scale(15),
    color: colors.black,
  },
  giftIcon: {
    width: scale(50),
    height: scale(50),
    marginTop: scale(10),
    marginRight: scale(5),
  },
  copy: {
    fontSize: scale(10),
    textAlign: 'right',
    marginTop: scale(10),
  },
  refer: {
    backgroundColor: colors.yellow,
    borderRadius: scale(10),
    paddingHorizontal: scale(15),
    paddingVertical: scale(15),
    marginTop: scale(20),
  },
  code: {
    color: colors.black,
    fontSize: scale(15),
  },
});

export default styles;
