import {
  View,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
  RefreshControl,
  Dimensions,
} from "react-native";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { scale, verticalScale } from "react-native-size-matters";

import Typography from "./typography";
import Img from "./image";

import icons from "../contants/icons";
import colors from "../contants/colors";

import api, { scoreBaseURL } from "../functions/api";
import { useNavigation } from "@react-navigation/native";
import orderBy from "lodash.orderby";
import Placeholder from "./placeholder";

const BuyResult = ({ matchData, all }) => {
  const [players, setPlayers] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(true);
  const [showLoader, setShowLoader] = useState(true);

  const user = useSelector((s) => s.user.value);

  const { navigate } = useNavigation();

  const getBuyPlayers = async (_page) => {
    try {
      const res = await api.get(
        `/buy-players?user=${user}&match=${matchData._id}&page=${_page || page}`
      );
      _page === 1 && setPage(_page);
      _page === 1
        ? setPlayers(orderBy(res, "points.totalPoints", "desc"))
        : setPlayers(
            orderBy([...players, ...res], "points.totalPoints", "desc")
          );
      _page === 1 && setIsLoading(true);
      res.length < 10 && setIsLoading(false);
    } catch (err) {
      console.log("🚀 ~ file: buyResult.js:20 ~ getBuyPlayers ~ err:", err);
    }
    setRefreshing(false);
    setShowLoader(false);
  };

  const getAllBuyPlayers = async (_page) => {
    try {
      const res = await api.get(
        `/buy-players?user=${user}&page=${_page || page}`
      );
      _page === 1 && setPage(_page);
      _page === 1 || page === 1
        ? setPlayers(res)
        : setPlayers([...players, ...res]);
      (_page === 1 || page === 1) && setIsLoading(true);
      res.length < 10 && setIsLoading(false);
    } catch (err) {
      console.log("🚀 ~ file: buyResult.js:20 ~ getBuyPlayers ~ err:", err);
    }
    setRefreshing(false);
    setShowLoader(false);
  };

  useEffect(() => {
    !all ? getBuyPlayers() : getAllBuyPlayers();
  }, [page, matchData]);

  return (
    <>
      {showLoader ? (
        <Placeholder heigh={verticalScale(100)} padding={scale(15)} />
      ) : (
        <>
          {players.length > 0 && (
            <FlatList
              data={players}
              contentContainerStyle={styles.flatList}
              onEndReached={() => setPage(page + 1)}
              showsVerticalScrollIndicator={false}
              style={{
                height: all
                  ? Dimensions.get("window").height - verticalScale(230)
                  : verticalScale(380),
              }}
              refreshControl={
                <RefreshControl
                  refreshing={refreshing}
                  onRefresh={() => {
                    setRefreshing(true);
                    matchData ? getBuyPlayers(1) : getAllBuyPlayers(1);
                  }}
                  colors={[colors.btn]}
                  tintColor={colors.btn}
                />
              }
              ListFooterComponent={() =>
                isLoading ? (
                  <ActivityIndicator
                    size={50}
                    color={colors.white}
                    style={{ marginVertical: 20 }}
                  />
                ) : (
                  <></>
                )
              }
              renderItem={({ item, index }) => (
                <>
                  {item && (
                    <View style={styles.container}>
                      <View style={styles.playerResult}>
                        <Typography
                          title={item.info.shortName}
                          style={styles.playerName}
                          lines={1}
                        />
                        <View style={styles.bats}>
                          <Typography
                            title={
                              item.info.roll === "Batsmen"
                                ? "Bat"
                                : item.info.roll === "Bowler"
                                ? "Bow"
                                : item.info.roll === "Wicket Keeper"
                                ? "WK"
                                : item.info.roll === "All-Rounders"
                                ? "A-R"
                                : null
                            }
                            style={styles.batsman}
                          />
                          <Img source={icons.batBall} style={styles.batBall} />
                        </View>
                        <Typography title="Buy Price - " style={styles.price} />
                        <Typography
                          title={`Rs. ${
                            parseFloat(item.buyPrice) /
                            parseFloat(item.quantity)
                          }`}
                          style={styles.rupees}
                        />
                        <Typography
                          title={`Qty - ${item.quantity}`}
                          style={styles.qty}
                        />
                      </View>
                      <View style={styles.buyView}>
                        <Img
                          source={{ uri: scoreBaseURL + item.info.image }}
                          style={styles.rohit}
                        />
                        <View>
                          {item.status == 1 && (
                            <View style={styles.youWon}>
                              <Typography
                                title={`Player none announced\n   (Amount Refunded)`}
                                style={{
                                  ...styles.ru,
                                  color: colors.engRed,
                                  marginLeft: scale(30),
                                  // textAlign: 'center',
                                }}
                              />
                            </View>
                          )}

                          {item.status == 0 &&
                            item.points.totalPoints == 0 &&
                            item.info.matchStatus === "Completed" && (
                              <View style={styles.youWon}>
                                <Typography
                                  title="Your player has no points"
                                  style={{
                                    ...styles.ru,
                                    color: colors.white,
                                    marginLeft: scale(30),
                                  }}
                                />
                              </View>
                            )}

                          {item.status == 0 &&
                            item.points.totalPoints > 0 &&
                            item.info.matchStatus === "Completed" && (
                              <View style={styles.youWon}>
                                <Img
                                  source={icons.worldCup}
                                  style={styles.worldCup}
                                />
                                <Typography
                                  title={`Congratulations You Won Rs. ${
                                    parseFloat(item.points.totalPoints) *
                                    parseFloat(item.quantity)
                                  }`}
                                  style={styles.ru}
                                />
                              </View>
                            )}

                          {item.status == 0 && (
                            <TouchableOpacity
                              style={styles.performance}
                              onPress={() =>
                                navigate("PlayerPerformance", {
                                  players,
                                  player: index,
                                })
                              }
                            >
                              <Typography
                                title="View Performance"
                                style={styles.view}
                              />
                            </TouchableOpacity>
                          )}

                          <View style={styles.points}>
                            <Typography
                              title="Total Point’s - "
                              style={{ fontSize: scale(10) }}
                            />
                            <Typography
                              title={"+" + item.points.totalPoints}
                              style={styles.total}
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                  )}
                </>
              )}
            />
          )}
        </>
      )}
    </>
  );
};

const { width } = Dimensions.get("window");

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: scale(10),
    backgroundColor: colors.primary,
    marginHorizontal: scale(15),
    borderRadius: scale(20),
    marginTop: scale(10),
    width: width - scale(30),
  },
  playerResult: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: scale(5),
  },
  playerName: {
    fontSize: scale(10),
    borderRightWidth: scale(1),
    borderRightColor: colors.black5,
    paddingHorizontal: scale(7),
    width: scale(90),
  },
  batsman: {
    color: colors.darkgrey,
    fontSize: scale(10),
  },
  batBall: {
    width: scale(13),
    height: scale(15),
  },
  bats: {
    flexDirection: "row",
    alignItems: "center",
    borderRightWidth: scale(1),
    borderRightColor: colors.black5,
    paddingHorizontal: scale(7),
  },
  price: {
    color: colors.darkgrey,
    fontSize: scale(10),
    paddingHorizontal: scale(7),
  },
  rupees: {
    fontSize: scale(9),
    borderColor: colors.engGreen,
    borderWidth: scale(1),
    paddingHorizontal: scale(7),
    paddingVertical: scale(1),
    height: scale(20),
    paddingTop: scale(3),
    whiteSpace: "nowrap",
  },
  qty: {
    fontSize: scale(10),
    color: colors.darkgrey,
    paddingHorizontal: scale(10),
    whiteSpace: "nowrap",
  },
  rohit: {
    width: scale(60),
    height: scale(60),
  },
  buyView: {
    flexDirection: "row",
  },
  view: {
    marginLeft: scale(25),
    backgroundColor: colors.whilegrey,
    paddingHorizontal: scale(10),
    paddingVertical: scale(2),
    borderRadius: 20,
    width: scale(115),
    fontSize: scale(10),
  },

  performance: {
    marginTop: verticalScale(10),
    marginLeft: scale(20),
  },
  points: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: scale(8),
    marginLeft: scale(100),
    marginBottom: scale(5),
  },
  total: {
    backgroundColor: colors.bg,
    color: colors.engGreen,
    paddingHorizontal: scale(10),
    fontSize: scale(13),
    borderRadius: scale(5),
  },
  worldCup: {
    width: scale(25),
    height: scale(25),
    marginTop: verticalScale(7),
  },
  youWon: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: scale(5),
    marginLeft: scale(10),
  },
  ru: {
    fontSize: scale(10),
    color: colors.yellow,
    marginTop: verticalScale(7),
  },
  flatList: {
    width: "100%",
    paddingBottom: verticalScale(15),
    alignItems: "center",
  },
  title: {
    marginLeft: scale(20),
  },
});

export default BuyResult;
