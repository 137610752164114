import {StyleSheet} from 'react-native';
import {Dimensions} from 'react-native';
import {scale} from 'react-native-size-matters';

import colors from '../../../contants/colors';

const windowWidth = Dimensions.get('window').width;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black5,
    // height: '100%',
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: scale(10),
    paddingHorizontal: scale(20),
  },
  backScreen: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  back: {
    width: scale(25),
    height: scale(18),
  },
  headerText: {
    fontSize: scale(17),
    marginLeft: scale(15),
  },
  pointSystem: {
    backgroundColor: colors.primary,
    minHeight: '100%',
    width: '100%',
    marginTop: scale(10),
    borderTopRightRadius: scale(7),
    borderTopLeftRadius: scale(7),
    paddingHorizontal: scale(20),
  },
  ptsPoster: {
    width: '100%',
    height: scale(190),
    borderRadius: scale(7),
    marginTop: scale(20),
  },
  noteText: {
    fontSize: scale(11),
    textAlign: 'center',
    marginTop: scale(10),
  },

  tabBar: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: scale(10),
    height: scale(20),
    marginBottom: scale(10),
  },

  tab: {
    marginHorizontal: scale(5),
    backgroundColor: colors.bg,
    borderWidth: 1,
    borderColor: colors.white,
    borderRadius: scale(20),
    paddingHorizontal: scale(10),
    alignItems: 'center',
    justifyContent: 'center',
  },
  batting: {
    backgroundColor: colors.bg,
    borderRadius: scale(10),
    padding: scale(15),
    marginTop: scale(10),
  },
  pointBatting: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: scale(5),
  },
  ptsBg: {
    width: '100%',
    height: scale(32),
    marginTop: scale(10),
    justifyContent: 'center',
  },
  run: {
    fontSize: scale(14),
    color: colors.bg,
  },
  pts: {
    fontSize: scale(14),
    color: colors.engGreen,
  },
  rubPts: {
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
    flexDirection: 'row',
  },
  title: {
    fontSize: scale(14),
  },
  down: {
    width: scale(20),
    height: scale(10),
  },
  runOut: {
    fontSize: scale(8),
    color: colors.bg,
    marginRight: scale(20),
  },
  directHit: {
    fontSize: scale(8),
    color: colors.bg,
    marginRight: scale(45),
  },
  notHit: {
    fontSize: scale(8),
    color: colors.bg,
    marginRight: scale(25),
  },
  bottomText: {
    fontSize: scale(14),
    textAlign: 'center',
    marginTop: scale(20),
  },
});

export default styles;
