import { StyleSheet } from "react-native";
import { Dimensions } from "react-native";

import colors from "../../../contants/colors";
import fonts from "../../../contants/fonts";
import { scale, verticalScale } from "react-native-size-matters";

const windowWidth = Dimensions.get("window").width;
const { height } = Dimensions.get("window");

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black5,
    width: "100%",
    minHeight: "100vh",
  },
  bgImg: {
    zIndex: -1,
    position: "absolute",
    marginTop: verticalScale(40),
    width: scale(320),
    height: height - verticalScale(50),
  },
  input: {
    backgroundColor: colors.primary,
    width: windowWidth - scale(30),
    borderRadius: scale(200),
    height: verticalScale(56),
    paddingHorizontal: scale(50),
    color: colors.white,
    fontSize: scale(14),
  },
  mobailIcon: {
    position: "absolute",
    width: scale(16),
    height: scale(25),
    marginLeft: scale(20),
  },
  mobailNum: {
    justifyContent: "center",
    marginTop: verticalScale(100),
  },
  otpText: {
    color: colors.input,
    fontSize: scale(14),
    marginVertical: verticalScale(20),
    fontWeight: fonts._500,
  },
  otpTime: {
    marginVertical: verticalScale(20),
    fontSize: scale(14),
    fontWeight: fonts._500,
  },
  btn: {
    width: scale(200),
    height: verticalScale(56),
    backgroundColor: colors.btn,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: scale(15),
    borderRadius: scale(200),
    justifyContent: "center",
    marginTop: verticalScale(20),
  },
  code: {
    width: scale(30),
    height: scale(30),
  },
  btnText: {
    fontSize: scale(14),
    fontWeight: fonts._600,
    paddingLeft: scale(13),
  },
  nunOtp: {
    width: scale(50),
    height: scale(50),
    backgroundColor: colors.primary,
    borderRadius: scale(10),
    borderWidth: scale(1),
    borderColor: colors.white,
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    width: scale(200),
    height: scale(100),
    marginTop: verticalScale(40),
  },
});

export default styles;
