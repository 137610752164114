import { Dimensions, StyleSheet } from "react-native";

import colors from "../../../contants/colors";
import fonts from "../../../contants/fonts";
import { scale, verticalScale } from "react-native-size-matters";

const { height } = Dimensions.get("window");

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.black5,
    alignItems: "center",
    width: "100%",
    minHeight: "100vh",
  },
  bgImg: {
    zIndex: -1,
    position: "absolute",
    marginTop: verticalScale(40),
    width: scale(320),
    height: height - verticalScale(50),
  },
  otpText: {
    color: colors.input,
    fontSize: scale(14),
    marginVertical: verticalScale(20),
    fontWeight: fonts._500,
  },

  btn: {
    width: scale(200),
    height: scale(55),
    backgroundColor: colors.btn,
    borderRadius: scale(200),
    justifyContent: "center",
    marginTop: verticalScale(20),
    alignItems: "center",
  },
  btnText: {
    fontSize: scale(14),
    fontWeight: fonts._600,
  },
  logo: {
    width: scale(200),
    height: scale(100),
    marginTop: verticalScale(40),
  },
});

export default styles;
