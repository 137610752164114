import { useNavigation } from "@react-navigation/native";
import { View, TouchableOpacity, ScrollView } from "react-native";
import Typography from "../../../components/typography";
import Img from "../../../components/image";
import styles from "./styles";
import icons from "../../../contants/icons";
import Input from "../../../components/input";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import api, { apiBaseURL } from "../../../functions/api";
import toast from "../../../functions/toast";
import { useEffect, useState } from "react";
import Loader from "../../../components/loader";
import { scale } from "react-native-size-matters";

const CompleteKYC = () => {
  const [showLoader, setShowLoader] = useState(false);

  const { goBack } = useNavigation();

  const userInfo = useSelector((s) => s.userInfo.value);

  const initialValues = {
    accNo: null,
    ifscCode: null,
    state: null,
    city: null,
    upiID: null,
    email: null,
  };

  const validationSchema = yup.object().shape({
    accNo: yup
      .string()
      .required("Account number is required")
      .matches(/^[0-9]{9,18}$/, "Invalid Bank Account number"),
    ifscCode: yup
      .string()
      .matches(/^[A-Za-z]{4}[0-9]{7}$/, "Invalid IFSC code")
      .required("IFSC code is required"),
    state: yup.string().required("State is required"),
    city: yup.string().required("City is required"),
    upiID: yup.string().required("UPI ID is required"),
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    onSubmit: () => addKyc(),
  });

  const addKyc = async () => {
    setShowLoader(true);
    try {
      await api.post("/add-kyc", { ...values, user: userInfo._id });
      toast("KYC upadted successfully");
      goBack();
    } catch (err) {
      console.log("🚀 ~ file: index.js:56 ~ addKyc ~ err:", err);
    }
    setShowLoader(false);
  };

  const getKyc = async () => {
    setShowLoader(true);
    try {
      const [data] = await api.get("/kyc/" + userInfo._id);
      if (data) {
        delete data.createdAt;
        const keys = Object.keys(data);
        keys.forEach((item) => {
          setFieldValue(item, data[item]);
        });
      }
    } catch (err) {
      console.log("🚀 ~ file: index.js:74 ~ getKyc ~ err:", err);
    }
    setShowLoader(false);
  };

  useEffect(() => {
    getKyc();
  }, []);

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Loader show={showLoader} />
      <View style={styles.header}>
        <View style={styles.backScreen}>
          <TouchableOpacity onPress={goBack}>
            <Img source={icons.back} style={styles.back} />
          </TouchableOpacity>
          <Typography title="Complete KYC" style={styles.headerText} />
        </View>
      </View>
      {userInfo && (
        <View style={styles.userKyc}>
          <View style={styles.userProfile}>
            <Img
              source={{
                uri: userInfo.image
                  ? apiBaseURL + userInfo.image
                  : "https://i.pinimg.com/280x280_RS/79/dd/11/79dd11a9452a92a1accceec38a45e16a.jpg",
              }}
              style={{ width: scale(50), height: scale(50), borderRadius: 100 }}
            />
            <View>
              <Typography
                title={"USER " + (userInfo._id + 15553)}
                style={styles.userName}
              />
              <Typography
                title={"+91 " + userInfo.mobNo}
                style={styles.userNum}
              />
            </View>
          </View>
        </View>
      )}
      <View style={styles.bankdetails}>
        <Typography title="Add Bank Details" style={styles.backText} />
      </View>
      <View style={styles.kycBank}>
        <View style={styles.input}>
          <Input
            placeholder="Eg: 123456789"
            label="Account Number"
            onChange={handleChange("accNo")}
            onBlur={handleBlur("accNo")}
            error={touched.accNo && errors.accNo}
            value={values.accNo}
          />
        </View>
        <View style={styles.input}>
          <Input
            placeholder="Eg: SBIN0011513"
            label="IFSC Code"
            onChange={handleChange("ifscCode")}
            onBlur={handleBlur("ifscCode")}
            error={touched.ifscCode && errors.ifscCode}
            value={values.ifscCode}
          />
        </View>
        <View style={styles.input}>
          <Input
            placeholder="Eg: test@sbi"
            label="UPI ID"
            onChange={handleChange("upiID")}
            onBlur={handleBlur("upiID")}
            error={touched.upiID && errors.upiID}
            value={values.upiID}
          />
        </View>
        <View style={styles.input}>
          <Input
            placeholder="Eg: Gujarat"
            label="State"
            onChange={handleChange("state")}
            onBlur={handleBlur("state")}
            error={touched.state && errors.state}
            value={values.state}
          />
        </View>
        <View style={styles.input}>
          <Input
            placeholder="Eg: Ahmedabad"
            label="City"
            onChange={handleChange("city")}
            onBlur={handleBlur("city")}
            error={touched.city && errors.city}
            value={values.city}
          />
        </View>
        <View style={styles.input}>
          <Input
            placeholder="Eg: test@gmail.com"
            label="Email"
            onChange={handleChange("email")}
            onBlur={handleBlur("email")}
            error={touched.email && errors.email}
            value={values.email}
          />
        </View>
      </View>
      <TouchableOpacity style={styles.btn} onPress={handleSubmit}>
        <Typography title="Update KYC" style={styles.kycBtn} />
      </TouchableOpacity>
    </ScrollView>
  );
};

export default CompleteKYC;
