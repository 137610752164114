import {useEffect, useState} from 'react';
import {View, TouchableOpacity, ScrollView, FlatList} from 'react-native';

import Typography from '../../../components/typography';
import Img from '../../../components/image';
import Loader from '../../../components/loader';

import icons from '../../../contants/icons';

import styles from './styles';
import {scale} from 'react-native-size-matters';
import colors from '../../../contants/colors';

import api, {apiBaseURL} from '../../../functions/api';
import {useSelector} from 'react-redux';
import moment from 'moment';
import {useNavigation, useRoute} from '@react-navigation/native';

const WalletHistory = () => {
  const {params} = useRoute();

  const [data, setData] = useState([]);
  const [withdraws, setWithdraws] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [tabIndex, setTabIndex] = useState(params || 0);

  const user = useSelector(s => s.user.value);
  const userInfo = useSelector(s => s.userInfo.value);

  const {goBack} = useNavigation();

  const getRecharges = async () => {
    setShowLoader(true);
    try {
      const data = await api.get('/recharges/' + user);
      setData(data);
    } catch (err) {
      console.log('🚀 ~ file: index.js:22 ~ getRecharges ~ err:', err);
    }
    setShowLoader(false);
  };

  const getWithdraws = async () => {
    setShowLoader(true);
    try {
      const data = await api.get('/withdraws/' + user);
      setWithdraws(data);
    } catch (err) {
      console.log('🚀 ~ file: index.js:22 ~ getRecharges ~ err:', err);
    }
    setShowLoader(false);
  };

  useEffect(() => {
    getRecharges();
    getWithdraws();
  }, []);

  return (
    <View style={styles.container}>
      <Loader show={showLoader} />
      <View style={styles.header}>
        <View style={styles.backScreen}>
          <TouchableOpacity onPress={goBack}>
            <Img source={icons.back} style={styles.back} />
          </TouchableOpacity>
          <Typography title="Wallet History" style={styles.headerText} />
        </View>
      </View>
      <View style={styles.userWallet}>
        <View style={styles.userProfile}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Img
              source={{
                uri: userInfo.image
                  ? apiBaseURL + userInfo.image
                  : 'https://i.pinimg.com/280x280_RS/79/dd/11/79dd11a9452a92a1accceec38a45e16a.jpg',
              }}
              style={{width: scale(50), height: scale(50), borderRadius: 100}}
            />
            <Typography
              title={`+91 ${userInfo.mobNo.slice(
                0,
                2,
              )}*****${userInfo.mobNo.slice(7, 10)}`}
              style={styles.userNum}
            />
          </View>
        </View>
        <View style={styles.userKyc}>
          <Typography title="My Balance" style={styles.userBalance} />
          <View style={styles.userRupees}>
            <Img source={icons.rupees} style={styles.rupees} />
            <Typography
              title={parseFloat(userInfo.wallet).toFixed(2)}
              style={styles.rupeesText}
            />
          </View>
        </View>
        <View style={[styles.tabIndex]}>
          <TouchableOpacity
            style={[
              tabIndex === 0 && {
                ...styles.indexActive,
              },
            ]}
            onPress={() => setTabIndex(0)}>
            <Typography
              style={[
                styles.tabName,
                tabIndex === 0 && {
                  ...{color: colors.white},
                  ...{
                    backgroundColor: colors.btn,
                  },
                },
              ]}
              title="Recharge"
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              tabIndex === 1 && {
                ...styles.indexActive,
              },
            ]}
            onPress={() => setTabIndex(1)}>
            <Typography
              style={[
                styles.tabName,
                tabIndex === 1 && {
                  ...{color: colors.white},
                  ...{
                    backgroundColor: colors.btn,
                  },
                },
              ]}
              title="Withdrawal"
            />
          </TouchableOpacity>
        </View>
        <FlatList
          data={tabIndex === 0 ? data : withdraws}
          showsVerticalScrollIndicator={false}
          style={{flex: 1}}
          contentContainerStyle={styles.flatList}
          renderItem={({item, index}) => (
            <View style={styles.statusRec}>
              <View style={styles.recharge}>
                <View style={styles.rechargePro}>
                  <Img
                    source={icons.walletPlus}
                    style={{
                      ...styles.walletPlus,
                      tintColor:
                        item.status === 'PENDING'
                          ? colors.yellow
                          : item.status === 'SUCCESS'
                          ? colors.engGreen
                          : '#ED4C67',
                    }}
                  />
                  <Typography
                    title={item.amount + ' INR'}
                    style={styles.rcText}
                  />
                </View>
                <View style={{alignItems: 'center'}}>
                  <View style={styles.status}>
                    <Typography title="Status:" style={styles.stText} />
                    <Typography
                      title={item.status.replace('FAILURE', 'FAIL')}
                      style={{
                        ...styles.Process,
                        color:
                          item.status === 'PENDING'
                            ? colors.yellow
                            : item.status === 'SUCCESS'
                            ? colors.engGreen
                            : '#ED4C67',
                      }}
                    />
                  </View>
                  <Typography
                    title={
                      'Time: ' +
                      moment(item.createdAt).format('DD/MM/YY hh:mm:ss')
                    }
                    style={styles.time}
                  />
                </View>
              </View>
            </View>
          )}
        />
      </View>
    </View>
  );
};

export default WalletHistory;
