import {
  View,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
  RefreshControl,
  Dimensions,
} from "react-native";
import { useEffect, useState } from "react";

import Typography from "./typography";

import colors from "../contants/colors";

import api from "../functions/api";
import moment from "moment";
import { scale, verticalScale } from "react-native-size-matters";
import { useSelector } from "react-redux";
import Loader from "./loader";
import Placeholder from "./placeholder";

const MatchBetList = () => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(true);
  const [showLoader, setShowLoader] = useState(true);

  const user = useSelector((s) => s.user.value);

  const getData = async (_page) => {
    try {
      const res = await api.get(
        `/match-bet-list?page=${_page || page}&user=${user}&numOfItem=${10}`
      );
      _page === 1 && setPage(_page);
      _page === 1 || page === 1 ? setData(res) : setData([...data, ...res]);
      (_page === 1 || page === 1) && setIsLoading(true);
      res.length < 10 && setIsLoading(false);
    } catch (err) {
      console.log("🚀 ~ file: MatchBetList.js:16 ~ getData ~ err:", err);
    }
    setRefreshing(false);
    setShowLoader(false);
  };

  useEffect(() => {
    getData();
  }, [page]);

  return (
    <View style={styles.container}>
      {/* <Loader show={showLoader} /> */}
      {showLoader ? (
        <Placeholder />
      ) : (
        <>
          {data.length > 0 && (
            <FlatList
              data={data}
              contentContainerStyle={styles.flatList}
              showsVerticalScrollIndicator={false}
              style={{
                height: Dimensions.get("window").height - verticalScale(200),
              }}
              onEndReached={() => {
                setPage(page + 1);
              }}
              refreshControl={
                <RefreshControl
                  refreshing={refreshing}
                  onRefresh={() => {
                    setRefreshing(true);
                    getData(1);
                  }}
                  colors={[colors.btn]}
                  tintColor={colors.btn}
                />
              }
              ListFooterComponent={() =>
                isLoading ? (
                  <ActivityIndicator
                    size={50}
                    color={colors.white}
                    style={{ marginVertical: 20 }}
                  />
                ) : (
                  <></>
                )
              }
              renderItem={({ item, index }) => (
                <View style={styles.ballPocket}>
                  <View style={{ width: scale(90) }}>
                    <Typography title={item.vs} style={styles.countryName} />
                    <Typography
                      title={`Stake Rate: ${parseFloat(item.x).toFixed(3)}`}
                      style={styles.over}
                    />
                    <View style={styles.status}>
                      <Typography title="Status: " style={styles.statusWin} />
                      <Typography
                        title={
                          item.status === "FAILURE"
                            ? "FAIL"
                            : item.status === "PENDING"
                            ? "In Process"
                            : item.status
                        }
                        style={{
                          ...styles.success,
                          color:
                            item.status === "SUCCESS"
                              ? colors.engGreen
                              : item.status === "PENDING"
                              ? colors.yellow
                              : colors.red,
                        }}
                      />
                    </View>
                  </View>
                  <View style={styles.bettingBall}>
                    <Typography title="Selected" style={styles.selected} />
                    <Typography
                      title={item.selectedTeamName}
                      style={styles.four}
                    />
                  </View>
                  <View>
                    <Typography
                      title={"Stake Amount: " + item.amount}
                      style={styles.statusWin}
                    />
                    <View style={styles.status}>
                      <Typography
                        title="Winning Amount: "
                        style={styles.statusWin}
                      />
                      <Typography
                        title={
                          item.status !== "SUCCESS"
                            ? "-"
                            : (
                                parseFloat(item.amount) * parseFloat(item.x)
                              ).toFixed(2)
                        }
                        style={{
                          ...styles.success,
                          ...(item.status === "FAILURE" && {
                            color: colors.red,
                          }),
                        }}
                      />
                    </View>
                    <Typography
                      title={
                        "Time: " +
                        moment(item.createdAt).format("DD/MM/YY hh:mm:ss")
                      }
                      style={styles.statusWin}
                    />
                  </View>
                </View>
              )}
            />
          )}
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: scale(15),
  },
  ballPocket: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: colors.primary,
    paddingHorizontal: scale(15),
    justifyContent: "space-between",
    paddingVertical: verticalScale(5),
    borderRadius: scale(10),
    marginTop: verticalScale(15),
  },
  status: {
    flexDirection: "row",
    alignItems: "center",
  },
  bettingBall: {
    backgroundColor: colors.black5,
    paddingVertical: verticalScale(7),
    borderRadius: scale(10),
    alignItems: "center",
    width: scale(90),
    marginHorizontal: scale(5),
  },
  countryName: {
    color: colors.darkgrey,
    fontSize: scale(9.5),
  },
  over: {
    fontSize: scale(10),
    marginTop: verticalScale(3),
  },
  statusWin: {
    color: colors.darkgrey,
    fontSize: scale(9),
    marginTop: verticalScale(3),
  },
  success: {
    color: colors.engGreen,
    fontSize: scale(9),
    marginTop: verticalScale(3),
  },
  four: {
    fontSize: scale(14),
    color: colors.yellow,
    marginTop: verticalScale(4),
  },
  selected: {
    fontSize: scale(11),
    color: colors.darkgrey,
  },
  flatList: {
    width: "100%",
  },
});

export default MatchBetList;
