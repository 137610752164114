import { Text, StyleSheet } from "react-native";

import colors from "../contants/colors";
import fonts from "../contants/fonts";
import { scale } from "react-native-size-matters";

const Typography = ({ title, style, lines }) => {
  return (
    <Text
      allowFontScaling={false}
      style={[styles.text, style]}
      numberOfLines={lines}
    >
      {title}
    </Text>
  );
};

const styles = StyleSheet.create({
  text: {
    fontSize: scale(13),
    color: colors.white,
    fontWeight: fonts._500,
  },
});

export default Typography;
