import { StyleSheet } from "react-native";
import { Dimensions } from "react-native";

import colors from "../../../contants/colors";
import fonts from "../../../contants/fonts";
import { scale, verticalScale } from "react-native-size-matters";

const windowWidth = Dimensions.get("window").width;
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.bg,
  },

  country: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginHorizontal: scale(50),
    marginTop: scale(20),
  },
  ind: {
    fontSize: scale(15),
    fontFamily: fonts._600,
  },
  playerImg: {
    flexDirection: "row",
    marginTop: scale(15),
  },
  indPlayers: {
    paddingHorizontal: scale(13),
  },
  notPlyers: {
    paddingHorizontal: scale(5),
  },
  rohit: {
    width: scale(50),
    height: scale(50),
    backgroundColor: colors.primary,
    borderRadius: 5,
  },
  name: {
    fontSize: scale(10),
    marginTop: scale(5),
    width: scale(85),
  },

  buyRupees: {
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1,
    borderColor: colors.engGreen,
    justifyContent: "center",
    backgroundColor: colors.buy,
    marginTop: scale(2),
    paddingVertical: verticalScale(3.5),
  },
  playerBuy: {
    marginLeft: scale(8),
  },
  buy: {
    marginRight: scale(20),
    fontSize: scale(10),
  },
  rupeesImg: {
    width: scale(9),
    height: scale(9),
    marginBottom: verticalScale(2),
    opacity: 0.6,
    marginRight: scale(1),
  },
  win: {
    color: colors.engGreen,
    fontSize: scale(12),
  },
  flatList: {
    width: "100%",
    paddingBottom: 15,
    alignItems: "center",
  },
  nonPlayer: {
    flexDirection: "row",
    alignItems: "color",
    marginLeft: scale(20),
    alignItems: "center",
  },
  dot: {
    backgroundColor: colors.red,
    width: scale(6),
    height: scale(6),
    borderRadius: 50,
  },
  nonText: {
    fontSize: scale(10),
    marginLeft: scale(10),
  },
  notRupees: {
    flexDirection: "row",
    alignItems: "center",
  },
  ruCancel: {
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1,
    borderColor: colors.red,
    backgroundColor: colors.buy,
    justifyContent: "center",
    marginTop: scale(2),
    paddingHorizontal: scale(5),
  },
  cancelIcon: {
    width: scale(13),
    height: scale(13),
    marginLeft: scale(5),
  },
  notIng: {
    tintColor: colors.yellow,
    width: scale(13),
    height: scale(13),
    // marginRight: scale(5),
  },
  loss: {
    color: colors.yellow,
    fontSize: scale(10),
  },
  cancel: {
    fontSize: scale(10),
  },
});

export default styles;
