import { useState } from "react";
import {
  View,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  Dimensions,
} from "react-native";

import colors from "../contants/colors";

import Typography from "./typography";

import StakeBall from "./stakeBall";
import BuyResult from "./buyResult";
import TeamVs from "./teamVs";
import RunrateScalping from "./runrateScalping";
import { useDispatch, useSelector } from "react-redux";
import { setListTab, setMatchTab } from "../redux/slices";
import Players from "./players";
import MatchBetList from "./matchBetList";
import { scale, verticalScale } from "react-native-size-matters";
import MySessions from "./mySessions";

const LogoTabBar = () => {
  const tabIndex = useSelector((s) => s.listTab.value);

  const dispatch = useDispatch();

  const tabs = [
    { title: "Team Stake", comp: <MatchBetList /> },
    { title: "Sessions", comp: <MySessions /> },
    { title: "Ball To Ball", comp: <StakeBall /> },
    { title: "My Buy Player`s", comp: <BuyResult all /> },
    { title: "My Contest`s", comp: <TeamVs all /> },
    { title: "Runrate Scalping", comp: <RunrateScalping all /> },
  ];

  return (
    <View style={styles.container}>
      <View
        style={{
          height: verticalScale(30),
          marginVertical: verticalScale(20),
          marginTop: verticalScale(10),
        }}
      >
        <ScrollView
          contentContainerStyle={styles.tabBar}
          horizontal
          style={{ height: "100%" }}
          showsHorizontalScrollIndicator={false}
          contentOffset={{ x: tabIndex * 100 }}
        >
          {tabs.map((tab, index) => (
            <TouchableOpacity
              key={index}
              onPress={() => dispatch(setListTab(index))}
              style={[styles.tab, tabIndex === index && styles.tabActive]}
            >
              <Typography
                title={tab.title}
                style={{
                  ...styles.tabTitle,
                  ...(tabIndex === index && { color: "#fff" }),
                }}
              />
            </TouchableOpacity>
          ))}
        </ScrollView>
      </View>
      <View style={{ flex: 1, paddingBottom: scale(90) }}>
        {tabs[tabIndex] && tabs[tabIndex].comp}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    overflow: "hidden",
  },
  tabBar: {
    flexDirection: "row",
    // width: '100%',
    justifyContent: "center",
    height: verticalScale(30),
  },
  tab: {
    marginHorizontal: scale(5),
    paddingHorizontal: scale(10),
    backgroundColor: "#FFFFFF26",
    borderRadius: scale(100),
    justifyContent: "center",
    // paddingTop: verticalScale(6),
  },
  tabTitle: {
    fontSize: scale(10),
    color: "#FFFFFF99",
  },
  tabActive: {
    borderBottomWidth: scale(2),
    backgroundColor: colors.btn,
    // paddingTop: verticalScale(6),
  },
});

export default LogoTabBar;
