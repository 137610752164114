import { StyleSheet } from "react-native";
import { Dimensions } from "react-native";

import colors from "../../../contants/colors";
import fonts from "../../../contants/fonts";
import { scale, verticalScale } from "react-native-size-matters";

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.bg,
    paddingBottom: scale(90),
    width: "100%",
    height: "100vh",
  },
  wrap: {
    width: "100%",
    height: "100%",
  },
  tabBar: {
    flexDirection: "row",
    width: "100%",
    marginTop: verticalScale(10),
    justifyContent: "center",
    marginBottom: verticalScale(10),
  },
  tab: {
    marginHorizontal: scale(25),
    paddingBottom: verticalScale(5),
  },
  tabTitle: {
    fontSize: scale(12),
    color: "#FFFFFF99",
    fontWeight: fonts._600,
  },
  tabActive: {
    color: colors.white,
    borderBlockColor: "#0984E3",
    borderBottomWidth: scale(2),
  },
  logo: {
    width: scale(110),
    height: scale(30),
    marginTop: verticalScale(10),
    marginLeft: scale(25),
  },
});

export default styles;
