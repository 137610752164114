import {View} from 'react-native';
import Header from '../../../components/header';
import colors from '../../../contants/colors';
import {useEffect, useState} from 'react';
import Players from '../players';
import Contests from '../contests';
import {useRoute} from '@react-navigation/native';

const BuyP = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const params = useRoute().params;

  const {index, teamA, teamB} = params;

  return (
    <View style={{flex: 1, backgroundColor: colors.bg}}>
      <Header
        onChange={setTabIndex}
        index={index || 0}
        teamA={teamA}
        teamB={teamB}
      />
      {tabIndex == 0 ? <Players {...params} /> : <Contests {...params} />}
    </View>
  );
};

export default BuyP;
