import {
  View,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
  RefreshControl,
  Dimensions,
} from "react-native";
import { useEffect, useState } from "react";

import Typography from "./typography";

import colors from "../contants/colors";

import api from "../functions/api";
import moment from "moment";
import { scale, verticalScale } from "react-native-size-matters";
import { useSelector } from "react-redux";
import Loader from "./loader";
import Placeholder from "./placeholder";

const MySessions = () => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(true);
  const [showLoader, setShowLoader] = useState(true);

  const user = useSelector((s) => s.user.value);

  const getData = async (_page) => {
    try {
      const res = await api.get(
        `/my-sessions?page=${_page || page}&user=${user}`
      );
      res.length < 10 && setIsLoading(false);
      _page === 1 && setPage(_page);
      _page === 1 || page === 1 ? setData(res) : setData([...data, ...res]);
      (_page === 1 || page === 1) && setIsLoading(true);
    } catch (err) {
      console.log("🚀 ~ file: MySessions.js:16 ~ getData ~ err:", err);
    }
    setRefreshing(false);
    setShowLoader(false);
  };

  useEffect(() => {
    getData();
  }, [page]);

  return (
    <View style={styles.container}>
      {/* <Loader show={showLoader} /> */}
      {showLoader ? (
        <Placeholder heigh={verticalScale(90)} />
      ) : (
        <>
          {data.length > 0 && (
            <FlatList
              data={data}
              contentContainerStyle={styles.flatList}
              showsVerticalScrollIndicator={false}
              style={{
                height: Dimensions.get("window").height - verticalScale(200),
              }}
              onEndReached={() => {
                setPage(page + 1);
              }}
              refreshControl={
                <RefreshControl
                  refreshing={refreshing}
                  onRefresh={() => {
                    setRefreshing(true);
                    getData(1);
                  }}
                  colors={[colors.btn]}
                  tintColor={colors.btn}
                />
              }
              ListFooterComponent={() =>
                isLoading ? (
                  <ActivityIndicator
                    size={50}
                    color={colors.white}
                    style={{ marginVertical: verticalScale(20) }}
                  />
                ) : (
                  <></>
                )
              }
              renderItem={({ item, index }) => (
                <View style={styles.cont}>
                  <Typography
                    title={item.question}
                    style={{
                      marginTop: verticalScale(2),
                      marginBottom: verticalScale(5),
                      fontSize: scale(10),
                      letterSpacing: scale(0.5),
                    }}
                  />
                  <View style={styles.ballPocket}>
                    <View style={{ width: scale(80) }}>
                      <Typography title={item.vs} style={styles.countryName} />
                      <View style={{ flexDirection: "row" }}>
                        <Typography title={item.number} style={styles.over} />
                        <Typography
                          title={item.yesorno == 1 ? " Yes" : " No"}
                          style={{
                            fontSize: scale(10),
                            color:
                              item.yesorno == 0 ? colors.engRed : colors.btn,
                            marginTop: verticalScale(3),
                          }}
                        />
                      </View>
                      <View style={styles.status}>
                        <Typography title="Status: " style={styles.statusWin} />
                        <Typography
                          title={
                            item.status === "FAILURE"
                              ? "FAIL"
                              : item.status === "PENDING"
                              ? "In Process"
                              : item.status
                          }
                          style={{
                            ...styles.success,
                            color:
                              item.status === "SUCCESS"
                                ? colors.engGreen
                                : item.status === "PENDING"
                                ? colors.yellow
                                : colors.red,
                          }}
                        />
                      </View>
                    </View>
                    <View style={styles.bettingBall}>
                      <Typography title="Selected" style={styles.selected} />
                      <View
                        style={{
                          flexDirection: "row",
                          marginTop: verticalScale(3),
                        }}
                      >
                        <Typography
                          title={item.number}
                          style={{
                            ...styles.four,
                            color:
                              item.yesorno == 0 ? colors.engRed : colors.btn,
                          }}
                        />
                      </View>
                    </View>
                    <View>
                      <Typography
                        title={"Result: " + (item.result || "-")}
                        style={{ fontSize: scale(8) }}
                      />
                      <Typography
                        title={"Stake Amount: " + item.amount}
                        style={styles.statusWin}
                      />
                      <View style={styles.status}>
                        <Typography
                          title="Winning Amount: "
                          style={{
                            fontSize: scale(8),
                            marginTop: verticalScale(3),
                          }}
                        />
                        <Typography
                          title={
                            item.status !== "SUCCESS"
                              ? "-"
                              : (
                                  parseFloat(item.amount) *
                                  parseFloat(item.x || 2)
                                ).toFixed(2)
                          }
                          style={{
                            ...styles.success,
                            ...(item.status === "FAILURE" && {
                              color: colors.red,
                            }),
                            ...(item.status === "PENDING" && {
                              color: colors.yellow,
                            }),
                          }}
                        />
                      </View>
                      <Typography
                        title={
                          "Time: " +
                          moment(item.createdAt).format("DD/MM/YYYY hh:mm:ss")
                        }
                        style={styles.statusWin}
                      />
                    </View>
                  </View>
                </View>
              )}
            />
          )}
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: scale(15),
  },
  ballPocket: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cont: {
    backgroundColor: colors.primary,
    paddingHorizontal: scale(15),
    justifyContent: "space-between",
    paddingVertical: verticalScale(5),
    borderRadius: scale(10),
    marginTop: verticalScale(15),
  },
  status: {
    flexDirection: "row",
    alignItems: "center",
  },
  bettingBall: {
    backgroundColor: colors.black5,
    paddingVertical: verticalScale(7),
    borderRadius: scale(10),
    alignItems: "center",
    width: scale(90),
    marginHorizontal: scale(5),
  },
  countryName: {
    color: colors.darkgrey,
    fontSize: scale(10),
  },
  over: {
    fontSize: scale(10),
    marginTop: verticalScale(3),
  },
  statusWin: {
    color: colors.darkgrey,
    fontSize: scale(9),
    marginTop: verticalScale(3),
  },
  success: {
    color: colors.engGreen,
    fontSize: scale(9),
    marginTop: verticalScale(3),
  },
  four: {
    fontSize: scale(14),
    color: colors.yellow,
  },
  selected: {
    fontSize: scale(11),
    color: colors.darkgrey,
  },
  flatList: {
    width: "100%",
  },
});

export default MySessions;
