import { useEffect, useState } from "react";
// import SplashScreen from 'react-native-splash-screen';
import { NavigationContainer, useNavigation } from "@react-navigation/native";
import {
  createStackNavigator,
  TransitionPresets,
} from "@react-navigation/stack";
import { useDispatch, useSelector } from "react-redux";
import { SafeAreaView, Dimensions, Vibration, LogBox } from "react-native";
import { io } from "socket.io-client";
import { ToastContainer } from "react-toast";
import { pwaInstallHandler } from "pwa-install-handler";

import Login from "../screens/app/login";
import PinGenerate from "../screens/app/pinGenerate";
import PinLogin from "../screens/app/pinLogin";
import Bat from "../screens/app/bat";
import PlayerPerformance from "../screens/app/playerPerformance";
// import Runrate from "../screens/app/runrate";
import Players from "../screens/app/players";
// import MyProfile from "../screens/app/myProfile";
// import Contests from "../screens/app/contests";
// import Loader from "../components/loader";
import BottomTab from "./bottomTab";
// import Recharge from "../screens/app/addRecharge";
import WalletHistory from "../screens/app/walletHistory";
import ReferEarn from "../screens/app/refferEarn";
import CompleteKYC from "../screens/app/completeKYC";
import Withdraw from "../screens/app/withdraw";
import colors from "../contants/colors";
import BuyP from "../screens/app/buyP";
import api, { apiBaseURL } from "../functions/api";
import { setInfo, setUserInfo } from "../redux/slices";
import getUser from "../functions/getUser";
import Intro from "../screens/app/intro";
import PointSystem from "../screens/app/pointSystem";
// import Splash from "../components/splash";
import Splash1 from "../screens/app/splash1";
import Recharge from "../screens/app/recharge";

const Navigation = () => {
  const [socket, setSocket] = useState(null);

  const userInfo = useSelector((s) => s.userInfo);

  const dispatch = useDispatch();

  const Stack = createStackNavigator();

  const linking = {
    config: {
      screens: {
        Home: "home",
        BottomTab: "/",
        Bat: "/game",
        Login: "/login",
        PinLogin: "/pin",
        Profile: "/profile",
        WalletHistory: "/wallet-history",
        Kyc: "kyc",
        PointSystem: "/point-system",
        Withdraw: "/withdraw",
        BetList: "/stake-list",
        BuyP: "/cont",
      },
    },
  };

  LogBox.ignoreAllLogs();

  const user = useSelector((s) => s.user.value);

  const customTransitionConfig = {
    animation: "spring",
    gestureDirection: "horizontal",
    transitionSpec: {
      open: { animation: "timing", config: { duration: 300 } },
      close: { animation: "timing", config: { duration: 300 } },
    },
    cardStyleInterpolator: ({ current }) => ({
      cardStyle: {
        transform: [
          {
            translateX: current.progress.interpolate({
              inputRange: [0, 1],
              outputRange: [Dimensions.get("window").width, 0],
            }),
          },
        ],
      },
    }),
  };

  const getInfo = async () => {
    try {
      const data = await api.get("/info");
      // SplashScreen.hide();
      dispatch(setInfo(data));
    } catch (err) {
      console.log("🚀 ~ getInfo ~ err:", err);
    }
  };

  const Vibrate = () => {
    const navigation = useNavigation();

    useEffect(() => {
      Vibration.vibrate(25);
    }, [navigation]);

    return <></>;
  };

  const makeSocket = () => {
    const socket = io(apiBaseURL);
    setSocket(socket);
  };

  useEffect(() => {
    if (socket) {
      socket.on("updateWallet", (bal) => {
        if (userInfo && bal) {
          userInfo.wallet = bal;
          dispatch(setUserInfo(userInfo));
        } else {
          getUser(user, dispatch);
        }
      });
    }
  }, [socket]);

  useEffect(() => {
    if (user) {
      makeSocket();
      getUser(user, dispatch);
    }
    getInfo();
  }, [user]);

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ToastContainer />
      <NavigationContainer linking={linking}>
        <Stack.Navigator
          screenOptions={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
            ...customTransitionConfig,
            cardStyle: { backgroundColor: colors.bg },
            // gestureEnabled: true,
          }}
          initialRouteName={user ? "PinLogin" : "Intro"}
        >
          <Stack.Screen name="Intro" component={Intro} />
          <Stack.Screen name="Login" component={Login} />
          <Stack.Screen name="PinGenerate" component={PinGenerate} />
          <Stack.Screen name="PinLogin" component={PinLogin} />
          <Stack.Screen name="BottomTab" component={BottomTab} />
          <Stack.Screen name="Bat" component={Bat} />
          <Stack.Screen
            name="PlayerPerformance"
            component={PlayerPerformance}
          />
          <Stack.Screen name="WalletHistory" component={WalletHistory} />
          {/* <Stack.Screen name="Runrate" component={Runrate} /> */}
          <Stack.Screen name="Players" component={Players} />
          {/* <Stack.Screen name="MyProfile" component={MyProfile} /> */}
          {/* <Stack.Screen name="Contests" component={Contests} /> */}
          {/* <Stack.Screen name="Recharge" component={Recharge} /> */}
          <Stack.Screen name="referEarn" component={ReferEarn} />
          <Stack.Screen name="Kyc" component={CompleteKYC} />
          <Stack.Screen name="Withdraw" component={Withdraw} />
          <Stack.Screen name="BuyP" component={BuyP} />
          <Stack.Screen name="PointSystem" component={PointSystem} />
          <Stack.Screen name="Splash" component={Splash1} />
          <Stack.Screen name="Recharge" component={Recharge} />
        </Stack.Navigator>
      </NavigationContainer>
    </SafeAreaView>
  );
};

export default Navigation;
