import { useEffect, useState } from "react";
import { View, StyleSheet, TouchableOpacity, ScrollView } from "react-native";
import { scale } from "react-native-size-matters";

import Img from "./image";
import Modal from "./modal";

import colors from "../contants/colors";
import fonts from "../contants/fonts";
import icons from "../contants/icons";

const HowTo = ({ children, show, onHide }) => {
  const [isModalVisible, setModalVisible] = useState(false);

  const open = () => {
    setModalVisible(true);
  };

  const cancel = () => {
    onHide(false);
  };

  useEffect(() => {
    setModalVisible(show);
  }, [show]);

  return (
    <Modal
      isVisible={isModalVisible}
      backdropOpacity={0.8}
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View style={styles.container}>
        <View style={{ width: "100%", alignItems: "flex-end" }}>
          <TouchableOpacity
            onPress={cancel}
            style={{ marginBottom: scale(10) }}
          >
            <Img
              source={icons.cancel}
              style={{ width: scale(13), height: scale(13) }}
            />
          </TouchableOpacity>
        </View>
        <ScrollView showsVerticalScrollIndicator={false}>{children}</ScrollView>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  playTime: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginVertical: 20,
  },
  text: {
    fontSize: 10,
    fontWeight: fonts._500,
  },
  howTo: {
    fontSize: 10,
    fontWeight: fonts._500,
    color: colors.yellow,
    backgroundColor: colors.dark,
    marginLeft: 10,
    paddingHorizontal: 15,
    paddingVertical: 2,
    borderRadius: 100,
  },
  container: {
    backgroundColor: colors.primary,
    width: "100%",
    height: scale(550),
    borderRadius: scale(10),
    padding: scale(15),
  },
});

export default HowTo;
