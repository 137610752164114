import { StyleSheet } from "react-native";
import { Dimensions } from "react-native";

import colors from "../../../contants/colors";
import fonts from "../../../contants/fonts";
import { scale, verticalScale } from "react-native-size-matters";

const windowWidth = Dimensions.get("window").width;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.bg,
    paddingHorizontal: scale(20),
    width: windowWidth,
  },
  back: {
    width: scale(30),
    height: verticalScale(20),
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: verticalScale(20),
    backgroundColor: colors.bg,
    paddingLeft: scale(20),
  },
  countryName: {
    fontSize: scale(16),
    fontWeight: fonts._600,
    marginLeft: scale(20),
  },
  playerName: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: verticalScale(20),
    marginLeft: scale(20),
  },
  name: {
    fontSize: scale(12),
  },
  batBall: {
    width: scale(15),
    height: scale(15),
    marginLeft: scale(10),
  },
  playerDetails: {
    paddingHorizontal: scale(15),
  },
  playerPoints: {
    flexDirection: "row",
    backgroundColor: colors.primary,
    borderRadius: scale(10),
    marginTop: verticalScale(5),
    alignItems: "center",
    paddingHorizontal: scale(10),
  },
  rohit: {
    width: scale(60),
    height: verticalScale(65),
    // position: 'absolute',
    // marginTop: -20,
  },
  playerBuy: {
    paddingHorizontal: scale(25),
  },
  price: {
    color: colors.darkgrey,
    fontSize: scale(12),
  },
  points: {
    color: colors.yellow,
    fontSize: scale(12),
    marginTop: verticalScale(5),
  },
  player: {
    marginLeft: scale(50),
    flexDirection: "row",
    alignItems: "center",
  },
  playerEvents: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: verticalScale(20),
  },
  pointsPlayer: {
    color: colors.darkgrey,
    fontSize: scale(12),
    marginLeft: scale(100),
  },
  cricketDetails: {
    borderBottomWidth: scale(1),
    borderBottomColor: colors.line,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: verticalScale(17),
    paddingBottom: verticalScale(5),
  },
  playerScore: {
    flexDirection: "row",
    alignItems: "center",
    // backgroundColor: 'red',
  },
  score: {
    fontSize: scale(12),
    width: scale(50),
    // textAlign: 'center',
  },
  actual: {
    fontSize: scale(12),
    width: scale(50),
    marginLeft: scale(20),
    textAlign: "center",
  },
  cricketTotal: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: colors.primary,
    borderRadius: scale(100),
    justifyContent: "space-between",
    marginTop: verticalScale(20),
    paddingVertical: verticalScale(7),
    paddingHorizontal: scale(10),
    marginHorizontal: scale(7),
  },
  Runs: {
    width: scale(150),
  },
});

export default styles;
