import { View, TouchableOpacity, ScrollView } from "react-native";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigation, useRoute } from "@react-navigation/native";

import Typography from "../../../components/typography";
import Img from "../../../components/image";
import OTPInputView from "../../../components/otpInput";

import styles from "./styles";

import colors from "../../../contants/colors";
import images from "../../../contants/images";
import icons from "../../../contants/icons";

import api from "../../../functions/api";
import toast from "../../../functions/toast";

import { setUser } from "../../../redux/slices";
import { scale, verticalScale } from "react-native-size-matters";

const PinGenerate = () => {
  const [pin, setPin] = useState(null);
  const [cPin, setCPin] = useState(null);

  const dispatch = useDispatch();

  const { params } = useRoute();

  const { replace } = useNavigation();

  const savePin = async () => {
    if (!pin || !pin.match(/^\d{4}$/) || !cPin || !cPin.match(/^\d{4}$/))
      return toast("Enter valid PIN");

    if (pin !== cPin) return toast("PIN & Confirm PIN do not match");

    try {
      const data = await api.post("/set-pin", { pin, _id: params._id });
      dispatch(setUser(data._id));
      replace("PinLogin");
      return toast("Login PIN saved successfully");
    } catch (err) {
      console.log("🚀 ~ file: index.js:27 ~ savePin ~ err:", err);
    }
  };

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Img source={icons.logo} style={styles.logo} />

      <Img source={images.cricketImg} style={styles.bgImg} />
      <View style={{ marginTop: verticalScale(30) }}>
        <Typography title="Enter Four Digit Login PIN" style={styles.otpText} />
        <OTPInputView
          selectionColor={colors.white}
          style={{
            width: scale(240),
            height: verticalScale(50),
            justifyContent: "center",
            alignItems: "center",
          }}
          pinCount={4}
          autoFocusOnLoad={false}
          codeInputFieldStyle={{
            width: scale(50),
            height: scale(50),
            borderRadius: scale(10),
            marginHorizontal: scale(5),
            borderWidth: 0,
            backgroundColor: colors.primary,
            fontSize: scale(24),
            textAlign: "center",
            color: colors.white,
          }}
          codeInputHighlightStyle={{
            borderColor: colors.white,
            borderWidth: scale(1),
          }}
          onCodeChanged={(code) => setPin(code.replace(/\D/g, ""))}
          code={pin || ""}
          secureTextEntry
        />
        <Typography
          title="Confirm Four Digit Login PIN"
          style={styles.otpText}
        />
        <OTPInputView
          selectionColor={colors.white}
          style={{
            width: scale(240),
            height: scale(50),
            justifyContent: "center",
            alignItems: "center",
          }}
          pinCount={4}
          autoFocusOnLoad={false}
          codeInputFieldStyle={{
            width: scale(50),
            height: scale(50),
            borderRadius: scale(10),
            marginHorizontal: scale(5),
            borderWidth: 0,
            backgroundColor: colors.primary,
            fontSize: scale(24),
            textAlign: "center",
            color: colors.white,
          }}
          codeInputHighlightStyle={{
            borderColor: colors.white,
            borderWidth: scale(1),
          }}
          onCodeChanged={(code) => setCPin(code.replace(/\D/g, ""))}
          code={cPin || ""}
          secureTextEntry
        />
      </View>

      <TouchableOpacity style={styles.btn} onPress={savePin}>
        <Typography title="Let’s Start" style={styles.btnText} />
      </TouchableOpacity>
    </ScrollView>
  );
};

export default PinGenerate;
