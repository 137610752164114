import { toast as Toast } from "react-toast";

const toast = (msg) => {
  const toast = document.createElement("div");
  toast.classList.add("tst");
  toast.innerText = msg;
  document.body.append(toast);

  setTimeout(() => {
    document.body.removeChild(toast);
  }, 2000);
};

export default toast;
