import { useEffect, useState } from "react";
import { View, TouchableOpacity } from "react-native";
import { TabView, SceneMap } from "react-native-tab-view";

import Typography from "../../../components/typography";
import Match from "../../../components/match";

import styles from "./styles";

import icons from "../../../contants/icons";
import Img from "../../../components/image";

import api from "../../../functions/api";
// import Loader from "../../../components/loader";

const Home = () => {
  const [index, setIndex] = useState(0);
  const [upcomingMatches, setUpcomingMatches] = useState([]);
  const [liveMatches, setLiveMatches] = useState([]);

  const routes = [
    {
      key: "second",
      title:
        upcomingMatches.length === 0
          ? "Upcoming"
          : `Upcoming (${upcomingMatches.length})`,
    },
    {
      key: "first",
      title: liveMatches.length === 0 ? "Live" : `Live (${liveMatches.length})`,
    },
    { key: "third", title: "Completed" },
  ];

  const renderScene = SceneMap({
    first: () => <Match status="live" />,
    second: () => <Match status="upcoming" />,
    third: () => <Match status="completed" />,
  });

  const CustomTabBar = (props) => {
    const tabs = props.navigationState.routes,
      activeTab = index,
      onTabPress = (tabIndex) => setIndex(tabIndex);
    return (
      <View style={styles.tabBar}>
        {tabs.map((tab, index) => (
          <TouchableOpacity
            key={index}
            onPress={() => onTabPress(index)}
            style={[styles.tab, activeTab === index && styles.tabActive]}
          >
            <Typography
              title={tab.title}
              style={{
                ...styles.tabTitle,
                ...(activeTab === index && { color: "#fff" }),
              }}
            />
          </TouchableOpacity>
        ))}
      </View>
    );
  };

  // const getMatches = async () => {
  //   try {
  //     const data = await api.get('/matches', true);
  //     setUpcomingMatches(data.filter(i => i.status === 'Upcoming'));
  //     setLiveMatches(data.filter(i => i.status === 'Live'));
  //     setCompletedMatches(data.filter(i => i.status === 'Completed'));
  //   } catch (err) {
  //     console.log('🚀 ~ file: index.js:55 ~ getMatches ~ err:', err);
  //   }
  //   setShowLoader(false);
  // };

  // useEffect(() => {
  //   getMatches();
  // }, []);

  return (
    <View style={styles.container}>
      <View style={styles.wrap}>
        {/* <Loader show={showLoader} /> */}
        <Img source={icons.logo} style={styles.logo} />
        <TabView
          navigationState={{ index, routes }}
          renderScene={renderScene}
          onIndexChange={setIndex}
          renderTabBar={(props) => <CustomTabBar {...props} />}
          lazy
        />
      </View>
    </View>
  );
};

export default Home;
