const gifs = {
  success: require('../assets/gifs/success.gif'),
  suc: require('../assets/gifs/suc.gif'),
  live: require('../assets/gifs/live.gif'),
  dottBall: require('../assets/gifs/0.gif'),
  one: require('../assets/gifs/1.gif'),
  two: require('../assets/gifs/2.gif'),
  three: require('../assets/gifs/3.gif'),
  fourRun: require('../assets/gifs/4.gif'),
  four: require('../assets/gifs/four.gif'),
  six: require('../assets/gifs/six.gif'),
  wait: require('../assets/gifs/wait.gif'),
  wd: require('../assets/gifs/wd.gif'),
  wicket: require('../assets/gifs/wicket.gif'),
  nb: require('../assets/gifs/nb.gif'),
  startStake: require('../assets/gifs/start-stake.gif'),
  stopStake: require('../assets/gifs/stop-stake.gif'),
};

export default gifs;
