import ReactModal from "react-modal";

const Modal = ({
  isVisible,
  backdropOpacity = 0.5,
  children,
  onBackdropPress = () => {},
  style,
}) => {
  // return isVisible ? (
  //   <>
  //     <div
  //       className="model-cover"
  //       style={{ backgroundColor: `rgba(0,0,0,${backdropOpacity})` }}
  //       onClick={onBackdropPress}
  //     />
  //     <div className="model-content" style={style}>
  //       {children}
  //     </div>
  //   </>
  // ) : (
  //   <></>
  // );
  return (
    <ReactModal
      isOpen={isVisible}
      onRequestClose={onBackdropPress}
      style={{ backgroundColor: `rgba(0,0,0,${backdropOpacity})` }}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
