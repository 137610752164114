import React, { useEffect, useState } from "react";
import { ScrollView, TouchableOpacity, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useSelector } from "react-redux";
import ReactNativeModal from "../../../components/modal";
import OTPInputView from "../../../components/otpInput";

import icons from "../../../contants/icons";

import Typography from "../../../components/typography";
import Input from "../../../components/input";
import Img from "../../../components/image";
import Loader from "../../../components/loader";

import toast from "../../../functions/toast";
import api from "../../../functions/api";

import styles from "./styles";
import colors from "../../../contants/colors";
import gifs from "../../../contants/gifs";
import { scale } from "react-native-size-matters";

//   {"Status": "SUCCESS", "responseCode": "0", "status": undefined, "txnId": "ICI7af830ea961846ddbdd2ffbb0295324a", "txnRef": "0.3948493142021714"}

const Withdraw = () => {
  const [amount, setAmount] = useState("100");
  const [showLoader, setShowLoader] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [sm, setSm] = useState(false);

  const { goBack, navigate } = useNavigation();

  const user = useSelector((s) => s.user.value);
  const userInfo = useSelector((s) => s.userInfo.value);

  const prices = [1000, 5000, 10000, 15000, 20000, 25000, 30000, 50000, 100000];

  const withraw = async (pin) => {
    setShowLoader(true);
    setShowModel(false);
    try {
      await api.post("/withdraw", { user, amount, pin, agent: userInfo.agent });
      toast("Withdrawal Request submitted successfully");
      setSm(true);
    } catch (err) {
      console.log("🚀 ~ file: index.js:42 ~ withraw ~ err:", err);
    }
    setShowLoader(false);
  };

  useEffect(() => {
    if (sm) {
      setTimeout(() => {
        setShowModel(false);
        setSm(false);
        navigate("WalletHistory", 1);
      }, 2000);
    }
  }, [sm]);

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Loader show={showLoader} />

      <View style={styles.header}>
        <TouchableOpacity onPress={goBack}>
          <Img source={icons.back} style={styles.back} />
        </TouchableOpacity>
        <Typography title="Withdraw" style={styles.title} />
      </View>
      {userInfo && (
        <View style={styles.wallet}>
          <Typography title="Wallet Balance" />
          <Typography
            title={parseFloat(userInfo.wallet).toFixed(2) + " INR"}
            style={styles.amount}
          />
        </View>
      )}
      <Input
        placeholder="Eg: 10000"
        label="Enter Withdrawal Amount"
        keyboardType="number-pad"
        value={amount && amount.toString()}
        onChange={(val) => {
          if (val === "" || val.match(/^[1-9]\d*$/)) setAmount(val);
        }}
        max={10}
        style={styles.input}
      />
      <View style={styles.prices}>
        {prices.map((item, index) => (
          <TouchableOpacity
            key={index}
            style={[styles.price, amount == item && styles.activePrice]}
            onPress={() => setAmount(item)}
            disabled={amount == item}
          >
            <Typography title={item} />
          </TouchableOpacity>
        ))}
      </View>
      {amount && amount > 0 && (
        <TouchableOpacity
          style={styles.btn}
          onPress={() => {
            if (amount < 1000) return toast("Enter minimum amount 1000");
            setShowModel(true);
          }}
        >
          <Typography
            title={"Withdraw " + amount + " INR"}
            style={styles.btnText}
          />
        </TouchableOpacity>
      )}
      <ReactNativeModal
        isVisible={showModel}
        backdropOpacity={0.7}
        onBackButtonPress={() => setShowModel(false)}
        onBackdropPress={() => setShowModel(false)}
      >
        <View style={styles.succ}>
          <Typography
            title="Enter Four Digit PIN to Withdraw"
            style={styles.otpText}
          />

          <OTPInputView
            style={{
              width: scale(240),
              height: scale(50),
              justifyContent: "center",
              alignItems: "center",
            }}
            pinCount={4}
            selectionColor={colors.white}
            autoFocusOnLoad={false}
            codeInputFieldStyle={{
              width: scale(50),
              height: scale(50),
              borderRadius: scale(10),
              marginHorizontal: scale(5),
              borderWidth: 0,
              backgroundColor: colors.primary,
              fontSize: scale(24),
              textAlign: "center",
              color: colors.white,
            }}
            codeInputHighlightStyle={{
              borderColor: colors.white,
              borderWidth: scale(1),
            }}
            onCodeFilled={withraw}
            secureTextEntry
          />
        </View>
      </ReactNativeModal>
      <ReactNativeModal isVisible={sm} backdropOpacity={0.7}>
        <View style={styles.success}>
          <Img source={gifs.suc} style={styles.successImg} />
        </View>
      </ReactNativeModal>
    </ScrollView>
  );
};

export default Withdraw;
