import React, { useEffect, useState } from "react";
import { View, StyleSheet, FlatList, ScrollView } from "react-native";
import colors from "../contants/colors";

import Typography from "./typography";

import Img from "./image";
import { scale, verticalScale } from "react-native-size-matters";
import Batting from "./batting";

import api, { scoreBaseURL } from "../functions/api";
import Loader from "./loader";
import Placeholder from "./placeholder";

const BattingInd = ({ matchData }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [data, setData] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [team, setTeam] = useState("teamA");

  const getData = async () => {
    try {
      const res = await api.get(`/scorecard/${matchData._id}`, true);
      setData(res);
    } catch (err) {
      console.log("🚀 ~ getData ~ err:", err);
    }
    setShowLoader(false);
  };

  useEffect(() => {
    if (matchData) {
      getData();
    }
  }, [matchData]);

  useEffect(() => {
    setTeam(tabIndex === 0 ? "teamA" : "teamB");
  }, [tabIndex]);

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{ height: verticalScale(400) }}
    >
      {showLoader ? (
        <Placeholder heigh={verticalScale(50)} padding={scale(15)} />
      ) : (
        <>
          {data && matchData && (
            <>
              <Batting
                onChange={setTabIndex}
                value={tabIndex}
                teamA={matchData.teamAShortName}
                teamB={matchData.teamBShortName}
              />
              {data[team] ? (
                <View style={styles.container}>
                  <View style={styles.allPlayers}>
                    <Typography title="Batting" />
                    <View
                      style={[styles.plyaerBuy, { marginRight: scale(10) }]}
                    >
                      <Typography
                        title="R"
                        style={{
                          ...styles.points,
                          width: scale(30),
                        }}
                      />
                      <Typography
                        title="B"
                        style={{
                          ...styles.price,
                          width: scale(30),
                        }}
                      />
                      <Typography
                        title="4s"
                        style={{ ...styles.points, width: scale(30) }}
                      />
                      <Typography
                        title="6s"
                        style={{ ...styles.price, width: scale(30) }}
                      />
                      <Typography
                        title="S/R"
                        style={{ ...styles.points, width: scale(35) }}
                      />
                    </View>
                  </View>
                  <FlatList
                    data={data[team].batsmans}
                    contentContainerStyle={styles.flatList}
                    showsVerticalScrollIndicator={false}
                    renderItem={({ item, index }) => (
                      <View
                        style={[
                          styles.playerName,
                          { opacity: item.wicket === 1 ? 0.5 : 1 },
                        ]}
                      >
                        <View style={styles.playerImg}>
                          <Img
                            source={{ uri: scoreBaseURL + item.info.image }}
                            style={styles.rohit}
                          />
                          <View style={{ marginLeft: scale(5) }}>
                            <Typography
                              title={item.info.shortName}
                              style={styles.name}
                            />
                            <Typography
                              title={item.note}
                              style={styles.indian}
                              lines={1}
                            />
                          </View>
                        </View>
                        <View style={styles.numbers}>
                          <Typography
                            title={item.events.runs}
                            style={{
                              ...styles.points,
                              width: scale(30),
                            }}
                          />
                          <Typography
                            title={item.events.balls}
                            style={{ ...styles.ball, width: scale(30) }}
                          />
                          <Typography
                            title={item.events.four}
                            style={{ ...styles.points, width: scale(30) }}
                          />
                          <Typography
                            title={item.events.six}
                            style={{ ...styles.ball, width: scale(30) }}
                          />
                          <Typography
                            title={item.events.strikeRate}
                            style={{ ...styles.points, width: scale(45) }}
                          />
                        </View>
                      </View>
                    )}
                  />
                  <View>
                    <View style={styles.extras}>
                      <Typography title="Extras" />
                      <View style={styles.wdLb}>
                        <Typography
                          title={data[team].extra}
                          style={styles.price}
                        />
                        <Typography
                          title=" (WD, NB, LB, BY)"
                          style={styles.points}
                        />
                      </View>
                    </View>
                    <View style={styles.extras}>
                      <Typography title="Total runs" />
                      <View style={styles.wdLb}>
                        <Typography
                          title={matchData.score.teams[team].run}
                          style={styles.price}
                        />
                        <Typography
                          title={` (${matchData.score.teams[team].wickets} W, ${matchData.score.teams[team].over} ov)`}
                          style={styles.points}
                        />
                      </View>
                    </View>
                  </View>
                  <View style={styles.allPlayers}>
                    <Typography title="Bowling" />
                    <View style={styles.plyaerBuy}>
                      <Typography
                        title="O"
                        style={{ ...styles.points, width: scale(30) }}
                      />
                      <Typography
                        title="M"
                        style={{ ...styles.price, width: scale(30) }}
                      />
                      <Typography
                        title="R"
                        style={{ ...styles.points, width: scale(30) }}
                      />
                      <Typography
                        title="W"
                        style={{ ...styles.price, width: scale(30) }}
                      />
                      <Typography
                        title="Econ"
                        style={{ ...styles.points, width: scale(45) }}
                      />
                    </View>
                  </View>
                  <FlatList
                    data={data[team].bowlers}
                    contentContainerStyle={styles.flatList}
                    renderItem={({ item, index }) => (
                      <>
                        {item && item.info && item.events && (
                          <View style={styles.playerName}>
                            <View style={styles.playerImg}>
                              <Img
                                source={{ uri: scoreBaseURL + item.info.image }}
                                style={styles.rohit}
                              />
                              <View style={{ marginLeft: scale(5) }}>
                                <Typography
                                  title={item.info.shortName}
                                  style={styles.name}
                                />
                              </View>
                            </View>
                            <View style={styles.numbers}>
                              <Typography
                                title={item.events.over.toFixed(1)}
                                style={{ ...styles.points, width: scale(30) }}
                              />
                              <Typography
                                title={item.events.maidenOver}
                                style={{ ...styles.ball, width: scale(30) }}
                              />
                              <Typography
                                title={item.events.runs}
                                style={{ ...styles.points, width: scale(30) }}
                              />
                              <Typography
                                title={item.events.wicket}
                                style={{ ...styles.ball, width: scale(30) }}
                              />
                              <Typography
                                title={item.events.econ}
                                style={{ ...styles.points, width: scale(45) }}
                              />
                            </View>
                          </View>
                        )}
                      </>
                    )}
                  />
                </View>
              ) : (
                <View
                  style={{
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: verticalScale(20),
                  }}
                >
                  <Typography
                    title="Yet to Bet"
                    style={{ color: colors.yellow }}
                  />
                </View>
              )}
            </>
          )}
        </>
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: scale(15),
    marginTop: verticalScale(10),
  },
  allPlayers: {
    marginTop: scale(10),
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomColor: colors.white,
    borderBottomWidth: 1,
  },
  price: {
    fontSize: scale(11),
  },
  points: {
    fontSize: scale(11),
  },
  playerName: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: scale(5),
    marginTop: scale(10),
  },
  playerImg: {
    flexDirection: "row",
  },
  rohit: {
    width: scale(40),
    height: scale(40),
    backgroundColor: colors.primary,
    borderRadius: scale(5),
  },
  numbers: {
    flexDirection: "row",
    marginTop: scale(6),
  },
  name: {
    fontSize: scale(10),
    marginTop: scale(5),
  },
  indian: {
    fontSize: scale(9),
    color: colors.yellow,
    width: scale(90),
  },
  priceNum: {
    marginHorizontal: scale(40),
    fontSize: scale(10),
  },
  plyaerBuy: {
    flexDirection: "row",
    alignItems: "center",
  },
  flatList: {
    width: "100%",
    marginBottom: scale(10),
  },
  ball: {
    fontSize: scale(11),
  },
  extras: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: scale(5),
  },
  wdLb: {
    alignItems: "center",
    flexDirection: "row",
  },
});

export default BattingInd;
