import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";

const OTPInputView = ({
  pinCount = 4,
  codeInputFieldStyle,
  editable,
  onCodeFilled = () => {},
  onCodeChanged = () => {},
  code,
  secureTextEntry,
}) => {
  const [otp, setOtp] = useState("");

  useEffect(() => {
    if (otp.split("").length === 4) onCodeFilled(otp);
  }, [otp]);

  return (
    <OtpInput
      value={code || otp}
      onChange={(e) => {
        setOtp(e);
        onCodeChanged(e);
      }}
      numInputs={pinCount}
      renderSeparator={<span>-</span>}
      renderInput={(props) => (
        <input
          {...props}
          style={codeInputFieldStyle}
          type={secureTextEntry ? "password" : "text"}
          disabled={editable === false}
          className="opt-input"
          inputmode="numeric"
        />
      )}
    />
  );
};

export default OTPInputView;
