import { StyleSheet } from "react-native";
import { scale, verticalScale } from "react-native-size-matters";

import colors from "../../../contants/colors";
import fonts from "../../../contants/fonts";

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#01416b",
    width: "100%",
    minHeight: "100%",
    paddingBottom: scale(60),
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: scale(10),
    paddingHorizontal: scale(15),
  },
  backScreen: {
    flexDirection: "row",
    alignItems: "center",
  },
  back: {
    width: scale(25),
    height: scale(18),
  },
  headerText: {
    fontSize: scale(17),
    marginLeft: scale(15),
  },
  notification: {
    width: scale(23),
    height: scale(23),
  },
  userProfile: {
    backgroundColor: colors.primary,
    borderTopLeftRadius: scale(30),
    minHeight: "100%",
    borderTopRightRadius: scale(30),
    marginTop: verticalScale(60),
    paddingHorizontal: scale(20),
  },
  userPhoto: {
    width: scale(100),
    height: scale(100),
    backgroundColor: colors.white,
    borderRadius: scale(100),
    marginTop: scale(-50),
  },
  cameraIcon: {
    width: scale(18),
    height: scale(18),
  },
  camera: {
    backgroundColor: colors.bg,
    position: "absolute",
    padding: scale(5),
    borderRadius: scale(100),
    marginTop: scale(70),
    marginLeft: scale(70),
  },
  userName: {
    fontSize: scale(18),
    fontWeight: fonts._600,
    marginTop: scale(15),
    textAlign: "center",
  },
  userNum: {
    fontSize: scale(10),
    textAlign: "center",
    marginTop: verticalScale(2),
  },
  levels: {
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: scale(10),
  },
  user: {
    alignItems: "center",
    justifyContent: "center",
  },
  userLevel: {
    fontSize: scale(11),
    fontWeight: fonts._600,
  },
  border: {
    width: "100%",
    backgroundColor: colors.white,
    height: scale(5),
    borderRadius: 50,
    marginTop: scale(8),
  },
  twoBorder: {
    width: scale(150),
    backgroundColor: colors.btn,
    height: scale(5),
    borderRadius: 50,
  },
  playText: {
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    marginTop: scale(5),
  },
  text: {
    fontSize: scale(8),
    color: colors.yellow,
    fontWeight: fonts._600,
  },
  rupees: {
    width: scale(13),
    height: scale(13),
  },
  rupeesText: {
    backgroundColor: colors.engGreen,
    paddingHorizontal: scale(18),
    borderRadius: scale(30),
  },
  amount: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: colors.engGreen,
    paddingHorizontal: scale(7),
    paddingVertical: verticalScale(5),
  },
  rupeesIcon: {
    tintColor: colors.white,
    width: scale(12),
    height: scale(12),
  },
  amountText: {
    fontSize: scale(12),
    fontWeight: fonts._600,
    marginLeft: scale(5),
  },
  addRupees: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: scale(20),
  },
  withdrawal: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: colors.yellow,
    paddingHorizontal: scale(7),
    paddingVertical: verticalScale(5),
  },
  userKyc: {
    backgroundColor: colors.bg,
    borderWidth: 1,
    borderColor: colors.yellow,
    borderRadius: scale(10),
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: scale(20),
    marginTop: scale(20),
    paddingVertical: scale(17),
  },
  kycCom: {
    flexDirection: "row",
    alignItems: "center",
  },
  kycText: {
    fontSize: scale(16),
    fontWeight: fonts._700,
    marginLeft: scale(15),
    marginBottom: scale(3),
  },
  addBank: {
    fontSize: scale(10),
    fontWeight: fonts._400,
    marginLeft: scale(15),
  },
  kycIcon: {
    width: scale(35),
    height: scale(35),
  },
  rightIcon: {
    width: scale(9),
    height: scale(15),
  },
  allSetting: {
    backgroundColor: colors.bg,
    borderRadius: scale(10),
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: scale(20),
    paddingVertical: scale(15),
    alignItems: "center",
    marginTop: scale(5),
  },
  settingText: {
    fontSize: scale(13),
  },
  right: {
    tintColor: colors.white,
    width: scale(9),
    height: scale(12),
  },
  setting: {
    marginTop: scale(10),
  },
  LogOut: {
    backgroundColor: colors.btn,
    borderRadius: scale(10),
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: scale(20),
    paddingVertical: scale(15),
    alignItems: "center",
    marginTop: scale(18),
    marginBottom: scale(30),
  },
  logout: {
    width: scale(18),
    height: scale(18),
  },
  userImg: {
    width: scale(100),
    height: scale(100),
    borderRadius: scale(100),
  },
});

export default styles;
