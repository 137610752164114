import {setUserInfo} from '../redux/slices';
import api from './api';

const getUser = async (user, dis) => {
  const [data] = await api.get('/user/' + user);
  dis(setUserInfo(data));
  return;
};

export default getUser;
