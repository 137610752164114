import { StyleSheet } from "react-native";
import { Dimensions } from "react-native";

import colors from "../../../contants/colors";
import fonts from "../../../contants/fonts";
import { scale } from "react-native-size-matters";

const windowWidth = Dimensions.get("window").width;
const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.bg,
    paddingHorizontal: scale(15),
    width: "100%",
    minHeight: "100%",
    paddingBottom: scale(50),
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: scale(10),
  },
  backScreen: {
    flexDirection: "row",
    alignItems: "center",
  },
  back: {
    width: scale(25),
    height: scale(18),
  },
  headerText: {
    fontSize: scale(17),
    marginLeft: scale(15),
  },
  userPhoto: {
    backgroundColor: colors.yellow,
    fontSize: scale(18),
    fontWeight: fonts._700,
    borderRadius: scale(200),
    color: colors.black,
    paddingVertical: scale(10),
    paddingHorizontal: scale(15),
  },
  userProfile: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: scale(15),
    paddingHorizontal: scale(10),
    marginBottom: scale(20),
  },
  userName: {
    fontSize: scale(18),
    marginLeft: scale(20),
    fontWeight: fonts._700,
  },
  userNum: {
    fontSize: scale(11),
    marginLeft: scale(20),
  },
  userKyc: {
    borderBottomColor: colors.btn,
    borderBottomWidth: 1,
    borderStyle: "dashed",
  },
  bankdetails: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: scale(20),
  },
  back: {
    width: scale(24),
    height: scale(24),
  },
  backText: {
    fontSize: scale(14),
  },
  input: {
    marginTop: scale(22),
  },
  btn: {
    alignItems: "center",
    marginTop: scale(25),
  },
  kycBtn: {
    backgroundColor: colors.btn,
    paddingVertical: scale(8),
    paddingHorizontal: scale(40),
    borderRadius: scale(10),
  },
});

export default styles;
