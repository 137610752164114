import React, { useEffect, useState } from "react";

import { View, TouchableOpacity, StyleSheet } from "react-native";

import colors from "../contants/colors";
import Typography from "./typography";
import fonts from "../contants/fonts";

import { scale, verticalScale } from "react-native-size-matters";

const Batting = ({ onChange, value, teamA, teamB }) => {
  const [tabIndex, setTabIndex] = useState(value);

  useEffect(() => {
    onChange(tabIndex);
  }, [tabIndex]);

  useEffect(() => {
    setTabIndex(value || 0);
  }, [value]);

  return (
    <View style={styles.container}>
      <View style={[styles.tabIndex]}>
        <TouchableOpacity
          style={[
            styles.index,
            tabIndex === 0 && {
              ...styles.indexActive,
            },
          ]}
          onPress={() => setTabIndex(0)}
        >
          <Typography
            style={[
              styles.tabName,
              tabIndex === 0 && {
                ...{ color: colors.white },
              },
            ]}
            title={teamA}
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            styles.index,
            tabIndex === 1 && {
              ...styles.indexActive,
            },
          ]}
          onPress={() => setTabIndex(1)}
        >
          <Typography
            style={[
              styles.tabName,
              tabIndex === 1 && {
                ...{ color: colors.white },
              },
            ]}
            title={teamB}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: scale(20),
  },
  tabBar: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: 30,
    paddingHorizontal: scale(30),
  },

  tabIndex: {
    flexDirection: "row",
    justifyContent: "center",
    justifyContent: "space-between",
    backgroundColor: colors.primary,
    paddingHorizontal: scale(40),
    paddingVertical: verticalScale(8),
    borderRadius: 20,
  },

  indexActive: {
    borderBottomColor: colors.btn,
    borderBottomWidth: 2,
  },
  tabName: {
    fontSize: scale(13),
    fontWeight: fonts._700,
    color: colors.darkgrey,
  },
});

export default Batting;
