import { useEffect, useState } from "react";
import { View, StyleSheet, FlatList, TouchableOpacity } from "react-native";

import Typography from "./typography";

import Img from "./image";

import colors from "../contants/colors";
import images from "../contants/images";

import { scale, verticalScale } from "react-native-size-matters";
import icons from "../contants/icons";
import fonts from "../contants/fonts";
import { useSelector } from "react-redux";
import { useNavigation } from "@react-navigation/native";
import HowTo from "./howTo";

const Header = ({ onChange = () => {}, index, teamA, teamB }) => {
  const [tabIndex, setTabIndex] = useState(index);
  const [sm, setSm] = useState(false);

  const { goBack, navigate } = useNavigation();

  const userInfo = useSelector((s) => s.userInfo.value);

  useEffect(() => {
    onChange(tabIndex);
  }, [tabIndex]);

  return (
    <>
      <HowTo show={sm} onHide={setSm}>
        {tabIndex === 1 ? (
          <>
            <View>
              {/* <Img
                source={images.ptsPoster}
                style={{
                  width: '100%',
                  height: scale(130),
                  backgroundColor: colors.bg,
                }}
              /> */}
            </View>
            <View>
              <Typography
                title="4. Head-to-Head One vs. One Player Contests: Direct Duels for Glory"
                style={{
                  fontSize: scale(13),
                  marginTop: scale(15),
                  fontFamily: fonts._700,
                }}
              />
              <Typography
                title="For those seeking a more personalized and intense experience, CrickStox offers head-to-head contests where users can go one-on-one against each other. This format adds a competitive edge to fantasy cricket, as users battle it out in a direct duel to prove their cricketing expertise. It's not just about scoring points; it's about outsmarting and outplaying your opponent."
                style={{ marginTop: scale(5) }}
              />

              <Typography
                title="The head-to-head contests on CrickStox create a dynamic and thrilling environment, where every decision matters. From player selection to strategic bets, users must be at the top of their game to emerge victorious. Whether you're competing against friends or challenging random opponents, the head-to-head contests add a social and competitive element to the fantasy cricket experience."
                style={{ marginTop: scale(10) }}
              />
            </View>
          </>
        ) : (
          <>
            <View>
              {/* <Img
                source={images.ptsPoster}
                style={{
                  width: '100%',
                  height: scale(130),
                  backgroundColor: colors.bg,
                }}
              /> */}
            </View>
            <View>
              <Typography
                title="Buy Players: Your Team, Your Choices"
                style={{
                  fontSize: scale(13),
                  marginTop: scale(15),
                  fontFamily: fonts._700,
                }}
              />
              <Typography
                title="CrickStox takes the fantasy cricket experience to the next level with its player auction feature. Instead of being restricted to a fixed budget, users have the opportunity to bid for their favorite players in a dynamic auction environment. This adds an element of strategy and competition, as users must carefully manage their budget to assemble the best possible team."
                style={{ marginTop: scale(5) }}
              />

              <Typography
                title="The buy players feature brings a sense of ownership to fantasy cricket, allowing users to create a team that reflects their cricketing insights and instincts. It's not just about selecting star players; it's about making shrewd choices, finding undervalued gems, and building a team that can outperform the competition. CrickStox's buy players feature turns fantasy cricket into a true test of managerial skills."
                style={{ marginTop: scale(10) }}
              />
            </View>
          </>
        )}
      </HowTo>
      <View style={styles.header}>
        <View style={styles.countryPlayer}>
          <TouchableOpacity onPress={goBack}>
            <Img source={icons.back} style={styles.back} />
          </TouchableOpacity>
          <Typography
            title={`${teamA || "IND"} vs ${teamB || "NZ"}`}
            style={styles.countryName}
          />
        </View>
        {userInfo && (
          <TouchableOpacity
            style={styles.pocketRupees}
            onPress={() => navigate("Recharge")}
          >
            <Img source={icons.pocket} style={styles.pocket} />
            <Typography
              title={parseFloat(userInfo.wallet).toFixed(2)}
              style={styles.rupees}
            />
            <Img source={icons.add} style={styles.add} />
          </TouchableOpacity>
        )}
      </View>
      <TouchableOpacity style={styles.playTime} onPress={() => setSm(true)}>
        <Typography title="How To Play?" style={styles.howTo} />
      </TouchableOpacity>
      <View style={[styles.tabIndex]}>
        <TouchableOpacity
          style={[
            styles.index,
            tabIndex === 0 && {
              ...styles.indexActive,
            },
          ]}
          onPress={() => setTabIndex(0)}
        >
          <Typography
            style={[
              styles.tabName,
              tabIndex === 0 && {
                ...{ color: colors.white },
              },
            ]}
            title="Buy Player’s"
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            styles.index,
            tabIndex === 1 && {
              ...styles.indexActive,
            },
          ]}
          onPress={() => setTabIndex(1)}
        >
          <Typography
            style={[
              styles.tabName,
              tabIndex === 1 && {
                ...{ color: colors.white },
              },
            ]}
            title="Play Contest"
          />
        </TouchableOpacity>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  howTo: {
    fontSize: scale(9),
    fontFamily: fonts._500,
    color: colors.yellow,
    backgroundColor: colors.dark,
    marginLeft: scale(10),
    textAlign: "center",
    paddingVertical: 2,
    borderRadius: 100,
  },
  back: {
    width: scale(22),
    height: scale(18),
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: scale(15),
    paddingHorizontal: scale(10),
    backgroundColor: colors.bg,
  },
  countryName: {
    fontSize: scale(16),
    fontFamily: fonts._600,
    marginLeft: scale(20),
  },
  countryPlayer: {
    flexDirection: "row",
    alignItems: "center",
  },

  pocketRupees: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: colors.primary,
    paddingHorizontal: scale(17),
    paddingVertical: scale(6),
    justifyContent: "center",
    borderRadius: scale(100),
  },

  pocket: {
    width: scale(20),
    height: scale(20),
  },
  add: {
    width: scale(20),
    height: scale(20),
  },
  rupees: {
    paddingHorizontal: scale(8),
    fontSize: scale(13),
  },
  playTime: {
    width: scale(100),
    marginLeft: scale(35),
  },

  tabIndex: {
    flexDirection: "row",
    justifyContent: "center",
    justifyContent: "space-between",
    backgroundColor: colors.primary,
    paddingHorizontal: scale(25),
    paddingVertical: scale(7),
    borderRadius: 100,
    marginHorizontal: scale(15),
    marginTop: scale(15),
  },
  indexActive: {
    borderBottomColor: colors.btn,
    borderBottomWidth: 2,
  },
  tabName: {
    fontSize: scale(13),
    fontFamily: fonts._700,
    color: colors.darkgrey,
  },
  index: {
    paddingVertical: verticalScale(3),
  },
});

export default Header;
