import { View, StyleSheet, FlatList, TouchableOpacity } from "react-native";
import { scale, verticalScale } from "react-native-size-matters";
import { useEffect, useState } from "react";

import Typography from "./typography";
import Img from "./image";
import Loader from "./loader";

import colors from "../contants/colors";

import api, { scoreBaseURL } from "../functions/api";
import { useNavigation } from "@react-navigation/native";
import Placeholder from "./placeholder";

const Players = ({ match, matchData }) => {
  const [players, setPlayers] = useState([]);
  const [showLoader, setShowLoader] = useState(true);

  const { navigate } = useNavigation();

  const getPlayersStats = async () => {
    try {
      const data = await api.get("/player-stats/" + match, true);
      console.log("🚀 ~ getPlayersStats ~ data:", data[0]);
      setPlayers(data);
    } catch (err) {
      console.log("🚀 ~ file: players.js:17 ~ getPlayersStats ~ err:", err);
    }
    setShowLoader(false);
  };

  useEffect(() => {
    match && getPlayersStats();
  }, [matchData]);

  return (
    <>
      {/* <Loader show={showLoader} /> */}
      <View style={styles.playersMatch}>
        <Typography
          title="Players Stast  At Match Level"
          style={styles.matchStart}
        />
      </View>
      <View style={styles.container}>
        <View style={styles.allPlayers}>
          <Typography title="PLAYERS" />
          <View style={styles.plyaerBuy}>
            <Typography title="POINTS" style={styles.points} />
            <Typography title="BUY PRICE" style={styles.price} />
            <Typography title="P&L" style={styles.points} />
          </View>
        </View>
        {showLoader ? (
          <View style={{ marginTop: verticalScale(5) }}>
            <Placeholder heigh={verticalScale(50)} />
          </View>
        ) : (
          <FlatList
            data={players}
            contentContainerStyle={styles.flatList}
            showsVerticalScrollIndicator={false}
            renderItem={({ item, index }) => {
              if (!item.points) item.points = { totalPoints: 0 };
              return (
                <>
                  {item && (
                    <TouchableOpacity
                      style={styles.playerName}
                      onPress={() =>
                        navigate("PlayerPerformance", {
                          players,
                          player: index,
                        })
                      }
                    >
                      <View style={styles.playerImg}>
                        <Img
                          source={{ uri: scoreBaseURL + item.image }}
                          style={styles.rohit}
                        />
                        <View style={{ marginLeft: scale(5) }}>
                          <Typography
                            title={item.shortName}
                            style={styles.name}
                            lines={1}
                          />
                          <Typography
                            title={item.team.shortName}
                            style={styles.indian}
                          />
                        </View>
                      </View>
                      {item.points && (
                        <View style={styles.numbers}>
                          <Typography
                            title={item.points.totalPoints}
                            style={styles.num}
                          />
                          <Typography
                            title={item.price || 100}
                            style={styles.priceNum}
                          />
                          <Typography
                            title={
                              (item.points.totalPoints >= (item.price || 100)
                                ? "+"
                                : "") +
                              (item.points.totalPoints - (item.price || 100))
                            }
                            style={[
                              styles.win,
                              {
                                color:
                                  item.points.totalPoints >= (item.price || 100)
                                    ? colors.engGreen
                                    : colors.red,
                              },
                            ]}
                          />
                        </View>
                      )}
                    </TouchableOpacity>
                  )}
                </>
              );
            }}
          />
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  playersMatch: {
    borderBottomColor: colors.darkgrey,
    borderBottomWidth: scale(1),
  },
  matchStart: {
    marginLeft: scale(20),
    paddingBottom: scale(5),
  },
  container: {
    paddingHorizontal: scale(15),
  },
  allPlayers: {
    marginTop: scale(10),
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomColor: colors.white,
    borderBottomWidth: scale(1),
  },
  price: {
    marginHorizontal: scale(15),
    color: colors.darkgrey,
    fontSize: scale(11),
  },
  points: {
    color: colors.darkgrey,
    fontSize: scale(11),
  },
  playerName: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: colors.border,
    borderRadius: 5,
    marginTop: scale(10),
  },
  playerImg: {
    flexDirection: "row",
    alignItems: "center",
  },
  rohit: {
    width: scale(50),
    height: scale(50),
    backgroundColor: colors.primary,
    borderRadius: scale(5),
  },
  numbers: {
    flexDirection: "row",
    marginTop: scale(6),
    marginHorizontal: scale(10),
  },
  name: {
    fontSize: scale(11),
    maxWidth: scale(100),
  },
  indian: {
    fontSize: scale(10),
    color: colors.yellow,
    marginTop: verticalScale(2),
  },
  priceNum: {
    marginHorizontal: scale(30),
    fontSize: scale(10),
  },
  plyaerBuy: {
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: scale(10),
  },
  num: {
    fontSize: scale(10),
    width: scale(35),
    textAlign: "center",
  },
  win: {
    fontSize: scale(10),
    color: colors.engGreen,
  },
  flatList: {
    width: "100%",
    paddingBottom: scale(150),
  },
});

export default Players;
