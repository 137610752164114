import { useEffect, useState } from "react";
import {
  View,
  TouchableOpacity,
  ScrollView,
  FlatList,
  Image,
  Linking,
} from "react-native";
import { scale, verticalScale } from "react-native-size-matters";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import Typography from "../../../components/typography";
import Img from "../../../components/image";
import Loader from "../../../components/loader";
import icons from "../../../contants/icons";
import styles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { setUser, setUserInfo } from "../../../redux/slices";
import getUser from "../../../functions/getUser";
import toast from "../../../functions/toast";
import api, { apiBaseURL } from "../../../functions/api";
import colors from "../../../contants/colors";
import Modal from "../../../components/modal";
import { pwaInstallHandler } from "pwa-install-handler";

const MyProfile = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [level, setLevel] = useState({ curLevel: 1, progress: 0 });
  const [kycDone, setKycDone] = useState(false);
  const [isLogout, setIsLogout] = useState(false);

  const isFocused = useIsFocused();
  const settingsData = [
    { title: "Wallet History", marginBottom: 0, screen: "WalletHistory" },
    { title: "Refer & Earn", marginBottom: 0, screen: "referEarn" },
    { title: "Points System", marginBottom: scale(10), screen: "PointSystem" },
    {
      title: "Install App",
      marginBottom: scale(10),
      onPress: () => {
        if (!pwaInstallHandler.canInstall())
          toast("Your browser is not support Apps");
        pwaInstallHandler.install();
      },
      hide: !pwaInstallHandler.canInstall(),
    },
    {
      title: "Contact Us For Support",
      marginBottom: scale(10),
      onPress: () => Linking.openURL("https://t.me/crickstoxfantasy"),
    },
    { title: "Play Safe", marginBottom: 0 },
    {
      title: "Privacy Policy",
      marginBottom: 0,
      onPress: () =>
        Linking.openURL(
          "https://www.termsfeed.com/live/66f12764-2aca-40ec-8310-22745e81829a"
        ),
    },
    { title: "Terms of Use", marginBottom: 0 },
  ];

  const { goBack, navigate, dispatch: dis, reset } = useNavigation();

  const userInfo = useSelector((s) => s.userInfo.value);

  const user = useSelector((s) => s.user.value);

  const dispatch = useDispatch();

  const pickImage = async () => {
    try {
      const pcr = document.createElement("input");
      pcr.setAttribute("type", "file");
      pcr.setAttribute("accept", "image/*");
      pcr.click();
      pcr.addEventListener("input", async () => {
        const [image] = pcr.files;
        if (image.size > 2000000) return toast("Select image up to 2 mb");
        setShowLoader(true);
        const file = image;
        const fd = new FormData();
        fd.append("image", file);
        fd.append("user", user);
        await api.post("/file-upload", fd, false, true);
        getUser(user, dispatch);
        toast("Photo uploaded successfully");
        setShowLoader(false);
      });
    } catch (err) {
      console.log("🚀 ~ file: index.js:42 ~ pickImage ~ err:", err);
    }
    setShowLoader(false);
  };

  const getLevel = async () => {
    try {
      const data = await api.get("/user-level/" + user);
      setLevel(data);
    } catch (err) {
      console.log("🚀 ~ getLevel ~ err:", err);
    }
  };

  useEffect(() => {
    getUser(user, dispatch);
    getLevel();
  }, [isFocused]);

  const getKyc = async () => {
    // setShowLoader(true);
    try {
      const [data] = await api.get("/kyc/" + user);
      setKycDone(data ? true : false);
    } catch (err) {
      console.log("🚀 ~ file: index.js:74 ~ getKyc ~ err:", err);
    }
    setShowLoader(false);
  };

  useEffect(() => {
    user && isFocused && getKyc();
  }, [user, isFocused]);

  return (
    <ScrollView
      contentContainerStyle={styles.container}
      showsVerticalScrollIndicator={false}
    >
      <Modal
        isVisible={isLogout}
        backdropOpacity={0.8}
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View
          style={{
            backgroundColor: colors.primary,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: scale(10),
            padding: scale(20),
          }}
        >
          <Typography
            title="Are you sure you want to Logout?"
            style={{ fontSize: scale(12), marginBottom: scale(20) }}
          />
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <TouchableOpacity
              onPress={() => setIsLogout(false)}
              style={{
                backgroundColor: colors.btn,
                paddingHorizontal: scale(20),
                paddingVertical: verticalScale(5),
                borderRadius: scale(5),
                marginHorizontal: scale(5),
              }}
            >
              <Typography title="Cancel" />
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                backgroundColor: colors.engRed,
                paddingHorizontal: scale(20),
                paddingVertical: verticalScale(5),
                borderRadius: scale(5),
                marginHorizontal: scale(5),
              }}
              onPress={() => {
                dispatch(setUser(null));
                dispatch(setUserInfo(null));
                dis(
                  reset({
                    index: 0,
                    routes: [{ name: "Login" }],
                  })
                );
              }}
            >
              <Typography title="Logout" />
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
      <Loader show={showLoader} />
      <View style={styles.header}>
        <View style={styles.backScreen}>
          <TouchableOpacity onPress={goBack}>
            <Img source={icons.back} style={styles.back} />
          </TouchableOpacity>
          <Typography title="Profile" style={styles.headerText} />
        </View>
        <TouchableOpacity>
          <Img source={icons.notification} style={styles.notification} />
        </TouchableOpacity>
      </View>
      <View style={styles.userProfile}>
        {userInfo && (
          <>
            <TouchableOpacity style={styles.user} onPress={pickImage}>
              <View style={styles.userPhoto}>
                <Image
                  source={{
                    uri: userInfo.image
                      ? apiBaseURL + userInfo.image
                      : "https://i.pinimg.com/280x280_RS/79/dd/11/79dd11a9452a92a1accceec38a45e16a.jpg",
                  }}
                  style={styles.userImg}
                />
                <View style={styles.camera}>
                  <Img source={icons.camera} style={styles.cameraIcon} />
                </View>
              </View>
            </TouchableOpacity>
            <View>
              <Typography
                title={"USER " + (userInfo._id + 15553)}
                style={styles.userName}
              />
              {userInfo.mobNo && (
                <Typography
                  title={`+91 ${userInfo.mobNo.slice(
                    0,
                    2
                  )}*****${userInfo.mobNo.slice(7, 10)}`}
                  style={styles.userNum}
                />
              )}
            </View>
          </>
        )}
        <View style={styles.levels}>
          <Typography
            title={"Level : " + level.curLevel}
            style={styles.userLevel}
          />
          <Typography
            title={"Level : " + (level.curLevel + 1)}
            style={styles.userLevel}
          />
        </View>
        <View style={styles.border}>
          <View style={[styles.twoBorder, { width: level.progress + "%" }]} />
        </View>
        <View style={styles.playText}>
          <Typography
            title="Play More Contests Get Rewards & Upgrade Your Level"
            style={styles.text}
          />
          <View style={styles.backScreen}>
            <Img source={icons.rupees} style={styles.rupees} />
            <TouchableOpacity>
              <Typography title="66" style={styles.rupeesText} />
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.addRupees}>
          <TouchableOpacity
            style={[
              styles.amount,
              { opacity: userInfo && userInfo.agent != null ? 0.2 : 1 },
            ]}
            disabled={userInfo && userInfo.agent != null}
            onPress={() => {
              navigate("Recharge");
            }}
          >
            <Img source={icons.rupees} style={styles.rupeesIcon} />
            <Typography title="Add Amount" style={styles.amountText} />
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.withdrawal}
            onPress={() => navigate("Withdraw")}
          >
            <Img source={icons.rupees} style={styles.rupeesIcon} />
            <Typography title="Withdrawal" style={styles.amountText} />
          </TouchableOpacity>
        </View>
        <TouchableOpacity
          style={[styles.userKyc, kycDone && { borderColor: colors.engGreen }]}
          onPress={() => navigate("Kyc")}
        >
          <View style={styles.kycCom}>
            <Img
              source={icons.kyc}
              style={{
                ...styles.kycIcon,
                ...(kycDone && { tintColor: colors.engGreen }),
              }}
            />
            <View>
              <Typography
                title={!kycDone ? "Complete KYC" : "KYC Completed"}
                style={{
                  ...styles.kycText,
                  ...(kycDone && { color: colors.engGreen }),
                }}
              />
              <Typography
                title={`${kycDone ? "Edit" : "Add"} Bank Details`}
                style={{
                  ...styles.addBank,
                }}
              />
            </View>
          </View>
          <TouchableOpacity>
            <Img
              source={icons.right}
              style={{
                ...styles.rightIcon,
                ...(kycDone && { tintColor: colors.engGreen }),
              }}
            />
          </TouchableOpacity>
        </TouchableOpacity>
        <View style={styles.setting}>
          <FlatList
            data={settingsData}
            renderItem={({ item, index }) =>
              !item.hide ? (
                <TouchableOpacity
                  style={[
                    styles.allSetting,
                    { marginBottom: item.marginBottom },
                  ]}
                  disabled={!item.screen && !item.onPress}
                  onPress={() => {
                    if (item.onPress) return item.onPress();
                    navigate(item.screen);
                  }}
                >
                  <Typography title={item.title} style={styles.settingText} />
                  <Img source={icons.right} style={styles.right} />
                </TouchableOpacity>
              ) : (
                <></>
              )
            }
          />
          <TouchableOpacity
            style={styles.LogOut}
            onPress={() => setIsLogout(true)}
          >
            <Typography title="Logout" style={styles.settingText} />
            <Img source={icons.logout} style={styles.logout} />
          </TouchableOpacity>
        </View>
      </View>
    </ScrollView>
  );
};

export default MyProfile;
