import { useState, useEffect } from "react";
import {
  View,
  TouchableOpacity,
  FlatList,
  ScrollView,
  Dimensions,
} from "react-native";
import { useSelector } from "react-redux";

import Typography from "../../../components/typography";
import Img from "../../../components/image";

import styles from "./styles";

import icons from "../../../contants/icons";
import colors from "../../../contants/colors";
import images from "../../../contants/images";
import Header from "../../../components/header";
import Loader from "../../../components/loader";

import api, { scoreBaseURL } from "../../../functions/api";
import toast from "../../../functions/toast";
import { useRoute } from "@react-navigation/native";
import ReactNativeModal from "../../../components/modal";
import { scale, verticalScale } from "react-native-size-matters";
import axios from "axios";

const Players = ({ navigation, match, all, status }) => {
  const [data, setData] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [boughtPlayers, setBoughtPlayers] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [isMatchLive, setIsMatchLive] = useState(false);
  const [sm, setSM] = useState(false);

  const user = useSelector((s) => s.user.value);

  const route = useRoute();

  let contestID = null;

  let contest = null,
    contestPrice = null;

  if (route.params) {
    match = route.params.match || match;
    contest = route.params.contest;
    contestID = route.params.contestID || null;
    contestPrice = route.params.contestPrice || null;
  }

  const getmatchPlayers = async () => {
    setShowLoader(true);
    try {
      const data = await api.get("/match-players/" + match, true);
      setData(data);
    } catch (err) {
      console.log("🚀 ~ file: index.js:21 ~ getmatchPlayers ~ err:", err);
    }
    setShowLoader(false);
  };

  const buyPlayer = async (player) => {
    setShowLoader(true);
    try {
      const data = {
        player: player._id,
        user,
        buyPrice: (player.price || 100) * quantity,
        _match: match,
        quantity,
      };

      await api.post("/buy-player", data);

      getBoughtPlayers();

      toast("Player bought successfully");
    } catch (err) {
      console.log("🚀 ~ file: index.js:38 ~ buyPlayer ~ err:", err);
    }
    setShowLoader(false);
    setShowModel(false);
    setQuantity(1);
  };

  const getBoughtPlayers = async () => {
    setShowLoader(true);
    try {
      const data = await api.get(`/buy-players?user=${user}&match=${match}`);
      setBoughtPlayers(data.filter((i) => i.status == 0).map((i) => i.player));
    } catch (err) {
      console.log("🚀 ~ file: index.js:61 ~ getBoughtPlayers ~ err:", err);
    }
    setShowLoader(false);
  };

  const joinContest = async (player) => {
    setShowLoader(true);
    try {
      await api.post("/join-contest", {
        user,
        contest: contest._id,
        player: player._id,
        _id: contestID,
        edit: 0,
        _match: match,
      });
      toast(contestID ? "Player updated" : "Contest joined successfully");
      return navigation.goBack();
    } catch (err) {
      console.log("🚀 ~ file: index.js:74 ~ joinContest ~ err:", err);
    }
    setShowLoader(false);
  };

  const checkMatch = async () => {
    setShowLoader(true);
    try {
      const {
        data: { result },
      } = await axios.get(scoreBaseURL + "/is-match-live/" + match);
      setIsMatchLive(result);
    } catch (err) {
      console.log("🚀 ~ checkMatch ~ err:", err);
    }
    setShowLoader(false);
  };

  const cancelPlayer = async () => {
    try {
      await api.post("/cancel-player", {
        user,
        player: selectedPlayer._id,
        match,
      });
      setSM(false);
      getBoughtPlayers();
      toast("Player has been removed");
    } catch (err) {
      console.log("🚀 ~ cancelPlayer ~ err:", err);
    }
  };

  const getPlayerById = (id) =>
    data.teamA.players.find(({ _id }) => _id == id) ||
    data.teamB.players.find(({ _id }) => _id == id) ||
    (data.teamA.np && data.teamA.np.find(({ _id }) => _id == id)) ||
    (data.teamB.np && data.teamB.np.find(({ _id }) => _id == id));

  const hideModel = () => {
    setSelectedPlayer(null);
    setShowModel(false);
    setQuantity(1);
  };

  useEffect(() => {
    getmatchPlayers();
    !contest && !all && getBoughtPlayers();
    !contest && checkMatch();
  }, []);

  // useEffect(() => {
  //   if (data) {
  //     const organizePlayers = players => {
  //       return players.reduce((acc, player) => {
  //         const role = player.roll;
  //         if (!acc[role]) {
  //           acc[role] = [];
  //         }
  //         acc[role].push(player);
  //         return acc;
  //       }, {});
  //     };

  //     const teamAPlayers = organizePlayers(data.teamA.players);
  //     const teamBPlayers = organizePlayers(data.teamB.players);

  //     data.teamA.players = [
  //       ...(teamAPlayers['Wicket Keeper'] || []),
  //       ...(teamAPlayers['Batsmen'] || []),
  //       ...(teamAPlayers['All-Rounders'] || []),
  //       ...(teamAPlayers['Bowler'] || []),
  //     ];

  //     data.teamB.players = [
  //       ...(teamBPlayers['Wicket Keeper'] || []),
  //       ...(teamBPlayers['Batsmen'] || []),
  //       ...(teamBPlayers['All-Rounders'] || []),
  //       ...(teamBPlayers['Bowler'] || []),
  //     ];

  //     setData(prev => ({...prev, ...data}));
  //   }
  // }, [data]);

  return (
    <>
      <ReactNativeModal
        isVisible={showModel}
        backdropOpacity={0.8}
        onBackdropPress={hideModel}
        onBackButtonPress={hideModel}
      >
        {selectedPlayer && (
          <View
            style={{
              width: "100%",
              height: scale(280),
              backgroundColor: colors.primary,
              borderRadius: scale(10),
              alignItems: "center",
              justifyContent: "center",
              paddingHorizontal: scale(10),
            }}
          >
            <Typography
              title={`Are you sure you want to ${
                contest ? "Joint Contest" : "Buy"
              } at ${
                (contest ? contestPrice : selectedPlayer.price || 100) *
                quantity
              } INR`}
              style={{
                textAlign: "center",
                fontSize: scale(13),
                lineHeight: scale(23),
              }}
            />
            <Img
              source={{ uri: scoreBaseURL + selectedPlayer.image }}
              style={{
                width: scale(80),
                height: scale(80),
                marginTop: scale(10),
              }}
            />
            <Typography
              title={selectedPlayer.shortName}
              style={{
                textAlign: "center",
                fontSize: scale(13),
                lineHeight: scale(23),
                marginTop: scale(5),
              }}
            />
            {!contest && (
              <View style={{ marginTop: scale(10), flexDirection: "row" }}>
                <TouchableOpacity
                  style={{
                    backgroundColor: colors.red,
                    width: scale(25),
                    height: scale(25),
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: scale(5),
                  }}
                  onPress={() => setQuantity(quantity <= 1 ? 1 : quantity - 1)}
                >
                  <Typography
                    title="-"
                    style={{
                      fontSize: scale(26),
                      marginTop: scale(-6),
                    }}
                  />
                </TouchableOpacity>
                <View
                  style={{
                    width: scale(25),
                    height: scale(25),
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: colors.white,
                    marginHorizontal: scale(5),
                  }}
                >
                  <Typography
                    title={quantity}
                    style={{
                      color: colors.black,
                      fontSize: scale(20),
                      marginTop: scale(-2),
                    }}
                  />
                </View>
                <TouchableOpacity
                  style={{
                    backgroundColor: colors.engGreen,
                    width: scale(25),
                    height: scale(25),
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: scale(5),
                  }}
                  onPress={() => setQuantity(quantity + 1)}
                >
                  <Typography
                    title="+"
                    style={{
                      fontSize: scale(26),
                      marginTop: scale(-6),
                    }}
                  />
                </TouchableOpacity>
              </View>
            )}
            <View style={{ marginTop: scale(15), flexDirection: "row" }}>
              <TouchableOpacity
                onPress={() =>
                  contest
                    ? joinContest(selectedPlayer)
                    : buyPlayer(selectedPlayer)
                }
                style={{
                  paddingVertical: scale(5),
                  paddingHorizontal: scale(30),
                  backgroundColor: colors.btn,
                  borderRadius: scale(6),
                }}
              >
                <Typography
                  title={contest ? "Join Now" : "Buy Now"}
                  style={{ fontSize: scale(14) }}
                />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={hideModel}
                style={{
                  paddingVertical: scale(5),
                  paddingHorizontal: scale(30),
                  backgroundColor: colors.red,
                  marginLeft: scale(15),
                  borderRadius: scale(6),
                }}
              >
                <Typography title="Cancel" style={{ fontSize: scale(14) }} />
              </TouchableOpacity>
            </View>
          </View>
        )}
      </ReactNativeModal>
      <ReactNativeModal
        isVisible={sm}
        backdropOpacity={0.8}
        onBackdropPress={hideModel}
        onBackButtonPress={setSM}
      >
        {selectedPlayer && (
          <View
            style={{
              width: "100%",
              height: scale(280),
              backgroundColor: colors.primary,
              borderRadius: scale(10),
              alignItems: "center",
              justifyContent: "center",
              paddingHorizontal: scale(10),
            }}
          >
            <Typography
              title={`Are you sure you want to remove player`}
              style={{
                textAlign: "center",
                fontSize: scale(13),
                lineHeight: scale(23),
              }}
            />
            <Typography
              title={`Amount will be refunded to your wallet`}
              style={{
                textAlign: "center",
                fontSize: scale(13),
                lineHeight: scale(23),
                color: colors.yellow,
              }}
            />
            <Img
              source={{ uri: scoreBaseURL + selectedPlayer.image }}
              style={{
                width: scale(80),
                height: scale(80),
                marginTop: scale(10),
              }}
            />
            <Typography
              title={selectedPlayer.shortName}
              style={{
                textAlign: "center",
                fontSize: scale(13),
                lineHeight: scale(23),
                marginTop: scale(5),
              }}
            />

            <View style={{ marginTop: scale(15), flexDirection: "row" }}>
              <TouchableOpacity
                onPress={cancelPlayer}
                style={{
                  paddingVertical: scale(5),
                  paddingHorizontal: scale(30),
                  backgroundColor: colors.btn,
                  borderRadius: scale(6),
                }}
              >
                <Typography title="Yes" style={{ fontSize: scale(14) }} />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={hideModel}
                style={{
                  paddingVertical: scale(5),
                  paddingHorizontal: scale(30),
                  backgroundColor: colors.red,
                  marginLeft: scale(15),
                  borderRadius: scale(6),
                }}
              >
                <Typography title="No" style={{ fontSize: scale(14) }} />
              </TouchableOpacity>
            </View>
          </View>
        )}
      </ReactNativeModal>
      <Loader show={showLoader} />
      {data && (
        <View style={styles.container}>
          {/* <Header /> */}
          <View style={styles.country}>
            <Typography
              title={`${data.teamA.shortName} Player’s`}
              style={styles.ind}
            />
            <Typography
              title={`${data.teamB.shortName} Player’s`}
              style={styles.ind}
            />
          </View>

          <ScrollView
            nestedScrollEnabled
            showsVerticalScrollIndicator={false}
            style={{
              height: Dimensions.get("window").height - verticalScale(150),
            }}
          >
            <View style={{ width: "100%", flexDirection: "row" }}>
              <FlatList
                style={{ width: "50%" }}
                data={data.teamA.anouncedPlayers || data.teamA.players}
                contentContainerStyle={styles.flatList}
                numColumns={1}
                renderItem={({ item, index }) => {
                  item = getPlayerById(item);
                  const roll = !item
                    ? ""
                    : !item.roll
                    ? ""
                    : item && item.roll.toLowerCase();
                  return (
                    <>
                      {item && (
                        <View style={styles.indPlayers}>
                          <View style={styles.playerImg}>
                            <View style={{ position: "relative" }}>
                              <Img
                                source={{ uri: scoreBaseURL + item.image }}
                                style={styles.rohit}
                              />
                              <Typography
                                title={
                                  roll === "batsmen"
                                    ? "Bat"
                                    : roll === "bowler"
                                    ? "Bowl"
                                    : roll === "wicket keeper"
                                    ? "WK"
                                    : "All"
                                }
                                lines={1}
                                style={{
                                  fontSize: scale(8),
                                  marginTop: verticalScale(-2),
                                  position: "absolute",
                                  left: 0,
                                  bottom: 0,
                                  width: "100%",
                                  textAlign: "center",
                                  backgroundColor: "rgba(0,0,0,0.5)",
                                  paddingVertical: scale(1),
                                }}
                              />
                            </View>
                            <View style={styles.playerBuy}>
                              <Typography
                                title={item.shortName}
                                style={styles.name}
                                lines={1}
                              />
                              <TouchableOpacity
                                style={[
                                  styles.buyRupees,
                                  boughtPlayers.includes(item._id) && {
                                    opacity: 0.4,
                                  },
                                ]}
                                onPress={() => {
                                  setSelectedPlayer(item);

                                  setShowModel(true);
                                }}
                                disabled={boughtPlayers.includes(item._id)}
                              >
                                {!contest ? (
                                  <>
                                    <Typography
                                      title="Buy"
                                      style={styles.buy}
                                    />
                                    <Img
                                      source={icons.rupees}
                                      style={styles.rupeesImg}
                                    />
                                    <Typography
                                      title={item.price || 100}
                                      style={styles.win}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Typography
                                      title="Select"
                                      style={styles.buy}
                                    />
                                    <Img
                                      source={icons.add}
                                      style={{
                                        ...styles.rupeesImg,
                                        ...{
                                          width: scale(12),
                                          height: scale(12),
                                        },
                                      }}
                                    />
                                  </>
                                )}
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      )}
                    </>
                  );
                }}
              />
              <FlatList
                style={{ width: "50%" }}
                data={data.teamB.anouncedPlayers || data.teamB.players}
                contentContainerStyle={styles.flatList}
                numColumns={1}
                renderItem={({ item, index }) => {
                  item = getPlayerById(item);
                  const roll = item && item.roll.toLowerCase();
                  return (
                    <>
                      {item && (
                        <View style={styles.indPlayers}>
                          <View style={styles.playerImg}>
                            <View style={{ position: "relative" }}>
                              <Img
                                source={{ uri: scoreBaseURL + item.image }}
                                style={styles.rohit}
                              />
                              <Typography
                                title={
                                  roll === "batsmen"
                                    ? "Bat"
                                    : roll === "bowler"
                                    ? "Bowl"
                                    : roll === "wicket keeper"
                                    ? "WK"
                                    : "All"
                                }
                                lines={1}
                                style={{
                                  fontSize: scale(8),
                                  marginTop: verticalScale(-2),
                                  position: "absolute",
                                  left: 0,
                                  bottom: 0,
                                  width: "100%",
                                  textAlign: "center",
                                  backgroundColor: "rgba(0,0,0,0.5)",
                                  paddingVertical: scale(1),
                                }}
                              />
                            </View>
                            <View style={styles.playerBuy}>
                              <Typography
                                title={item.shortName}
                                style={styles.name}
                                lines={1}
                              />
                              <TouchableOpacity
                                style={[
                                  styles.buyRupees,
                                  boughtPlayers.includes(item._id) && {
                                    opacity: 0.4,
                                  },
                                ]}
                                onPress={() => {
                                  setSelectedPlayer(item);

                                  setShowModel(true);
                                }}
                                disabled={boughtPlayers.includes(item._id)}
                              >
                                {!contest ? (
                                  <>
                                    <Typography
                                      title="Buy"
                                      style={styles.buy}
                                    />
                                    <Img
                                      source={icons.rupees}
                                      style={styles.rupeesImg}
                                    />
                                    <Typography
                                      title={item.price || 100}
                                      style={styles.win}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Typography
                                      title="Select"
                                      style={styles.buy}
                                    />
                                    <Img
                                      source={icons.add}
                                      style={{
                                        ...styles.rupeesImg,
                                        ...{
                                          width: scale(12),
                                          height: scale(12),
                                        },
                                      }}
                                    />
                                  </>
                                )}
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      )}
                    </>
                  );
                }}
              />
            </View>

            {/* subsitued players */}

            {((data.teamA.subsitutePlayers &&
              data.teamA.subsitutePlayers.length > 0) ||
              (data.teamB.subsitutePlayers &&
                data.teamB.subsitutePlayers.length > 0)) && (
              <>
                <View style={styles.nonPlayer}>
                  <View
                    style={[styles.dot, { backgroundColor: colors.btn }]}
                  ></View>
                  <Typography
                    title="Substitute Players"
                    style={styles.nonText}
                  />
                </View>
                <View style={{ width: "100%", flexDirection: "row" }}>
                  <FlatList
                    style={{ width: "50%" }}
                    data={data.teamA.subsitutePlayers}
                    contentContainerStyle={styles.flatList}
                    numColumns={1}
                    renderItem={({ item, index }) => {
                      item = getPlayerById(item);
                      const roll = !item
                        ? ""
                        : !item.roll
                        ? ""
                        : item && item.roll.toLowerCase();
                      return (
                        <>
                          {item && (
                            <>
                              <View style={styles.indPlayers}>
                                <View style={styles.playerImg}>
                                  <View style={{ position: "relative" }}>
                                    <Img
                                      source={{
                                        uri: scoreBaseURL + item.image,
                                      }}
                                      style={styles.rohit}
                                    />
                                    <Typography
                                      title={
                                        roll === "batsmen"
                                          ? "Bat"
                                          : roll === "bowler"
                                          ? "Bowl"
                                          : roll === "wicket keeper"
                                          ? "WK"
                                          : "All"
                                      }
                                      lines={1}
                                      style={{
                                        fontSize: scale(8),
                                        marginTop: verticalScale(-2),
                                        position: "absolute",
                                        left: 0,
                                        bottom: 0,
                                        width: "100%",
                                        textAlign: "center",
                                        backgroundColor: "rgba(0,0,0,0.5)",
                                        paddingVertical: scale(1),
                                      }}
                                    />
                                  </View>
                                  <View style={styles.playerBuy}>
                                    <Typography
                                      title={item.shortName}
                                      style={styles.name}
                                      lines={1}
                                    />
                                    <TouchableOpacity
                                      style={[
                                        styles.buyRupees,
                                        boughtPlayers.includes(item._id) &&
                                          isMatchLive && {
                                            opacity: 0.4,
                                          },
                                        { borderColor: colors.btn },
                                      ]}
                                      onPress={() => {
                                        setSelectedPlayer(item);
                                        if (boughtPlayers.includes(item._id)) {
                                          setSM(true);
                                        } else {
                                          setShowModel(true);
                                        }
                                      }}
                                      disabled={
                                        boughtPlayers.includes(item._id) &&
                                        isMatchLive
                                      }
                                    >
                                      {!contest ? (
                                        <>
                                          <Typography
                                            title={
                                              boughtPlayers.includes(item._id)
                                                ? "Remove"
                                                : "Buy"
                                            }
                                            style={{
                                              ...styles.buy,
                                              ...(boughtPlayers.includes(
                                                item._id
                                              ) && {
                                                marginLeft: scale(5),
                                                marginRight: scale(2),
                                              }),
                                            }}
                                          />
                                          <Img
                                            source={icons.rupees}
                                            style={{
                                              ...styles.rupeesImg,
                                              tintColor: colors.btn,
                                            }}
                                          />
                                          <Typography
                                            title={item.price || 100}
                                            style={{
                                              ...styles.win,
                                              color: colors.btn,
                                            }}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <Typography
                                            title="Select"
                                            style={styles.buy}
                                          />
                                          <Img
                                            source={icons.add}
                                            style={{
                                              ...styles.rupeesImg,
                                              ...{
                                                width: scale(12),
                                                height: scale(12),
                                                tintColor: colors.btn,
                                              },
                                            }}
                                          />
                                        </>
                                      )}
                                    </TouchableOpacity>
                                  </View>
                                </View>
                              </View>
                            </>
                          )}
                        </>
                      );
                    }}
                  />
                  <FlatList
                    style={{ width: "50%" }}
                    data={data.teamB.subsitutePlayers}
                    contentContainerStyle={styles.flatList}
                    numColumns={1}
                    renderItem={({ item, index }) => {
                      item = getPlayerById(item);
                      const roll = !item
                        ? ""
                        : !item.roll
                        ? ""
                        : item && item.roll.toLowerCase();
                      return (
                        <>
                          {item && (
                            <View style={styles.indPlayers}>
                              <View style={styles.playerImg}>
                                <View style={{ position: "relative" }}>
                                  <Img
                                    source={{ uri: scoreBaseURL + item.image }}
                                    style={styles.rohit}
                                  />
                                  <Typography
                                    title={
                                      roll === "batsmen"
                                        ? "Bat"
                                        : roll === "bowler"
                                        ? "Bowl"
                                        : roll === "wicket keeper"
                                        ? "WK"
                                        : "All"
                                    }
                                    lines={1}
                                    style={{
                                      fontSize: scale(8),
                                      marginTop: verticalScale(-2),
                                      position: "absolute",
                                      left: 0,
                                      bottom: 0,
                                      width: "100%",
                                      textAlign: "center",
                                      backgroundColor: "rgba(0,0,0,0.5)",
                                      paddingVertical: scale(1),
                                    }}
                                  />
                                </View>
                                <View style={styles.playerBuy}>
                                  <Typography
                                    title={item.shortName}
                                    style={styles.name}
                                    lines={1}
                                  />
                                  <TouchableOpacity
                                    style={[
                                      styles.buyRupees,
                                      boughtPlayers.includes(item._id) &&
                                        isMatchLive && {
                                          opacity: 0.4,
                                        },
                                      { borderColor: colors.btn },
                                    ]}
                                    onPress={() => {
                                      setSelectedPlayer(item);
                                      if (boughtPlayers.includes(item._id)) {
                                        setSM(true);
                                      } else {
                                        setShowModel(true);
                                      }
                                    }}
                                    disabled={boughtPlayers.includes(
                                      item._id || isMatchLive
                                    )}
                                  >
                                    {!contest ? (
                                      <>
                                        <Typography
                                          title={
                                            boughtPlayers.includes(item._id)
                                              ? "Remove"
                                              : "Buy"
                                          }
                                          style={{
                                            ...styles.buy,
                                            ...(boughtPlayers.includes(
                                              item._id
                                            ) && {
                                              marginLeft: scale(2),
                                              marginRight: scale(2),
                                            }),
                                          }}
                                        />
                                        <Img
                                          source={icons.rupees}
                                          style={{
                                            ...styles.rupeesImg,
                                            tintColor: colors.btn,
                                          }}
                                        />
                                        <Typography
                                          title={item.price || 100}
                                          style={{
                                            ...styles.win,
                                            color: colors.btn,
                                          }}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <Typography
                                          title="Select"
                                          style={styles.buy}
                                        />
                                        <Img
                                          source={icons.add}
                                          style={{
                                            ...styles.rupeesImg,
                                            ...{
                                              width: scale(12),
                                              height: scale(12),
                                              tintColor: colors.btn,
                                            },
                                          }}
                                        />
                                      </>
                                    )}
                                  </TouchableOpacity>
                                </View>
                              </View>
                            </View>
                          )}
                        </>
                      );
                    }}
                  />
                </View>
              </>
            )}

            {/* none anounced Players */}
            {((data.teamA.nPlayers && data.teamA.nPlayers.length > 0) ||
              (data.teamB.nPlayers && data.teamB.nPlayers.length > 0)) && (
              <>
                <View style={styles.nonPlayer}>
                  <View style={styles.dot}></View>
                  <Typography
                    title="Non Announcement Players"
                    style={styles.nonText}
                  />
                </View>
                <View style={{ width: "100%", flexDirection: "row" }}>
                  <FlatList
                    style={{ width: "50%" }}
                    data={data.teamA.nPlayers}
                    contentContainerStyle={styles.flatList}
                    numColumns={1}
                    renderItem={({ item, index }) => {
                      item = getPlayerById(item);
                      const roll = !item
                        ? ""
                        : !item.roll
                        ? ""
                        : item && item.roll.toLowerCase();
                      return (
                        <>
                          {item && (
                            <View style={styles.indPlayers}>
                              <View style={styles.playerImg}>
                                <View style={{ position: "relative" }}>
                                  <Img
                                    source={{ uri: scoreBaseURL + item.image }}
                                    style={styles.rohit}
                                  />
                                  <Typography
                                    title={
                                      roll === "batsmen"
                                        ? "Bat"
                                        : roll === "bowler"
                                        ? "Bowl"
                                        : roll === "wicket keeper"
                                        ? "WK"
                                        : "All"
                                    }
                                    lines={1}
                                    style={{
                                      fontSize: scale(8),
                                      marginTop: verticalScale(-2),
                                      position: "absolute",
                                      left: 0,
                                      bottom: 0,
                                      width: "100%",
                                      textAlign: "center",
                                      backgroundColor: "rgba(0,0,0,0.5)",
                                      paddingVertical: scale(1),
                                    }}
                                  />
                                </View>
                                <View style={styles.playerBuy}>
                                  <Typography
                                    title={item.shortName}
                                    style={styles.name}
                                    lines={1}
                                  />
                                  <TouchableOpacity
                                    style={[
                                      styles.buyRupees,
                                      {
                                        borderColor: colors.engRed,
                                        width: scale(90),
                                      },
                                    ]}
                                    disabled
                                  >
                                    {!contest ? (
                                      <>
                                        <Typography
                                          title={
                                            boughtPlayers.includes(item._id)
                                              ? "Refunded"
                                              : "---"
                                          }
                                          style={{
                                            ...styles.buy,
                                            fontSize: scale(8),
                                            marginRight: scale(5),
                                          }}
                                        />
                                        <Img
                                          source={icons.rupees}
                                          style={{
                                            ...styles.rupeesImg,
                                            tintColor: colors.engRed,
                                            marginRight: scale(1),
                                          }}
                                        />
                                        <Typography
                                          title={item.price || 100}
                                          style={{
                                            ...styles.win,
                                            color: colors.engRed,
                                          }}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <Typography
                                          title="Select"
                                          style={styles.buy}
                                        />
                                      </>
                                    )}
                                  </TouchableOpacity>
                                </View>
                              </View>
                            </View>
                          )}
                        </>
                      );
                    }}
                  />
                  <FlatList
                    style={{ width: "50%" }}
                    data={data.teamB.nPlayers}
                    contentContainerStyle={styles.flatList}
                    numColumns={1}
                    renderItem={({ item, index }) => {
                      item = getPlayerById(item);
                      const roll = !item
                        ? ""
                        : !item.roll
                        ? ""
                        : item && item.roll.toLowerCase();
                      return (
                        <>
                          {item && (
                            <View style={styles.indPlayers}>
                              <View style={styles.playerImg}>
                                <View style={{ position: "relative" }}>
                                  <Img
                                    source={{ uri: scoreBaseURL + item.image }}
                                    style={styles.rohit}
                                  />
                                  <Typography
                                    title={
                                      roll === "batsmen"
                                        ? "Bat"
                                        : roll === "bowler"
                                        ? "Bowl"
                                        : roll === "wicket keeper"
                                        ? "WK"
                                        : "All"
                                    }
                                    lines={1}
                                    style={{
                                      fontSize: scale(8),
                                      marginTop: verticalScale(-2),
                                      position: "absolute",
                                      left: 0,
                                      bottom: 0,
                                      width: "100%",
                                      textAlign: "center",
                                      backgroundColor: "rgba(0,0,0,0.5)",
                                      paddingVertical: scale(1),
                                    }}
                                  />
                                </View>
                                <View style={styles.playerBuy}>
                                  <Typography
                                    title={item.shortName}
                                    style={styles.name}
                                    lines={1}
                                  />
                                  <TouchableOpacity
                                    style={[
                                      styles.buyRupees,
                                      {
                                        borderColor: colors.engRed,
                                        width: scale(90),
                                      },
                                    ]}
                                    disabled
                                  >
                                    {!contest ? (
                                      <>
                                        <Typography
                                          title={
                                            boughtPlayers.includes(item._id)
                                              ? "Refunded"
                                              : "---"
                                          }
                                          style={{
                                            ...styles.buy,
                                            fontSize: scale(8),
                                            marginRight: scale(5),
                                          }}
                                        />
                                        <Img
                                          source={icons.rupees}
                                          style={{
                                            ...styles.rupeesImg,
                                            tintColor: colors.engRed,
                                            marginRight: scale(1),
                                          }}
                                        />
                                        <Typography
                                          title={item.price || 100}
                                          style={{
                                            ...styles.win,
                                            color: colors.engRed,
                                          }}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <Typography
                                          title="Select"
                                          style={styles.buy}
                                        />
                                      </>
                                    )}
                                  </TouchableOpacity>
                                </View>
                              </View>
                            </View>
                          )}
                        </>
                      );
                    }}
                  />
                </View>
              </>
            )}
          </ScrollView>
        </View>
      )}
    </>
  );
};

export default Players;
