import { View, StyleSheet, TouchableOpacity, ScrollView } from "react-native";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import Typography from "./typography";
import Img from "./image";
import BetConfirm from "./betConfirm";
import Loader from "./loader";

import colors from "../contants/colors";
import icons from "../contants/icons";
import fonts from "../contants/fonts";

import api, { scoreBaseURL } from "../functions/api";
import toast from "../functions/toast";
import { useNavigation } from "@react-navigation/native";
import { scale, verticalScale } from "react-native-size-matters";

const MatchDetails = ({ data, bowlerResult, x, runRate }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [isTeamBBetting, setIsTeamBettingTeam] = useState(false);
  const [isTeamABetting, setIsTeamAettingTeam] = useState(false);
  const [runrate, setRunrate] = useState(null);

  const user = useSelector((s) => s.user.value);
  const userInfo = useSelector((s) => s.userInfo.value);

  const { goBack, navigate } = useNavigation();

  const addBet = async (amount) => {
    try {
      const prepData = {
        amount,
        _match: data._id,
        selectedTeam,
        user,
        x: parseFloat(data.teamA === selectedTeam ? x.teamA : x.teamB).toFixed(
          3
        ),
      };

      await api.post("/add-match-bet", prepData);

      setShowModal(false);
      return toast("Bet added successfully");
    } catch (err) {
      console.log("🚀 ~ file: matchDetails.js:22 ~ addBet ~ err:", err);
    }
    setShowLoader(false);
  };

  useEffect(() => {
    if (data) {
      setIsTeamBettingTeam(
        data.bettingTeam === "teamB" || data.score.teams.teamB.over !== "0.0"
      );

      setIsTeamAettingTeam(
        data.bettingTeam === "teamA" || data.score.teams.teamA.over !== "0.0"
      );
    }
  }, [data]);

  useEffect(() => {
    setShowModal(false);
  }, [x]);

  useEffect(() => {
    runRate && setRunrate(runRate.finalRunRate);
  }, [runRate]);

  return (
    <>
      <Loader show={showLoader} />
      <BetConfirm
        show={showModal}
        hideModal={() => setShowModal(false)}
        min={100}
        val={"100"}
        onChange={addBet}
        x={
          data &&
          x &&
          parseFloat(data.teamA === selectedTeam ? x.teamA : x.teamB).toFixed(3)
        }
        selected={
          data &&
          (data.teamA === selectedTeam
            ? data.teamAShortName
            : data.teamBShortName)
        }
      />
      {data && x ? (
        <>
          <View style={styles.teamMatch}>
            <View style={styles.raper}>
              <View style={styles.header}>
                <TouchableOpacity onPress={goBack}>
                  <Img source={icons.back} style={styles.back} />
                </TouchableOpacity>
                <Typography
                  title={`${data.teamAShortName} vs ${data.teamBShortName}`}
                  style={styles.countryName}
                />
              </View>
              <View style={{ flexDirection: "row" }}>
                {userInfo && (
                  <TouchableOpacity
                    style={styles.pocketRupees}
                    onPress={() => navigate("Recharge")}
                  >
                    <Img source={icons.pocket} style={styles.pocket} />
                    <Typography
                      title={parseFloat(userInfo.wallet).toFixed(2)}
                      style={styles.rupees}
                    />
                    <Img source={icons.add} style={styles.add} />
                  </TouchableOpacity>
                )}
                <TouchableOpacity
                  style={styles.btn}
                  onPress={() => navigate("BetList")}
                >
                  <Typography title="MY STAKE" style={styles.btnText} />
                </TouchableOpacity>
              </View>
            </View>
            <View style={styles.stateName}>
              <Typography title={data.teamAShortName} style={styles.india} />
              <Typography title={data.teamBShortName} style={styles.india} />
            </View>
            <View style={styles.matchTeam}>
              {/* team a */}
              <View
                style={[
                  styles.team,
                  {
                    opacity:
                      data.status == "Completed" && data.winner == data.teamB
                        ? 0.5
                        : 1,
                  },
                ]}
              >
                {data.status === "Completed" && data.winner == data.teamA && (
                  <>
                    <Img
                      source={icons.trophy}
                      style={{
                        width: scale(15),
                        marginRight: scale(5),
                        tintColor: colors.yellow,
                        marginRight: scale(10),
                      }}
                    />
                  </>
                )}
                <Img
                  source={{ uri: scoreBaseURL + data.teamAImage }}
                  style={styles.indIcon}
                />
                {isTeamABetting ? (
                  <>
                    <Typography
                      title={
                        data.score.teams.teamA.run +
                        "-" +
                        data.score.teams.teamA.wickets
                      }
                      style={styles.name}
                    />
                    <Typography
                      title={`(${data.score.teams.teamA.over})`}
                      style={{ fontSize: scale(9), marginLeft: scale(5) }}
                    />
                  </>
                ) : (
                  <Typography
                    title="Yet to Bet"
                    style={{ marginLeft: scale(5) }}
                  />
                )}
              </View>
              {/* team b */}
              <View
                style={[
                  styles.team,
                  {
                    opacity:
                      data.status == "Completed" && data.winner == data.teamA
                        ? 0.5
                        : 1,
                  },
                ]}
              >
                {data.status === "Completed" && data.winner == data.teamB && (
                  <>
                    <Img
                      source={icons.trophy}
                      style={{
                        width: scale(15),
                        marginRight: scale(5),
                        tintColor: colors.yellow,
                        marginRight: scale(10),
                      }}
                    />
                  </>
                )}
                <Img
                  source={{ uri: scoreBaseURL + data.teamBImage }}
                  style={styles.indIcon}
                />
                {isTeamBBetting ? (
                  <>
                    <Typography
                      title={
                        data.score.teams.teamB.run +
                        "-" +
                        data.score.teams.teamB.wickets
                      }
                      style={styles.name}
                    />
                    <Typography
                      title={`(${data.score.teams.teamB.over})`}
                      style={{ fontSize: scale(9), marginLeft: scale(5) }}
                    />
                  </>
                ) : (
                  <Typography
                    title="Yet to Bet"
                    style={{ marginLeft: scale(5) }}
                  />
                )}
              </View>
            </View>
            {data.status === "Live" && (
              <View style={styles.live}>
                <View style={styles.liveIcon} />
                <Typography title="Live" style={styles.liveText} />
              </View>
            )}
            <View style={[styles.live, { marginTop: verticalScale(3) }]}>
              {data.note && (
                <Typography
                  title={
                    data.status === "Completed"
                      ? `${
                          data.teamA == data.winner
                            ? data.teamAShortName
                            : data.teamBShortName
                        } won by ${
                          data.teamA == data.winner
                            ? data.score.teams.teamA.run -
                              data.score.teams.teamB.run
                            : data.score.teams.teamB.run -
                              data.score.teams.teamA.run
                        } Runs`
                      : runrate > 0 && data.eveningChanged == 0
                      ? `CRR ${runrate}`
                      : data.note
                  }
                  style={{
                    ...styles.liveText,
                    ...(data.status === "Completed" && {
                      color: colors.yellow,
                    }),
                  }}
                />
              )}
            </View>
          </View>

          <View style={styles.bating}>
            <View style={styles.ls}>
              {/* player a */}
              {data.score.players.playerA && (
                <View style={styles.team}>
                  <View
                    style={{
                      flexDirection: "row",
                      width: scale(100),
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      title={data.playerAShortName}
                      style={
                        data.striker === data.playerA
                          ? styles.playerName
                          : styles.playerTwo
                      }
                      lines={1}
                    />
                    {data.striker === data.playerA && (
                      <Img source={icons.batBall} style={styles.batBall} />
                    )}
                  </View>
                  <Typography
                    title={`${data.score.players.playerA.run} (${data.score.players.playerA.ball})`}
                    style={
                      data.striker === data.playerA
                        ? styles.playerName
                        : styles.playerScore
                    }
                    lines={1}
                  />
                </View>
              )}
              {/* plyer B  */}
              {data.score.players.playerB && (
                <View style={styles.team}>
                  <View
                    style={{
                      flexDirection: "row",
                      width: scale(100),
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      title={data.playerBShortName}
                      style={
                        data.striker === data.playerB
                          ? styles.playerName
                          : styles.playerTwo
                      }
                      lines={1}
                    />
                    {data.striker === data.playerB && (
                      <Img source={icons.batBall} style={styles.batBall} />
                    )}
                  </View>
                  <Typography
                    title={`${data.score.players.playerB.run} (${data.score.players.playerB.ball})`}
                    style={
                      data.striker === data.playerB
                        ? styles.playerName
                        : styles.playerScore
                    }
                  />
                </View>
              )}
            </View>
            <View style={styles.rs}>
              {/* Bowler */}
              {data.score.players.bowler && (
                <View style={styles.overBall}>
                  <Typography
                    title={data.bowlerShortName}
                    style={{ ...styles.playerName }}
                    lines={1}
                  />
                  <Typography
                    title={`${data.score.players.bowler.wicket}/${data.score.players.bowler.run} (${data.score.players.bowler.over})`}
                    style={styles.ball}
                  />
                </View>
              )}
              {data.bowlerShortName && (
                <ScrollView
                  horizontal
                  contentContainerStyle={styles.over}
                  showsHorizontalScrollIndicator={false}
                  style={{ width: scale(150) }}
                  contentOffset={{ x: bowlerResult.length * 50 }}
                >
                  {bowlerResult.slice(-10).map((item, i) => {
                    let result = item ? item.result : null;

                    let _i = null;

                    const extras = ["WD", "NB", "LB", "BY"];

                    if (result) {
                      result = result.split(" + ")[0];
                      _i = extras.includes(result) ? result : item.run;
                    }

                    return (
                      <Typography
                        title={!item ? "" : item.wicket == 1 ? "W" : _i}
                        style={{
                          ...styles[
                            !item
                              ? "sixBall"
                              : item.wicket === 1
                              ? "wicket"
                              : result === "Six"
                              ? "six"
                              : result === "Four"
                              ? "two"
                              : "one"
                          ],
                          fontSize: _i && _i.length > 1 ? scale(7) : scale(12),
                          paddingTop: scale(3),
                        }}
                      />
                    );
                  })}
                  {bowlerResult.length < 6 &&
                    Array(6 - bowlerResult.length)
                      .fill()
                      .map(() => <View style={styles.sixBall} />)}
                  {/* <Typography title="1" style={styles.one} />
                <Typography title="4" style={styles.two} />
                <Typography title="4" style={styles.one} />
                <Typography title="6" style={styles.six} />
                <Typography title="W" style={styles.wicket} /> */}
                  {/* <View style={styles.sixBall}></View> */}
                </ScrollView>
              )}
            </View>
          </View>
          <View style={styles.reting}>
            <TouchableOpacity
              style={[
                styles.upComing,
                {
                  opacity:
                    data.status !== "Completed" && x.teamALocked == 0 ? 1 : 0.4,
                },
              ]}
              onPress={() => {
                setSelectedTeam(data.teamA);
                setShowModal(true);
              }}
              disabled={data.status === "Completed" || x.teamALocked == 1}
            >
              <Typography title={data.teamAShortName} style={styles.ru} />
              <Typography
                title={`${parseFloat(x.teamA).toFixed(3)} `}
                style={styles.ru}
              />
              <Img
                source={
                  parseFloat(x.teamB).toFixed(3) <
                  parseFloat(x.teamA).toFixed(3)
                    ? icons.green
                    : icons.red
                }
                style={{
                  ...styles.upIcon,
                  ...{
                    marginBottom:
                      parseFloat(x.teamB).toFixed(3) >=
                      parseFloat(x.teamA).toFixed(3)
                        ? 0
                        : 2,
                  },
                }}
              />
            </TouchableOpacity>
            <Typography title="Select Your Win Team" style={styles.winTeam} />
            <TouchableOpacity
              style={[
                styles.upComing,
                {
                  opacity:
                    data.status !== "Completed" && x.teamBLocked == 0 ? 1 : 0.4,
                },
              ]}
              onPress={() => {
                setSelectedTeam(data.teamB);
                setShowModal(true);
              }}
              disabled={data.status === "Completed" || x.teamBLocked == 1}
            >
              <Typography title={data.teamBShortName} style={styles.ru} />
              <Typography
                title={`${parseFloat(x.teamB).toFixed(3)} `}
                style={styles.ru}
              />
              <Img
                source={
                  parseFloat(x.teamB).toFixed(3) >=
                  parseFloat(x.teamA).toFixed(3)
                    ? icons.green
                    : icons.red
                }
                style={{
                  ...styles.upIcon,
                  ...{
                    marginBottom:
                      parseFloat(x.teamB).toFixed(3) >=
                      parseFloat(x.teamA).toFixed(3)
                        ? 0
                        : 2,
                  },
                }}
              />
              <View
                style={{
                  position: "absolute",
                  zIndex: 99,
                }}
              ></View>
            </TouchableOpacity>
          </View>
        </>
      ) : (
        <View style={{ height: verticalScale(270) }} />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  teamMatch: {
    marginTop: verticalScale(25),
    borderBottomWidth: scale(1),
    borderBottomColor: colors.darkgrey,
    paddingHorizontal: scale(15),
    paddingBottom: verticalScale(8),
  },
  back: {
    width: scale(26),
    height: verticalScale(18),
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
  },
  countryName: {
    fontSize: scale(12),
    fontWeight: fonts._600,
    marginLeft: scale(20),
  },
  btn: {
    backgroundColor: colors.btn,
    width: scale(80),
    height: verticalScale(25),
    borderRadius: scale(200),
    justifyContent: "center",
    alignItems: "center",
    paddingTop: verticalScale(2),
    marginLeft: scale(10),
  },
  btnText: {
    fontSize: scale(10),
    fontWeight: fonts._600,
  },
  raper: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  india: {
    fontWeight: fonts._600,
    fontSize: scale(12),
    color: colors.lightgrey,
  },
  stateName: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: verticalScale(20),
  },
  team: {
    flexDirection: "row",
    marginTop: verticalScale(7),
    alignItems: "center",
  },
  indIcon: {
    width: scale(20),
    height: scale(20),
    borderRadius: scale(50),
  },
  name: {
    marginLeft: scale(10),
    fontSize: scale(13),
    fontWeight: fonts._700,
    letterSpacing: scale(1),
  },
  matchTeam: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  usaIcon: {
    width: scale(20),
    height: scale(20),
    marginLeft: scale(5),
  },
  score: {
    color: colors.engGrey,
  },
  liveIcon: {
    backgroundColor: colors.engRed,
    width: scale(10),
    height: scale(10),
    borderRadius: scale(100),
  },
  liveText: {
    fontSize: scale(13),
    fontWeight: fonts._600,
    color: colors.engGrey,
    marginHorizontal: scale(5),
  },
  live: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    // marginTop: verticalScale(0),
  },
  bating: {
    marginHorizontal: scale(15),
    marginTop: verticalScale(15),
    flexDirection: "row",
    justifyContent: "space-between",
  },
  playerName: {
    fontSize: scale(13),
    fontWeight: fonts._500,
    maxwidth: scale(60),
  },
  batBall: {
    width: scale(15),
    height: scale(15),
    marginLeft: scale(7),
  },
  playerScore: {
    color: colors.darkgrey,
    fontSize: scale(13),
    fontWeight: fonts._500,
  },
  playerTwo: {
    color: colors.darkgrey,
    fontSize: scale(13),
    fontWeight: fonts._500,
    maxwidth: scale(60),
  },
  ball: {
    fontSize: scale(13),
    fontWeight: fonts._500,
    marginLeft: "auto",
  },
  overBall: {
    flexDirection: "row",
    marginTop: verticalScale(7),
  },
  over: {
    flexDirection: "row",
    marginTop: verticalScale(7),
  },
  one: {
    backgroundColor: colors.grey,
    width: scale(22),
    height: scale(22),
    borderRadius: scale(100),
    fontSize: scale(11),
    textAlign: "center",
    alignItems: "center",
    fontWeight: fonts._600,
    marginLeft: scale(7),
  },
  two: {
    backgroundColor: colors.btn,
    width: scale(22),
    height: scale(22),
    borderRadius: scale(100),
    fontSize: scale(11),
    textAlign: "center",
    alignItems: "center",
    fontWeight: fonts._600,
    marginLeft: scale(7),
  },
  six: {
    backgroundColor: colors.engGreen,
    width: scale(22),
    height: scale(22),
    borderRadius: scale(100),
    fontSize: scale(11),
    textAlign: "center",
    alignItems: "center",
    fontWeight: fonts._600,
    marginLeft: scale(7),
  },
  wicket: {
    backgroundColor: colors.red,
    width: scale(22),
    height: scale(22),
    borderRadius: scale(100),
    fontSize: scale(11),
    textAlign: "center",
    alignItems: "center",
    fontWeight: fonts._600,
    marginLeft: scale(7),
  },
  sixBall: {
    backgroundColor: colors.bg,
    width: scale(22),
    height: scale(22),
    borderRadius: scale(100),
    marginLeft: scale(7),
    borderWidth: scale(1),
    borderColor: colors.white,
    borderStyle: "dashed",
  },
  ru: {
    marginHorizontal: 4,
    fontWeight: fonts._600,
    fontSize: scale(9),
  },
  upComing: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#060E24",
    borderRadius: scale(20),
    borderWidth: scale(1),
    borderColor: "#FFFFFF66",
    width: scale(95),
    height: scale(30),
    justifyContent: "center",
    marginHorizontal: scale(10),
    paddingTop: scale(2),
    position: "relative",
  },
  upIcon: {
    width: scale(8),
    height: scale(11),
    marginBottom: scale(5),
    // marginLeft: scale(2),
  },
  reting: {
    marginHorizontal: scale(10),
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: verticalScale(15),
    alignItems: "center",
    backgroundColor: colors.primary,
    paddingVertical: verticalScale(7),
    borderRadius: scale(200),
  },
  winTeam: {
    fontSize: scale(8),
    fontWeight: fonts._500,
    letterSpacing: scale(0.2),
  },
  pocketRupees: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: colors.primary,
    paddingHorizontal: scale(10),
    paddingVertical: verticalScale(3),
    paddingTop: verticalScale(5),
    justifyContent: "center",
    borderRadius: scale(100),
    height: verticalScale(26.5),
  },
  pocket: {
    width: scale(12),
    height: scale(12),
  },
  add: {
    width: scale(11),
    height: scale(11),
  },
  rupees: {
    paddingHorizontal: scale(5),
    fontSize: scale(10),
  },
});

export default MatchDetails;
