import {
  View,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  ScrollView,
  Dimensions,
} from "react-native";
import Typography from "./typography";
import colors from "../contants/colors";
import { scale, verticalScale } from "react-native-size-matters";
import { useEffect, useState } from "react";
import api, { apiBaseURL } from "../functions/api";
import BetConfirm from "./betConfirm";
import { useSelector } from "react-redux";
import toast from "../functions/toast";
import Loader from "./loader";
import Placeholder from "./placeholder";
import moment from "moment";
import { io } from "socket.io-client";

const Sessions = ({ matchData, x }) => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [timer, setTimer] = useState(0);

  const user = useSelector((s) => s.user.value);

  const getSessions = async () => {
    try {
      let data = await api.get("/sessions/" + matchData._id);

      const fancy = data.filter((i) => i._type == 1);

      data = data.filter((i) => i._type != 1);

      const preTeam =
        matchData.score.teams.teamA.run > matchData.score.teams.teamB.run
          ? "teamA"
          : "teamB";

      const target =
        preTeam === "teamA"
          ? matchData.score.teams.teamA.run
          : matchData.score.teams.teamB.run;

      data =
        matchData.score.teams.teamA.over > 0 &&
        matchData.score.teams.teamB.over > 0
          ? data.filter((i) => i.min <= target)
          : data;

      if (
        matchData.score.teams[
          matchData.teamA == matchData.bettingTeam ? "teamA" : "teamB"
        ].wickets >= 7
      ) {
        data = data.filter(
          (i) => i._over <= parseFloat(matchData._over || 0) + 2
        );
      }

      setData([...data, ...fancy]);

      setShowModal(false);
    } catch (err) {
      console.log("🚀 ~ getSessions ~ err:", err);
    }
    setLoading(false);
    setShowLoader(false);
  };

  const addBet = async (amount) => {
    if (data.find((item) => item.sessionID == selected.sessionID).gstatus)
      return toast("Cannot place bet");

    setShowLoader(true);
    try {
      await api.post("/add-session-bet", {
        user,
        session: selected._id,
        _over: selected._over,
        amount,
        yesorno: selected.yesorno,
        number: selected.number,
        _match: matchData._id,
        _type: selected._type || 0,
        question: selected.question,
        sessionID: selected.sessionID,
        x: selected.x,
        srno: selected.srno,
      });
      toast("bet added successfully");
    } catch (err) {
      console.log("🚀 ~ addBet ~ err:", err);
    }
    setShowModal(false);
    setShowLoader(false);
  };

  // useEffect(() => {
  //   if (matchData) {
  //     getSessions();
  //   }
  // }, [matchData]);

  // useEffect(() => {
  //   if (x) {
  //     const i = setInterval(() => {
  //       const utcFormat = "YYYY-MM-DDTHH:mm:ss.SSSZ";
  //       const utcDate = moment.utc(new Date(x.time), utcFormat);
  //       const istDate = utcDate.local();
  //       const duration = moment.duration(istDate.diff(moment()));
  //       const timer = Math.floor(duration.asSeconds());

  //       timer <= 0 && setShowModal(false);

  //       setTimer(timer <= 0 ? 0 : timer);
  //     }, 1000);
  //     return () => clearInterval(i);
  //   }
  // }, [x]);

  useEffect(() => {
    if (matchData) {
      const socket = io(apiBaseURL);
      socket.on("sessions-" + matchData._id, setData);
    }
  }, [matchData]);

  return (
    <View style={styles.container}>
      {data.length === 0 ? (
        <Placeholder padding={scale(15)} />
      ) : (
        <>
          <Loader show={showLoader} />
          <BetConfirm
            show={showModal}
            hideModal={() => setShowModal(false)}
            onChange={addBet}
            selected={selected && selected.amount}
            x={selected && selected.x}
            min={100}
            max={50000}
            val={"100"}
          />
          <ScrollView
            style={{
              paddingHorizontal: scale(10),
              height: verticalScale(430),
            }}
          >
            <FlatList
              data={data.filter((i) => i._type != 1)}
              style={{ marginBottom: verticalScale(20) }}
              showsVerticalScrollIndicator={false}
              keyExtractor={(item) => item._id}
              renderItem={({ item }) => (
                <View style={styles.ovRuns}>
                  <View>
                    <Typography title={item.question} style={styles.bats} />
                    <View
                      style={[
                        styles.overBats,
                        x.sessionsBlocked == 1 && { opacity: 0.5 },
                      ]}
                    >
                      <TouchableOpacity
                        style={[styles.boxOne]}
                        disabled={item.gstatus || x.sessionsBlocked == 1}
                        onPress={() => {
                          setSelected({
                            ...item,
                            yesorno: 0,
                            number: item.min,
                            _type: 0,
                            x: item.minX,
                            sessionsID: item.sessionID,
                            question: item.question,
                            srno: item.srno,
                          });
                          setShowModal(true);
                        }}
                      >
                        <Typography title={item.min} style={styles.run} />
                        <Typography
                          title={item.minX.toFixed(2) + "x"}
                          style={styles.rupees}
                        />
                        <Typography
                          title="No"
                          style={{
                            ...styles.rupees,
                            textAlign: "left",
                            marginTop: verticalScale(-12),
                          }}
                        />
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={[styles.boxTwo]}
                        disabled={item.gstatus || x.sessionsBlocked == 1}
                        onPress={() => {
                          setSelected({
                            ...item,
                            yesorno: 1,
                            number: item.max,
                            _type: 0,
                            x: item.maxX,
                            sessionsID: item.sessionID,
                            question: item.question,
                            srno: item.srno,
                          });
                          setShowModal(true);
                        }}
                      >
                        <Typography title={item.max} style={styles.run} />
                        <Typography
                          title={item.maxX.toFixed(2) + "x"}
                          style={styles.rupees}
                        />
                        <Typography
                          title="Yes"
                          style={{
                            ...styles.rupees,
                            textAlign: "left",
                            marginTop: verticalScale(-12),
                          }}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                  <View style={{ marginTop: verticalScale(15) }}>
                    <Typography
                      title={`Min - 100\nMax - 50K`}
                      style={styles.minMax}
                    />
                  </View>
                  {item.gstatus && (
                    <View
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "rgba(231, 76, 60, 0.7)",
                        zIndex: 99,
                        borderRadius: scale(6),
                      }}
                    >
                      <Typography title={item.gstatus} />
                    </View>
                  )}
                </View>
              )}
            />
            {/* -------------------------Fancy------------------------- */}
            {/* <Typography
              title="Fancy Sessions"
              style={{ marginBottom: verticalScale(5) }}
            /> */}
            {/* <FlatList
              data={data.filter((i) => i._type == 1)}
              style={{ marginBottom: verticalScale(20) }}
              showsVerticalScrollIndicator={false}
              keyExtractor={(item) => item._id}
              renderItem={({ item }) => (
                <View style={styles.ovRuns}>
                  <View>
                    <Typography title={item.question} style={styles.bats} />
                    <View style={[styles.overBats]}>
                      <TouchableOpacity
                        style={[styles.boxOne]}
                        disabled={
                          x.sessionsBlocked == 1 ||
                          item.gstatus ||
                          item.min == 0
                        }
                        onPress={() => {
                          setSelected({
                            ...item,
                            yesorno: 0,
                            number: item.min,
                            _type: 0,
                          });
                          setShowModal(true);
                        }}
                      >
                        <Typography
                          title={item.min == 0 ? "--" : item.min}
                          style={styles.run}
                        />
                        <Typography title="2.00x" style={styles.rupees} />
                        <Typography
                          title="No"
                          style={{
                            ...styles.rupees,
                            textAlign: "left",
                            marginTop: verticalScale(-8),
                          }}
                        />
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={[styles.boxTwo]}
                        disabled={
                          x.sessionsBlocked == 1 ||
                          item.gstatus ||
                          item.max == 0
                        }
                        onPress={() => {
                          setSelected({
                            ...item,
                            yesorno: 1,
                            number: item.max,
                            _type: 0,
                          });
                          setShowModal(true);
                        }}
                      >
                        <Typography title={item.max} style={styles.run} />
                        <Typography title="2.00x" style={styles.rupees} />
                        <Typography
                          title="Yes"
                          style={{
                            ...styles.rupees,
                            textAlign: "left",
                            marginTop: verticalScale(-8),
                          }}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                  <View>
                    <Typography
                      title={`Min - 100\nMax - 50K`}
                      style={styles.minMax}
                    />
                  </View>
                  {item.gstatus && (
                    <View
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "rgba(231, 76, 60, 0.8)",
                        zIndex: 99,
                        borderRadius: scale(6),
                      }}
                    >
                      <Typography title={item.gstatus} />
                    </View>
                  )}
                </View>
              )}
            /> */}
          </ScrollView>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    // paddingHorizontal: scale(10),
  },
  ovRuns: {
    flexDirection: "row",
    backgroundColor: colors.primary,
    width: "100%",
    height: scale(64),
    borderRadius: scale(5),
    marginTop: scale(10),
    alignItems: "center",
    padding: scale(10),
    justifyContent: "space-between",
    position: "relative",
  },
  minMax: {
    width: scale(65),
    fontSize: scale(10),
    opacity: 0.5,
  },
  bats: {
    fontSize: scale(9.5),
    marginBottom: scale(5),
    letterSpacing: scale(0.5),
  },
  overBats: {
    flexDirection: "row",
    alignItems: "center",
  },
  boxOne: {
    backgroundColor: colors.engRed,
    height: scale(24),
    width: scale(84),
    borderRadius: scale(5),
    alignItems: "center",
    padding: scale(3),
  },
  boxTwo: {
    backgroundColor: colors.btn,
    marginLeft: scale(10),
    height: scale(24),
    width: scale(84),
    borderRadius: scale(5),
    alignItems: "center",
    padding: scale(3),
  },
  run: {
    fontSize: scale(10),
    marginTop: verticalScale(3),
  },
  rupees: {
    fontSize: scale(7),
    marginTop: scale(-4),
    textAlign: "right",
    width: "100%",
  },
  flatList: {
    paddingBottom: scale(150),
  },
});

export default Sessions;
