import { Dimensions, StyleSheet } from "react-native";

import colors from "../../../contants/colors";
import fonts from "../../../contants/fonts";

import { scale, verticalScale } from "react-native-size-matters";

const { height } = Dimensions.get("window");

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.bg,
    width: "100%",
    minHeight: "100%",
    paddingHorizontal: scale(10),
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: scale(10),
  },
  backScreen: {
    flexDirection: "row",
    alignItems: "center",
  },
  back: {
    width: scale(25),
    height: scale(18),
  },
  headerText: {
    fontSize: scale(17),
    marginLeft: scale(15),
  },
  userWallet: {
    backgroundColor: colors.primary,
    minHeight: height - verticalScale(35),
    paddingHorizontal: scale(15),
    borderRadius: scale(10),
    marginTop: scale(15),
  },
  userPhoto: {
    backgroundColor: colors.yellow,
    fontSize: scale(18),
    fontWeight: fonts._700,
    borderRadius: scale(200),
    color: colors.black,
    paddingVertical: scale(10),
    paddingHorizontal: scale(15),
  },
  userProfile: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: scale(15),
  },
  userName: {
    fontSize: scale(18),
    marginLeft: scale(20),

    fontWeight: fonts._700,
  },
  userNum: {
    fontSize: scale(11),
    marginLeft: scale(10),
  },
  userKyc: {
    backgroundColor: colors.bg,
    borderWidth: 1,
    borderColor: colors.engGreen,
    borderRadius: scale(10),
    marginTop: scale(20),
    height: scale(75),
  },
  userBalance: {
    textAlign: "center",
    fontSize: scale(15),
    fontWeight: fonts._700,
    marginTop: verticalScale(10),
  },
  rupees: {
    width: scale(17),
    height: scale(17),
    marginRight: scale(5),
  },
  rupeesText: {
    color: colors.engGreen,
    fontSize: scale(20),
    fontWeight: fonts._700,
  },
  userRupees: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: verticalScale(7.5),
  },
  tabIndex: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginVertical: scale(5),
    marginTop: verticalScale(20),
  },
  tabName: {
    fontSize: scale(13),
    backgroundColor: colors.bg,
    paddingHorizontal: scale(30),
    paddingVertical: scale(3),
    borderRadius: scale(10),
    borderWidth: 1,
    borderColor: colors.btn,
    paddingVertical: scale(7),
  },
  recharge: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  rechargePro: {
    flexDirection: "row",
    alignItems: "center",
  },
  walletPlus: {
    tintColor: colors.yellow,
    width: scale(30),
    height: scale(30),
  },
  statusRec: {
    backgroundColor: colors.bg,
    borderRadius: scale(10),
    marginTop: scale(10),
    paddingVertical: scale(10),
    paddingHorizontal: scale(15),
  },
  rcText: {
    fontWeight: fonts._600,
    fontSize: scale(13),
    marginLeft: scale(10),
  },
  status: {
    flexDirection: "row",
    alignItems: "center",
  },
  stText: {
    fontSize: scale(12),
    fontWeight: fonts._600,
  },
  Process: {
    color: colors.yellow,
    fontSize: scale(12),
    marginLeft: scale(7),
    fontWeight: fonts._600,
  },
  flatList: {
    paddingBottom: scale(120),
  },
});

export default styles;
