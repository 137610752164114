import {
  Button,
  Clipboard,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import Img from "../../../components/image";
import { scale, verticalScale } from "react-native-size-matters";
import { useNavigation } from "@react-navigation/native";
import Typography from "../../../components/typography";
import icons from "../../../contants/icons";
import Input from "../../../components/input";
import colors from "../../../contants/colors";
import toast from "../../../functions/toast";
import { useEffect, useState } from "react";
import api from "../../../functions/api";
import getUser from "../../../functions/getUser";
import Modal from "../../../components/modal";
import gifs from "../../../contants/gifs";

const Recharge = () => {
  const [amount, setAmount] = useState(null);
  const [ref, setRef] = useState(null);
  const [showModel, setShowModel] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const data = useSelector((s) => s.info.value);
  const user = useSelector((s) => s.user.value);

  const { goBack, navigate } = useNavigation();

  const { width, height } = useWindowDimensions();

  const dispatch = useDispatch();

  const submit = async () => {
    try {
      if (amount < 50) return toast("Enter minimum amount 50");
      else if (amount > 50000) return toast("Enter maximum amount 50000");

      setShowLoader(true);

      await api.post("/add-recharge", {
        user,
        amount,
        txnId: ref,
        txnRef: ref,
        type: "MANUAL",
      });

      setShowModel(true);

      getUser(user, dispatch);

      //   toast(`Recharge of ${amount} INR added successfully`);
    } catch (err) {
      console.log("🚀 ~ submit ~ err:", err);
    }
    setShowLoader(false);
  };

  useEffect(() => {
    if (showModel) {
      setTimeout(() => {
        setShowModel(false);
        navigate("WalletHistory", 0);
      }, 2000);
    }
  }, [showModel]);

  return (
    <View style={{ flex: 1, alignItems: "center", height: "100%" }}>
      <View
        style={{
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
          marginTop: verticalScale(15),
          paddingHorizontal: scale(20),
        }}
      >
        <TouchableOpacity onPress={goBack}>
          <Img
            source={icons.back}
            style={{
              marginRight: scale(20),
              marginLeft: scale(20),
              width: scale(25),
              height: scale(25),
            }}
          />
        </TouchableOpacity>
        <Typography
          title="Add Amount"
          style={{
            fontSize: scale(16),
          }}
        />
      </View>
      <Img
        source={{
          uri: `https://chart.googleapis.com/chart?cht=qr&chs=250x250&chl=upi://pay?pa=${data.upiID}&pn=Crickstox`,
        }}
        style={{
          width: scale(250),
          height: scale(250),
          borderRadius: scale(20),
          marginVertical: verticalScale(20),
        }}
      />
      <Typography title={"UPI Payment ID: " + data.upiID} />
      <TouchableOpacity
        onPress={() => {
          Clipboard.setString(data.upiID);
          toast("UPI ID Copied!");
        }}
        style={{
          backgroundColor: colors.primary,
          paddingHorizontal: scale(20),
          paddingVertical: verticalScale(10),
          borderRadius: scale(7),
          marginBottom: verticalScale(5),
          marginTop: verticalScale(10),
        }}
      >
        <Typography title="COPY UPI ID" />
      </TouchableOpacity>
      <View
        style={{
          flexDirection: "row",
          width: width - scale(40),
          marginVertical: verticalScale(10),
        }}
      >
        <Typography
          title="Copy UPI ID or Scane QR Code & send recharge amount, after send amount submit your recharge details below"
          style={{
            fontSize: scale(11),
            lineHeight: verticalScale(18),
            textAlign: "center",
          }}
        />
      </View>
      <View>
        <Input
          label="Amount"
          placeholder="Enter minimum Amount Rs. 100"
          style={{
            marginVertical: verticalScale(15),
            width: width - scale(40),
          }}
          value={amount}
          onChange={(val) => {
            if (val === "" || val.match(/^[1-9]\d*$/)) setAmount(val);
          }}
          max={5}
        />
        <Input
          label="UPI Ref Number"
          placeholder="Enter UPI Ref Number"
          style={{
            marginVertical: verticalScale(15),
            width: width - scale(40),
          }}
          value={ref}
          onChange={setRef}
        />
        <TouchableOpacity
          style={{
            paddingVertical: verticalScale(10),
            paddingHorizontal: scale(10),
            backgroundColor: colors.btn,
            width: scale(150),
            alignItems: "center",
            borderRadius: scale(5),
            marginHorizontal: "auto",
            marginTop: verticalScale(10),
            opacity: !ref || !amount ? 0.5 : 1,
          }}
          disabled={!ref || !amount}
          onPress={submit}
        >
          <Typography title="Submit Recharge" style={{ fontSize: scale(13) }} />
        </TouchableOpacity>
      </View>
      <Modal isVisible={showModel} backdropOpacity={0.7}>
        <View
          style={{
            width: "100%",
            height: height / 2,
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            borderRadius: scale(20),
          }}
        >
          <Img
            source={gifs.suc}
            style={{
              width: scale(400),
              height: scale(400),
            }}
          />
        </View>
      </Modal>
    </View>
  );
};

export default Recharge;
