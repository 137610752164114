import { useEffect, useState } from "react";
import {
  View,
  TouchableOpacity,
  FlatList,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import { scale, verticalScale } from "react-native-size-matters";
import { useSelector } from "react-redux";

import Typography from "../../../components/typography";
import Img from "../../../components/image";

import styles from "./styles";

import icons from "../../../contants/icons";
import colors from "../../../contants/colors";
import Header from "../../../components/header";

import api, { scoreBaseURL } from "../../../functions/api";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import Loader from "../../../components/loader";
import groupBy from "lodash.groupby";
import axios from "axios";
import images from "../../../contants/images";
import orderBy from "lodash.orderby";
import Placeholder from "../../../components/placeholder";

const Contests = ({ match }) => {
  const [contests, setContests] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [showLoader, setShowLoader] = useState(true);
  const [isMatchLive, setIsMatchLive] = useState(null);

  const navigation = useNavigation();

  const { width } = useWindowDimensions();

  const isFocused = useIsFocused();

  const user = useSelector((s) => s.user.value);

  const getMyContests = async () => {
    setShowLoader(true);
    try {
      const contests = await api.get(
        `/my-contests?match=${match}&user=${user}`
      );
      setContests(contests);
    } catch (err) {
      console.log("🚀 ~ file: index.js:27 ~ getContests ~ err:", err);
    }
    setShowLoader(false);
  };

  const getContests = async () => {
    setShowLoader(true);
    try {
      const contests = await api.get(`/new-contests/${match}?user=${user}`);

      setContests(
        contests.filter(
          (i) =>
            (i.users[0] ? i.users[0].user !== user : true) &&
            (i.users[1] ? i.users[1].user !== user : true)
        )
      );
    } catch (err) {
      console.log("🚀 ~ file: index.js:27 ~ getContests ~ err:", err);
    }
    setShowLoader(false);
  };

  const checkMatchStatus = async () => {
    // setShowLoader(true);
    try {
      const {
        data: { result },
      } = await axios.get(`${scoreBaseURL}/is-match-live/${match}`);
      setIsMatchLive(result);
    } catch (err) {
      console.log("🚀 ~ checkMatchStatus ~ err:", err);
    }
    // setShowLoader(false);
  };

  useEffect(() => {
    if (isFocused) {
      // setContests([]);
      tabIndex === 0 ? !isMatchLive && getContests() : getMyContests();
      checkMatchStatus();
    }
  }, [tabIndex, isFocused]);

  return (
    <View style={styles.container}>
      {!contests || showLoader || isMatchLive == null ? (
        <View style={{ marginTop: verticalScale(20) }}>
          <Placeholder
            heigh={verticalScale(150)}
            padding={scale(15)}
            item={5}
          />
        </View>
      ) : (
        <>
          <View style={[styles.tabIndex]}>
            <TouchableOpacity
              style={[
                styles.index,
                tabIndex === 0 && {
                  ...styles.indexActive,
                },
              ]}
              onPress={() => setTabIndex(0)}
            >
              <Typography
                style={[
                  styles.tabName,
                  tabIndex === 0 && {
                    ...{ color: colors.white },
                    ...{
                      backgroundColor: colors.btn,
                    },
                  },
                ]}
                title="Contests"
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                styles.index,
                tabIndex === 1 && {
                  ...styles.indexActive,
                },
              ]}
              onPress={() => setTabIndex(1)}
            >
              <Typography
                style={[
                  styles.tabName,
                  tabIndex === 1 && {
                    ...{ color: colors.white },
                    ...{
                      backgroundColor: colors.btn,
                    },
                  },
                ]}
                title="My Contest's"
              />
            </TouchableOpacity>
          </View>
          <View style={styles.contests}>
            <Typography
              title="Player Vs Player Contests"
              style={{ marginLeft: scale(10) }}
            />
            {tabIndex === 1 ? (
              <FlatList
                data={contests}
                showsVerticalScrollIndicator={false}
                contentContainerStyle={styles.flatList}
                renderItem={({ item, index }) => {
                  return (tabIndex === 1 ? item.contInfo : true) ? (
                    <View
                      style={{
                        width,
                        alignItems: "center",
                        paddingHorizontal: scale(10),
                      }}
                    >
                      <View style={styles.headToHead}>
                        <View style={styles.teamVs}>
                          <View style={styles.titel}>
                            <Typography
                              title="Prize Pool"
                              style={styles.pool}
                            />
                            <View style={styles.entry}>
                              <Img
                                source={icons.rupees}
                                style={styles.rupees}
                              />
                              <Typography title="Entry" style={styles.pool} />
                            </View>
                          </View>
                          <View style={styles.titel}>
                            <View style={styles.entry}>
                              <Img
                                source={icons.rupees}
                                style={styles.rupeesIcon}
                              />
                              <Typography
                                title={item.contInfo.pricePool}
                                style={styles.text}
                              />
                            </View>
                            <TouchableOpacity
                              onPress={() =>
                                navigation.navigate("Players", {
                                  contest: item,
                                  match: item._match,
                                  contestPrice: item.entryFee,
                                })
                              }
                              disabled={tabIndex === 1}
                            >
                              <Typography
                                title={item.contInfo.entryFee}
                                style={styles.money}
                              />
                            </TouchableOpacity>
                          </View>
                          <View style={styles.border}>
                            <View
                              style={[
                                styles.twoBorder,
                                {
                                  width:
                                    parseFloat(
                                      tabIndex === 0
                                        ? item.spot - item.spotLeft
                                        : item.contInfo.spot -
                                            item.contInfo.spotLeft
                                    ) *
                                      50 +
                                    "%",
                                },
                              ]}
                            />
                          </View>
                          <View style={styles.playersAdd}>
                            <View>
                              {item.oppPlayer ? (
                                <>
                                  <Typography
                                    title={
                                      (tabIndex === 0
                                        ? item.spotLeft
                                        : item.contInfo.spotLeft) == 0
                                        ? "Contest Full"
                                        : `${
                                            tabIndex === 0
                                              ? item.spotLeft
                                              : item.contInfo.spotLeft
                                          } Spot Left`
                                    }
                                    style={{
                                      ...styles.spot,
                                      ...((tabIndex === 0
                                        ? item.spotLeft
                                        : item.contInfo.spotLeft) == 0 && {
                                        color: colors.engGreen,
                                      }),
                                    }}
                                  />
                                  <Typography
                                    title="Opponent Player"
                                    style={styles.player}
                                  />
                                  <Typography
                                    title={item.oppPlayer.fullName}
                                    style={styles.name}
                                  />
                                  <Img
                                    source={{
                                      uri: scoreBaseURL + item.oppPlayer.image,
                                    }}
                                    style={styles.rohit}
                                  />
                                </>
                              ) : (
                                <>
                                  <Img
                                    source={images.user}
                                    style={{
                                      width: scale(50),
                                      height: scale(50),
                                      marginBottom: verticalScale(5),
                                    }}
                                  />
                                  <Typography title="Waiting" />
                                </>
                              )}
                            </View>
                            <Typography title="VS" style={styles.vs} />
                            <View style={{ alignItems: "flex-end" }}>
                              <Typography
                                title="2 Spots"
                                style={styles.spots}
                              />
                              <Typography
                                title="Your Player"
                                style={styles.player}
                              />
                              {tabIndex === 0 ? (
                                <TouchableOpacity
                                  onPress={() =>
                                    navigation.navigate("Players", {
                                      contest: item,
                                      match: item._match,
                                      contestPrice: item.entryFee,
                                    })
                                  }
                                >
                                  <Img
                                    source={icons.add}
                                    style={{
                                      tintColor: "#fff",
                                      width: scale(30),
                                      height: scale(30),
                                    }}
                                  />
                                </TouchableOpacity>
                              ) : (
                                <>
                                  {item.myPlayer && (
                                    <>
                                      <Typography
                                        title={item.myPlayer.fullName}
                                        style={styles.name}
                                      />
                                      <TouchableOpacity
                                        disabled={isMatchLive || item.edit == 0}
                                        onPress={() => {
                                          navigation.navigate("Players", {
                                            contestID: item._id,
                                            contest: item._id,
                                            match: item.contInfo._match,
                                            contestPrice:
                                              item.contInfo.entryFee,
                                          });
                                        }}
                                        style={{ position: "relative" }}
                                      >
                                        <Img
                                          source={{
                                            uri:
                                              scoreBaseURL +
                                              item.myPlayer.image,
                                          }}
                                          style={styles.rohit}
                                        />
                                        {!isMatchLive && item.edit == 1 && (
                                          <Img
                                            source={icons.editPlayer}
                                            style={{
                                              width: scale(50),
                                              height: scale(50),
                                              position: "absolute",
                                              top: 0,
                                              left: 0,
                                            }}
                                          />
                                        )}
                                      </TouchableOpacity>
                                    </>
                                  )}
                                </>
                              )}
                            </View>
                          </View>
                        </View>
                        {item.contInfo.status == 1 && (
                          <>
                            <Typography
                              title="Contest is not fullfill"
                              style={{
                                width: "100%",
                                textAlign: "center",
                                marginTop: verticalScale(-10),
                                color: colors.engRed,
                                fontSize: scale(12),
                              }}
                            />
                            <Typography
                              title="Amount has been refunded to your wallet"
                              style={{
                                marginBottom: verticalScale(10),
                                width: "100%",
                                textAlign: "center",
                                marginTop: verticalScale(2),
                                color: colors.yellow,
                                fontSize: scale(10),
                              }}
                            />
                          </>
                        )}
                        <View style={styles.bottam}>
                          <View style={styles.winImg}>
                            <Img
                              source={icons.worldCup}
                              style={styles.worldCup}
                            />
                            <Typography title="50%" style={styles.entery} />
                            <Img source={icons.single} style={styles.single} />
                            <Typography
                              title="Single Entry"
                              style={styles.entery}
                            />
                            <Img source={icons.first} style={styles.single} />
                            <Typography
                              title={
                                "1st " +
                                (tabIndex === 0
                                  ? item.pricePool
                                  : item.contInfo.pricePool)
                              }
                              style={styles.entery}
                            />
                          </View>
                          {/* <View>
                  <Typography title={"Winning: +310"} style={styles.bottamText} />
                </View> */}
                        </View>
                      </View>
                    </View>
                  ) : (
                    <></>
                  );
                }}
              />
            ) : (
              <ScrollView
                showsVerticalScrollIndicator={false}
                horizontal={false}
                contentContainerStyle={styles.flatList}
              >
                {Object.values(groupBy(contests, "entryFee")).map(
                  (_item, index) => (
                    <FlatList
                      data={orderBy(_item, "spotLeft")}
                      style={{ marginVertical: verticalScale(5) }}
                      showsHorizontalScrollIndicator={false}
                      horizontal
                      pagingEnabled
                      renderItem={({ item, index }) => {
                        return (tabIndex === 1 ? item.contInfo : true) ? (
                          <View
                            style={{
                              width,
                              alignItems: "center",
                            }}
                          >
                            <View
                              style={[
                                styles.headToHead,
                                { width: width - scale(20) },
                              ]}
                            >
                              <View style={styles.teamVs}>
                                <View style={styles.titel}>
                                  <Typography
                                    title="Prize Pool"
                                    style={styles.pool}
                                  />
                                  <View style={styles.entry}>
                                    <Img
                                      source={icons.rupees}
                                      style={styles.rupees}
                                    />
                                    <Typography
                                      title="Entry"
                                      style={styles.pool}
                                    />
                                  </View>
                                </View>
                                <View style={styles.titel}>
                                  <View style={styles.entry}>
                                    <Img
                                      source={icons.rupees}
                                      style={styles.rupeesIcon}
                                    />
                                    <Typography
                                      title={item.pricePool}
                                      style={styles.text}
                                    />
                                  </View>
                                  <TouchableOpacity
                                    onPress={() =>
                                      navigation.navigate("Players", {
                                        contest: item,
                                        match: item._match,
                                        contestsPrice: item.entryFee,
                                      })
                                    }
                                    disabled={tabIndex === 1}
                                  >
                                    <Typography
                                      title={item.entryFee}
                                      style={styles.money}
                                    />
                                  </TouchableOpacity>
                                </View>
                                <View style={styles.border}>
                                  <View
                                    style={[
                                      styles.twoBorder,
                                      {
                                        width:
                                          parseFloat(
                                            tabIndex === 0
                                              ? item.spot - item.spotLeft
                                              : item.contInfo.spot -
                                                  item.contInfo.spotLeft
                                          ) *
                                            50 +
                                          "%",
                                      },
                                    ]}
                                  />
                                </View>
                                <View style={styles.playersAdd}>
                                  <View>
                                    {item.oppPlayer ? (
                                      <>
                                        <Typography
                                          title="Opponent Player"
                                          style={styles.player}
                                        />
                                        <Typography
                                          title={item.oppPlayer.fullName}
                                          style={styles.name}
                                        />
                                        <Img
                                          source={{
                                            uri:
                                              scoreBaseURL +
                                              item.oppPlayer.image,
                                          }}
                                          style={styles.rohit}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <Typography title="Waiting" />
                                      </>
                                    )}
                                    <Typography
                                      title={`${
                                        tabIndex === 0
                                          ? item.spotLeft
                                          : item.contInfo.spotLeft
                                      } Spot Left`}
                                      style={{
                                        ...styles.spot,
                                        ...((tabIndex === 0
                                          ? item.spotLeft
                                          : item.contInfo.spotLeft) == 0 && {
                                          color: colors.engGreen,
                                        }),
                                      }}
                                    />
                                  </View>
                                  <Typography title="VS" style={styles.vs} />
                                  <View style={{ alignItems: "flex-end" }}>
                                    <Typography
                                      title="2 Spots"
                                      style={styles.spots}
                                    />
                                    <Typography
                                      title="Your Player"
                                      style={styles.player}
                                    />
                                    {tabIndex === 0 ? (
                                      <TouchableOpacity
                                        onPress={() =>
                                          navigation.navigate("Players", {
                                            contest: item,
                                            match: item._match,
                                          })
                                        }
                                      >
                                        <Img
                                          source={icons.add}
                                          style={{
                                            tintColor: "#fff",
                                            width: scale(30),
                                            height: scale(30),
                                            marginTop: verticalScale(5),
                                          }}
                                        />
                                      </TouchableOpacity>
                                    ) : (
                                      <>
                                        {item.myPlayer && (
                                          <>
                                            <Typography
                                              title={item.myPlayer.fullName}
                                              style={styles.name}
                                            />
                                            <TouchableOpacity
                                              disabled={item.edit == 0}
                                              onPress={() => {
                                                navigation.navigate("Players", {
                                                  contestID:
                                                    item.users &&
                                                    item.users.find(
                                                      (i) => i.user == user
                                                    )._id,
                                                  contest: item._id,
                                                  match: item._match,
                                                  contestPrice: item.entryFee,
                                                });
                                              }}
                                            >
                                              <Img
                                                source={{
                                                  uri:
                                                    scoreBaseURL +
                                                    item.myPlayer.image,
                                                }}
                                                style={styles.rohit}
                                              />
                                            </TouchableOpacity>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </View>
                                </View>
                              </View>
                              <View style={styles.bottam}>
                                <View style={styles.winImg}>
                                  <Img
                                    source={icons.worldCup}
                                    style={styles.worldCup}
                                  />
                                  <Typography
                                    title="50%"
                                    style={styles.entery}
                                  />
                                  <Img
                                    source={icons.single}
                                    style={styles.single}
                                  />
                                  <Typography
                                    title="Single Entry"
                                    style={styles.entery}
                                  />
                                  <Img
                                    source={icons.first}
                                    style={styles.single}
                                  />
                                  <Typography
                                    title={
                                      "1st " +
                                      (tabIndex === 0
                                        ? item.pricePool
                                        : item.contInfo.pricePool)
                                    }
                                    style={styles.entery}
                                  />
                                </View>
                                {/* <View>
                  <Typography title={"Winning: +310"} style={styles.bottamText} />
                </View> */}
                              </View>
                            </View>
                          </View>
                        ) : (
                          <></>
                        );
                      }}
                    />
                  )
                )}
              </ScrollView>
            )}
          </View>
        </>
      )}
    </View>
  );
};

export default Contests;
