import {
  View,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  RefreshControl,
  ActivityIndicator,
  Dimensions,
} from "react-native";

import Typography from "./typography";
import { scale, verticalScale } from "react-native-size-matters";
import Img from "./image";

import icons from "../contants/icons";
import colors from "../contants/colors";
import api from "../functions/api";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "./loader";
import Placeholder from "./placeholder";

const RunrateScalping = ({ all }) => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(true);
  const [showLoader, setShowLoader] = useState(true);

  const user = useSelector((s) => s.user.value);

  const getData = async (_page) => {
    try {
      const res = await api.get(
        `/run-rate-bet-list?numOfItem=${5}&page=${_page || page}&user=${user}`
      );
      res.length < 10 && setIsLoading(false);
      _page === 1 && setPage(_page);
      _page === 1 || page === 1 ? setData(res) : setData([...data, ...res]);
      _page === 1 && setIsLoading(true);
      res.length < 10 && setIsLoading(false);
    } catch (err) {
      console.log("🚀 ~ file: stakeBall.js:16 ~ getData ~ err:", err);
    }
    setRefreshing(false);
    setShowLoader(false);
  };

  useEffect(() => {
    getData();
  }, [page]);

  return (
    <>
      {showLoader ? (
        <Placeholder heigh={verticalScale(150)} item={5} padding={scale(15)} />
      ) : (
        <>
          {data.length > 0 && (
            <FlatList
              showsVerticalScrollIndicator={false}
              data={data}
              style={{
                height: Dimensions.get("window").height - verticalScale(220),
              }}
              contentContainerStyle={styles.flatList}
              onEndReached={() => setPage(page + 1)}
              refreshControl={
                <RefreshControl
                  refreshing={refreshing}
                  onRefresh={() => {
                    setRefreshing(true);
                    getData(1);
                  }}
                  colors={[colors.btn]}
                  tintColor={colors.btn}
                />
              }
              ListFooterComponent={() =>
                isLoading ? (
                  <ActivityIndicator
                    size={50}
                    color={colors.white}
                    style={{ marginVertical: verticalScale(20) }}
                  />
                ) : (
                  <></>
                )
              }
              renderItem={({ item, index }) => (
                <View style={styles.container}>
                  <View style={styles.right}>
                    <View style={styles.resuliUp}>
                      <Img
                        source={icons.arcticons_stockswidget}
                        style={styles.arcticons}
                      />
                      <Typography
                        title="Runrate Up & Down"
                        style={styles.upDown}
                      />
                    </View>

                    <Typography title="Result" style={styles.result} />
                    <Typography
                      title={
                        item.result
                          ? `(${item._over} ov) ${item.result}`
                          : "Waiting"
                      }
                      style={styles.over}
                    />
                    <Typography title="P&L" style={styles.result} />
                    <View style={styles.loss}>
                      <Typography title="Profit: " style={styles.win} />
                      <Typography
                        title={
                          item.status === "SUCCESS"
                            ? "+" +
                              (parseFloat(item.betAmount) * parseFloat(item.x) -
                                parseFloat(item.betAmount))
                            : item.status === "PENDING"
                            ? "In Process"
                            : 0
                        }
                        style={{
                          ...styles.won,
                          ...(item.status !== "SUCCESS" && {
                            color: colors.yellow,
                          }),
                        }}
                      />
                    </View>
                    <View style={styles.loss}>
                      <Typography title="Loss: " style={styles.win} />
                      <Typography
                        title={
                          item.status == "FAILURE"
                            ? "-" + parseFloat(item.betAmount)
                            : item.status === "PENDING"
                            ? "In Proccess"
                            : 0
                        }
                        style={{
                          ...styles.ruLoss,
                          ...(item.status === "FAILURE" && {
                            color: colors.red,
                          }),
                        }}
                      />
                    </View>
                  </View>

                  <View style={styles.left}>
                    <Typography
                      title={item.vs}
                      style={{
                        color: colors.lightgrey,
                        fontSize: scale(12),
                        marginBottom: scale(5),
                      }}
                    />
                    <Typography
                      title="You Select"
                      style={{ marginTop: verticalScale(4) }}
                    />
                    <View
                      style={[
                        styles.upComing,
                        item.selected === "DOWN" && { borderColor: colors.red },
                      ]}
                    >
                      <Typography
                        title={item.selected + " "}
                        style={styles.ru}
                      />
                      <Typography
                        title={`(${parseInt(item._over) - 1} ov) ${
                          item.curRunRate
                        } `}
                        style={styles.ru}
                      />
                      <Img
                        source={
                          item.selected === "DOWN" ? icons.red : icons.green
                        }
                        style={styles.green}
                      />
                    </View>
                    <View style={styles.qut}>
                      <View style={styles.price}>
                        <Typography title="Qty" style={styles.total} />
                        <Typography
                          title={"- " + item.quantity}
                          style={styles.num}
                        />
                      </View>
                      <View style={styles.price}>
                        <Typography title="Qty Price" style={styles.total} />
                        <Typography
                          title={"- " + item.amount}
                          style={styles.num}
                        />
                      </View>
                    </View>
                    <View style={styles.price}>
                      <Typography title="Total" style={styles.total} />
                      <Typography
                        title={"- " + item.betAmount}
                        style={styles.num}
                      />
                    </View>
                    <View style={styles.price}>
                      <Typography title="Total winning:" style={styles.win} />
                      {/* <Typography title="+200" style={styles.won} /> */}
                      <Typography
                        title={
                          item.status === "SUCCESS"
                            ? "+" +
                              parseFloat(item.betAmount) * parseFloat(item.x)
                            : 0
                        }
                        style={{
                          ...styles.won,
                          ...(item.status === "FAILURE" && {
                            color: colors.yellow,
                          }),
                        }}
                      />
                    </View>
                  </View>
                </View>
              )}
            />
          )}
        </>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: scale(10),
    paddingVertical: verticalScale(10),
    marginHorizontal: scale(10),
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: colors.primary,
    borderRadius: scale(10),
    marginBottom: scale(6),
    marginTop: scale(5),
  },
  resuliUp: {
    flexDirection: "row",
    alignItems: "center",
  },
  upComing: {
    alignItems: "center",
    flexDirection: "row",
    borderWidth: scale(1),
    borderColor: colors.engGreen,
    paddingHorizontal: scale(10),
    paddingVertical: verticalScale(5),
    borderRadius: scale(20),
    marginTop: verticalScale(7),
    backgroundColor: colors.bg,
  },
  green: {
    width: scale(11),
    height: scale(11),
  },
  ru: {
    fontSize: scale(11),
  },
  right: {},
  qut: {
    alignItems: "center",
    flexDirection: "row",
  },
  price: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: verticalScale(7),
  },
  left: {
    alignItems: "center",
  },
  arcticons: {
    width: scale(17),
    height: scale(17),
  },
  loss: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: scale(20),
    marginTop: verticalScale(4),
  },
  upDown: {
    fontSize: scale(12),
    marginHorizontal: scale(5),
  },
  result: {
    fontSize: scale(12),
    color: colors.darkgrey,
    marginLeft: scale(20),
    marginTop: scale(10),
  },
  over: {
    backgroundColor: colors.bg,
    width: scale(80),
    textAlign: "center",
    borderRadius: 20,
    fontSize: scale(11),
    paddingVertical: verticalScale(4),
    marginTop: verticalScale(3),
  },
  total: {
    fontSize: scale(11),
    color: colors.darkgrey,
    marginLeft: scale(2),
  },
  num: {
    fontSize: scale(11),
    marginHorizontal: scale(2),
  },
  win: {
    fontSize: scale(11),
  },
  won: {
    fontSize: scale(11),
    color: colors.engGreen,
    marginLeft: scale(5),
  },
  ruLoss: {
    color: colors.yellow,
    fontSize: scale(11),
    marginLeft: scale(5),
  },
});

export default RunrateScalping;
