import { StyleSheet } from "react-native";
import { Dimensions } from "react-native";

import colors from "../../../contants/colors";
import fonts from "../../../contants/fonts";
import { scale, verticalScale } from "react-native-size-matters";

const windowWidth = Dimensions.get("window").width;
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.bg,
  },
  tabIndex: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: scale(5),
    marginTop: scale(20),
  },

  tabName: {
    fontSize: scale(12),
    paddingHorizontal: scale(10),
    paddingVertical: verticalScale(5),
    backgroundColor: "#FFFFFF26",
    borderRadius: 30,
  },
  index: {
    marginLeft: scale(10),
  },
  contests: {
    // paddingHorizontal: scale(15),
    marginTop: scale(15),
  },
  teamVs: {
    paddingVertical: scale(7),
    paddingHorizontal: scale(10),
  },
  headToHead: {
    backgroundColor: colors.primary,
    borderRadius: scale(6),
    marginTop: scale(10),
    width: "100%",
  },
  titel: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  entry: {
    flexDirection: "row",
    alignItems: "center",
  },
  pool: {
    fontSize: scale(12),
    color: colors.darkgrey,
  },
  rupees: {
    width: scale(12),
    height: scale(12),
    tintColor: colors.white,
    marginRight: scale(3),
  },
  rupeesIcon: {
    width: scale(20),
    height: scale(20),
    marginTop: scale(5),
  },
  text: {
    fontSize: scale(17),
    fontFamily: fonts._700,
    marginTop: scale(5),
  },
  money: {
    backgroundColor: colors.engGreen,
    paddingHorizontal: scale(20),
    fontSize: scale(10),
    borderRadius: scale(3),
    marginTop: scale(5),
  },
  border: {
    width: "100%",
    backgroundColor: colors.white,
    height: scale(5),
    borderRadius: 50,
    marginTop: scale(8),
  },
  twoBorder: {
    width: scale(150),
    backgroundColor: colors.btn,
    height: scale(5),
    borderRadius: 50,
  },
  playersAdd: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  spot: {
    color: colors.yellow,
    fontSize: scale(10),
    // marginTop: verticalScale(-20),
    marginVertical: verticalScale(5),
  },
  player: {
    color: colors.darkgrey,
    fontSize: scale(10),
    marginTop: verticalScale(3),
  },
  name: {
    color: colors.white,
    fontSize: scale(10),
    marginTop: verticalScale(4),
  },
  spots: {
    color: colors.darkgrey,
    fontSize: scale(12),
    marginTop: verticalScale(5),
  },
  vs: {
    fontSize: scale(20),
    color: colors.darkgrey,
    fontFamily: fonts._700,
  },
  rohit: {
    width: scale(50),
    height: scale(50),
    backgroundColor: colors.bg,
    borderRadius: scale(5),
    marginTop: verticalScale(4),
  },
  bottam: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: colors.border,
    paddingVertical: scale(2),
    paddingHorizontal: scale(2),
    borderBottomRightRadius: scale(6),
    borderBottomLeftRadius: scale(6),
    justifyContent: "space-between",
  },
  worldCup: {
    width: scale(17),
    height: scale(17),
  },
  single: {
    width: scale(13),
    height: scale(13),
    marginHorizontal: scale(5),
  },
  flatList: {
    paddingBottom: scale(100),
  },
  winImg: {
    flexDirection: "row",
    alignItems: "center",
  },
  bottamText: {
    fontSize: scale(9),
    marginLeft: scale(5),
    color: colors.engGreen,
  },
  entery: {
    fontSize: scale(9),
  },
});

export default styles;
